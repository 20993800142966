'use strict';

let View, singleton;
const  account = require('models/account'),
  config = require('models/config'),
  template = require('templates/welcome');

View = Backbone.View.extend({

  events: {
    'click #button-login': 'redirectToLogin',
    'click #button-OIDC': 'goToOIDC',
  },

  close: function () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  redirectToLogin: function () {
    let _url,
      currentUrl = Backbone.history.location.href,
      authorizationConfig = config.get('authorization'),
      serverOAuth2Port = authorizationConfig.oauth2Port;

    localStorage['nonce'] = config.generateNonce();
    if (currentUrl.indexOf('com.cn') >= 0 && !authorizationConfig.oauth2Hostname.indexOf('.cn') >= 0) {
      authorizationConfig.oauth2Hostname += '.cn';
    }
    _url = (serverOAuth2Port == 443 ? 'https://' : 'http://')
      + authorizationConfig.oauth2Hostname
      + (serverOAuth2Port != 443 ? ':' + serverOAuth2Port : '')
      + '/authorize'
      + '?scope=openid%20profile%20email%20CMS_USER%20CMS_ADMIN'
      + '&client_id=' + authorizationConfig.client_id
      + '&redirect_uri=' + config.getHomeUrl()
      + '&response_type=code'
      + '&nonce=' + localStorage['nonce'];
    config.redirectTo(_url);
  },

  goToOIDC: function goToOIDC () {
    let _url,
      authorizationConfig = config.get('authorization'),
      serverOAuth2Port = authorizationConfig.oauth2Port;

    localStorage.clear();
    sessionStorage.clear();

    _url = (serverOAuth2Port == 443 ? 'https://' : 'http://')
      + authorizationConfig.oauth2Hostname
      + (serverOAuth2Port != 443 ? ':' + serverOAuth2Port : '')
      + '/signin'
      + '?scope=openid%20profile%20email%20CMS_USER%20CMS_ADMIN'
      + '&client_id=' + authorizationConfig.client_id
      + '&redirect_uri=' + config.getHomeUrl()
      + '&response_type=code'
      + '&nonce=' + localStorage['nonce'];
    account.destroySession();
    config.redirectTo(_url);
  },

  renderOnAccountChange: function renderOnAccountChange () {
    account.on('change', this.render, this);
    
  },

  render: function () {
    const html = template({ account: account.toJSON() });
    this.$el.html(html);
    $('#main').append(this.$el);
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function () {
    if (!singleton) {
      singleton = new View();
    }
    const returnValue = singleton.render();
    singleton.renderOnAccountChange();
    return returnValue;
  },
});

module.exports = View;
