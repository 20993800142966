'use strict';

 let View,
  singleton;
const template = require('templates/programMultipleDisabledNodeModal'),
  Utils = require('utils/utils');

View = Backbone.View.extend({

  DATETIMEPICKER_DATE_FORMAT: 'YYYY-MM-DD HH:mm',

  close: function () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  resetErrorDiv: function resetErrorDiv () {
    const $errorDiv = $('.cms-node-field--error');
    $errorDiv.addClass('hidden');
    const $errorTitleLabel = $('.cms-node-title-label--error');
    $errorTitleLabel.remove();
    const $errorLabel = $('.cms-node-label--error');
    $errorLabel.remove();
  },

  showErrorDiv: function showErrorDiv ($errorElement) {
    const $errorDiv = $('.cms-node-field--error');
    $errorDiv.removeClass('hidden');
    $errorElement.appendTo($errorDiv);
  },

  cancel: function () {
    this.resetErrorDiv();
    this.$el.parent().modal('toggle');
  },

  removeDates: function () {
    this.$el.parent().modal('toggle');
    // Setting a past date in order for the previous one to be deleted in Release.update
    this.selectCallBack('', '');
  },

  saveDates: function () {
    this.resetErrorDiv();
    const selectedEnableDateStr = $('#InputEnableMultipleDatetimepicker').val();
    const selectedDisableDateStr = $('#InputDisableMultipleDatetimepicker').val();
    if ((selectedEnableDateStr && !moment(selectedEnableDateStr, this.DATETIMEPICKER_DATE_FORMAT).isValid()) || (selectedDisableDateStr && !moment(selectedDisableDateStr, this.DATETIMEPICKER_DATE_FORMAT).isValid())) {
      this.showErrorDiv($('<div class="cms-node-title-label--error"><b>ERROR:</b> invalid date format, accepted format is "YYYY-MM-DD HH:mm"</div>'));
      return false;
    }
    if (selectedEnableDateStr && selectedDisableDateStr && selectedEnableDateStr === selectedDisableDateStr) {
      this.showErrorDiv($('<div class="cms-node-title-label--error"><b>ERROR:</b> both dates cannot be equal, modify one date</div>'));
      return false;
    }
    const selectedEnableDate = (selectedEnableDateStr) ? moment(selectedEnableDateStr).seconds(0).format(Utils.INTERNAL_DATE_FORMAT) : '';
    const selectedDisableDate = (selectedDisableDateStr) ? moment(selectedDisableDateStr).seconds(0).format(Utils.INTERNAL_DATE_FORMAT) : '';
    this.$el.parent().modal('toggle');
    this.selectCallBack(selectedEnableDate, selectedDisableDate);
  },

  render: function () {
    const html = template({});
    this.$el.html(html);
    $('#modal-programMultipleDisabled').append(this.$el);
    this.$el.parent().modal('toggle');

    const defaultEnableDate = (this.registeredEnableDate) ? moment(this.registeredEnableDate).seconds(0) : '';
    const enableDatetimepicker = $('#enableMultipleDatetimepicker');
    const enableInput = $('#InputEnableMultipleDatetimepicker');
    enableDatetimepicker.datetimepicker({
      disabledHours: [0],
      format: this.DATETIMEPICKER_DATE_FORMAT,
      defaultDate: defaultEnableDate,
      stepping: 5,
      sideBySide: true,
    });
    if (defaultEnableDate) {
      enableDatetimepicker.data('DateTimePicker').date(defaultEnableDate);
      enableInput.val(defaultEnableDate.format(this.DATETIMEPICKER_DATE_FORMAT));
    } else {
      enableDatetimepicker.data('DateTimePicker').date('');
      enableInput.val('');
    }
    enableDatetimepicker.append('<span class="input-group-addon enable-multipledatetimepicker-clear"><span class="fa fa-close show"></span></span>');
    $('.enable-multipledatetimepicker-clear').on('click', $.proxy(() => {
      enableDatetimepicker.data('DateTimePicker').date('');
      enableInput.val('');
    }, this));

    const defaultDisableDate = (this.registeredDisableDate) ? moment(this.registeredDisableDate).seconds(0) : '';
    const disableDatetimepicker = $('#disableMultipleDatetimepicker');
    const disableInput = $('#InputDisableMultipleDatetimepicker');
    disableDatetimepicker.datetimepicker({
      disabledHours: [0],
      format: this.DATETIMEPICKER_DATE_FORMAT,
      defaultDate: defaultDisableDate,
      stepping: 5,
      sideBySide: true,
    });
    if (defaultDisableDate) {
      disableDatetimepicker.data('DateTimePicker').date(defaultDisableDate);
      disableInput.val(defaultDisableDate.format(this.DATETIMEPICKER_DATE_FORMAT));
    } else {
      disableDatetimepicker.data('DateTimePicker').date('');
      disableInput.val('');
    }
    disableDatetimepicker.append('<span class="input-group-addon disable-multipledatetimepicker-clear"><span class="fa fa-close show"></span></span>');
    $('.disable-multipledatetimepicker-clear').on('click', $.proxy(() => {
      disableDatetimepicker.data('DateTimePicker').date('');
      disableInput.val('');
    }, this));

    this.$el.find('.js-btn-remove').on('click', $.proxy(this.removeDates, this));
    this.$el.find('.js-btn-cancel').on('click', $.proxy(this.cancel, this));
    this.$el.find('.js-btn-OK').on('click', $.proxy(this.saveDates, this));

    this.delegateEvents();
    return this;
  },
}, {
  showMe: function (registeredEnableDate, registeredDisableDate, selectCallBack) {
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }
    singleton.selectCallBack = selectCallBack;
    singleton.registeredEnableDate = registeredEnableDate || '';
    singleton.registeredDisableDate = registeredDisableDate || '';
    singleton.render();
    return singleton;
  },
});

module.exports = View;
