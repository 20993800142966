'use strict';

const _HEPTAGRAM_REGEX = /[a-z]+/;

const _EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

let User;
User = Backbone.Model.extend({
    collection: {
        url: '/users',
    },

    idAttribute: 'hepta',

    validation: function () {
        const errors = [];

        let hepta = this.get('hepta');
        let newHepta = this.get('newhepta');
        if (newHepta) {
            newHepta = newHepta.trim();
            if (!newHepta) {
                errors.push('New hepta field is only white spaces');
            } else {
                newHepta = newHepta.toLowerCase();
                if (!_HEPTAGRAM_REGEX.test(newHepta)) {
                    errors.push('New hepta field is not correct (must contain only alpha characters)');
                } else {
                    if (newHepta.length < 2 || newHepta.length > 7) {
                        errors.push('New hepta field is not correct (length must be between 2 and 7 characters)');
                    }
                }
            }
        } else {
            if (!hepta) {
                errors.push('Heptagram field is empty');
            } else {
                hepta = hepta.trim();
                if (!hepta) {
                    errors.push('Heptagram field is only white spaces');
                } else {
                    hepta = hepta.toLowerCase();
                    if (!_HEPTAGRAM_REGEX.test(hepta)) {
                        errors.push('Heptagram field is not correct (must contain only alpha characters)');
                    } else {
                        if (hepta.length < 2 || hepta.length > 7) {
                            errors.push('Heptagram field is not correct (length must be between 2 and 7 characters)');
                        }
                    }
                }
            }
        }

        let lastName = this.get('lastname');
        if (!lastName) {
            errors.push('Last Name field is empty');
        } else {
            lastName = lastName.trim();
            if (!lastName) {
                errors.push('Last Name field is only white spaces');
            }
        }

        let firstName = this.get('firstname');
        if (!firstName) {
            errors.push('First Name field is empty');
        } else {
            firstName = firstName.trim();
            if (!firstName) {
                errors.push('First Name field is only white spaces');
            }
        }

        let  email = this.get('email');
        let  newEmail = this.get('newemail');
        if (newEmail) {
            if (!newEmail) {
                errors.push('New email field is only white spaces');
            } else {
                newEmail = newEmail.toLowerCase();
                if (!_EMAIL_REGEX.test(newEmail)) {
                    errors.push('New email field is not correct (must be a valid email)');
                }
            }
        } else {
            if (!email) {
                errors.push('Email field is empty');
            } else {
                email = email.trim();
                if (!email) {
                    errors.push('Email field is only white spaces');
                } else {
                    email = email.toLowerCase();
                    if (!_EMAIL_REGEX.test(email)) {
                        errors.push('Email field is not correct (must be a valid email)');
                    }
                }
            }
        }

        let  profile = this.get('profile');
        if (profile) {
            profile = profile.trim().toUpperCase();
            if (profile === 'CMS_SUPERADMIN') {
                errors.push('Profile cannot be set to CMS_SUPERADMIN through the CMS');
            }
        }

        return errors;
    },

    extractErrorMsg: function (errors, command) {
        let  errorMsg = '';
        if (errors && errors.length) {
            let  errorMsg = 'Cannot ' + command + ' user because:';
            errorMsg += '</br><ul>';
            errors.forEach((error) => {
                errorMsg += '<li> ' + error + '</li>';
            });
            errorMsg += '</ul>';
        }
        return errorMsg;
    },
});

module.exports = User;
