'use strict';

let View,
  singleton;
const template = require('templates/editNodeModal'),
  Utils = require('utils/utils'),
  DataUtils = require('utils/DataUtils'),
  TagsUtils = require('utils/TagsUtils'),
  notificationEvent = require('NotificationEvent');
const { PAGES_STR } = require('../utils/clubmedPagesContents');

View = Backbone.View.extend({

  events: {
    'click .js-btn-dl-json': 'downloadJson',
    'click .js-btn-dl-xlsx': 'downloadXlsx',
    'click .js-btn-edit': 'navigateTo',
    'click .js-btn-cancel': 'cancel',
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.release) {
      this.release.off(null, null, this);
    }
  },

  initModel: function initModel() {
    const release = this.release.toJSON();
    this.contentId = _.get(release, 'content_id');
    this.localeId = _.get(release, 'locale');
    this.releaseId = _.get(release, 'id');
    this.title = (this.releaseTags && TagsUtils.jsonPathEqualsTaggedRootPath(this.contentId, this.title)) ? [this.title, TagsUtils.getDecodedTags(this.releaseTags, '-')].join('-') : this.title;
    this.path = Utils.replaceAll(Utils.getParentPath(this.jsonPath, '.'), '.', '-') + '-' + this.title;
    const releaseValue = _.get(release, 'value', {});
    let nodeValue = _.cloneDeep(_.get(releaseValue, this.jsonPath, {}));
    if (_.isArray(nodeValue)) {
      if (Utils.getLastPathPart(this.jsonPath, '.') === PAGES_STR) {
        nodeValue = nodeValue.map((page) => {
          delete page['b2c-pagesId'];
          delete page['old_position'];
          this._id = page['id'];
          delete page['_id'];
          return page;
        });
      }
      this.jsonValueIsArray = true;
      this.jsonValue = nodeValue;
    } else {
      if (Utils.getLastPathPart(Utils.getParentPath(this.jsonPath, '.'), '.') === PAGES_STR) {
        delete nodeValue['b2c-pagesId'];
        delete nodeValue['old_position'];
        this._id = nodeValue['_id'];
        delete nodeValue['_id'];
      }
      this.jsonValue = nodeValue;
    }

    return {
      title: this.title,
    };
  },

  downloadJson: function downloadJson() {
    try {
      this.jsonValue = this.jsonEditor.get();
    } catch (error) {
      this.showErrorDiv($('<div class="cms-node-title-label--error"><b>ERROR:</b> invalid JSON value, it could not be downloaded</div>'));
    }

    const data = JSON.stringify(this.jsonValue);
    const fileName = [this.contentId, this.localeId, this.path, (new Date()).toISOString().slice(0, 10).replace(/-/g, '')].join('_') + '.json';
    const fileMimeType = 'application/json';
    DataUtils.downloadData(data, fileName, fileMimeType);
    return false;
  },

  downloadXlsx: function downloadXlsx() {
    this.convertJson2Csv('invalid JSON value, it could not be converted to a XLSX file');

    const data = DataUtils.csv2Array(this.csvValue);
    const fileName = [this.contentId, this.localeId, this.path, (new Date()).toISOString().slice(0, 10).replace(/-/g, '')].join('_') + '.xlsx';
    const worksheetName = this.jsonPath.substr(0, 31); // worksheet names are limited to 31 chars
    DataUtils.downloadXlsx(data, fileName, worksheetName);
    return false;
  },

  bindImportXlsxFileInput: function bindImportXlsxFileInput() {
    const self = this;
    const $input = $('#xlsx');
    $input.on('change', (event) => {
      if (event.target && event.target.files) {
        const rABS = typeof FileReader !== 'undefined' && (FileReader.prototype || {}).readAsBinaryString;

        const f = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
          // get data of the file
          let data = event.target.result;

          // reset input
          $input.val(null);

          if (!rABS) {
            data = new Uint8Array(data);
          }
          const dataArray = DataUtils.xlsx2Array(XLSX.read(data, { type: rABS ? 'binary' : 'array' }));
          const dataJson = DataUtils.array2Json(dataArray);
          try {
            self.jsonValue = dataJson;
            self.jsonEditor.set(self.jsonValue);
          } catch (error) {
            self.showErrorDiv($('<div class="cms-node-title-label--error"><b>ERROR:</b> invalid XLSX file, it could not be converted to JSON</div>'));
            return false;
          }
        };
        if (rABS) {
          reader.readAsBinaryString(f);
        } else {
          reader.readAsArrayBuffer(f);
        }
      }
    });
  },

  updateJsonValue: function updateJsonValue() {
    try {
      // Get value & convert it
      this.jsonValue = this.jsonEditor.get();
    } catch (error) {
      this.jsonValue = {};
    }
  },

  validateArrayValue: function validateArrayValue() {
    this.updateJsonValue();
    return Array.isArray(this.jsonValue);
  },

  convertJson2Csv: function convertJson2Csv(errorMsg) {
    this.updateJsonValue();

    try {
      // Get value & convert it
      this.csvValue = DataUtils.json2Csv(this.jsonValue);
    } catch (error) {
      this.showErrorDiv($('<div class="cms-node-title-label--error"><b>ERROR:</b>' + errorMsg + '</div>'));
      return false;
    }
    return true;
  },

  resetErrorDiv: function resetErrorDiv() {
    const $errorDiv = $('.cms-node-field--error');
    $errorDiv.addClass('hidden');
    const $errorTitleLabel = $('.cms-node-title-label--error');
    $errorTitleLabel.remove();
    const $errorLabel = $('.cms-node-label--error');
    $errorLabel.remove();
  },

  showErrorDiv: function showErrorDiv($errorElement) {
    const $errorDiv = $('.cms-node-field--error');
    $errorDiv.removeClass('hidden');
    $errorElement.appendTo($errorDiv);
  },

  navigateTo: function navigateTo() {
    this.resetErrorDiv();

    if (this.jsonValueIsArray) {
      if (!this.validateArrayValue()) {
        this.showErrorDiv($('<div class="cms-node-title-label--error"><b>ERROR:</b> invalid Array value, it could not be saved </div>'));
        return false;
      }
    } else {
      // Check if value has expected format
      if (!this.convertJson2Csv(' invalid JSON value, it could not be saved')) {
        return false;
      }
    }

    this.$el.parent().modal('toggle');
    if (this._id) {
      this.jsonValue._id = this._id;
    }
    this.callBackEdit(this.jsonValue);
    return true;
  },

  cancel: function cancel() {
    this.resetErrorDiv();
    this.$el.parent().modal('toggle');
  },

  renderJsonEditor: function renderJsonEditor(value) {
    const jsonContainer = document.getElementById('node-jsoneditor');
    const options = {
      mode: 'code',
      error: function (err) {
        notificationEvent.notify(err.toString(), 'danger');
      },
    };
    this.jsonEditor = new JSONEditor(jsonContainer, options);
    this.jsonEditor.set(value);
  },

  render: function render() {
    const templateModel = this.initModel();
    const html = template(templateModel);
    this.$el.html(html);
    $('#modal-editNode').append(this.$el);
    this.renderJsonEditor(this.jsonValue);
    this.$el.parent().modal('toggle');

    this.bindImportXlsxFileInput();
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function (release, jsonPath, title, releaseTags, callBack) {
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }
    singleton.release = release;
    singleton.releaseTags = releaseTags;
    singleton.jsonPath = jsonPath;
    singleton.title = title;
    singleton.jsonValue = {};
    singleton.csvValue = '';
    singleton.jsonValueIsArray = false;
    singleton.callBackEdit = callBack;
    singleton.render();
    return singleton;
  },
});

module.exports = View;
