'use strict';

let View,
  singleton;
const  template = require('templates/duplicateContent'),
  account = require('models/account'),
  config = require('models/config'),
  DuplicateContent = require('models/duplicateContent'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  notificationEvent = require('NotificationEvent');

View = Backbone.View.extend({

  events: {
    'blur .cms-editable': 'updateDuplicateContent',
    'click .js-addNotification': 'addNotificationsUrl',
    'click .js-removeNotification': 'removeNotificationsUrls',
  },

  duplicateContent: function duplicateContent (content, retry) {
    const self = this;
    Spinner.show();
    this._disableBtn();
    const appRouter = require('router');
    notificationEvent.notify('Copy in progress... Please wait.', 'warning');
    content.save(content.toJSON(), {
      success: function () {
        Spinner.hide();
        appRouter.navigate('editContent/' + self.model.get('newContentId'), { trigger: true });
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.duplicateContent(content, false);
            }
          },
          fail: function () {
            self._enableBtn();
          },
        });
      },
    });
  },

  updateDuplicateContent: function updateDuplicateContent (event) {
    const $element = $(event.target);
    const id = $element.attr('id');
    if (id == 'input-content-url') {
      this.model.set('preview_url', $element.val());
    } else if (id == 'input-block-delete') {
      this.model.set('deleteBlocked', $element.prop('checked'));
    } else if (id == 'input-include-draft') {
      this.model.set('includeDraft', $element.prop('checked'));
    } else if (id == 'input-include-archived') {
      this.model.set('includeArchived', $element.prop('checked'));
    } else if (id == 'input-content-desc') {
      this.model.set('desc', $element.val());
    } else if (id && id.indexOf('input-notifications_urls_') !== -1) {
      const position = id.substring(id.lastIndexOf('_') + 1);
      const aNumbers = _.clone(this.model.get('notifications_urls'));
      aNumbers[position] = $element.val();
      this.model.set('notifications_urls', aNumbers);
    } else if (id && id.indexOf('input-remove_notifications_urls_') !== -1) {
      const position = id.substring(id.lastIndexOf('_') + 1);
      const aNumbers = _.clone(this.model.get('notifications_urls'));
      aNumbers.splice(position, 1);
      this.model.set('notifications_urls', aNumbers);
    }
  },

  addNotificationsUrl: function addNotificationsUrl () {
    const notifications_urlsDiv = $('#notifications');
    const numberUrls = $('.js-duplicateContent-urls').size();
    $('<div class="js-duplicateContent-urls cms-duplicateContent-urls cms-editable"><input type="text" class="form-control" id="input-notifications_urls_' + numberUrls + '" name="notifications_urls_' + numberUrls + '"  /><a id= "input-remove_notifications_urls_' + numberUrls + '" class="cms-duplicateContent-removeUrl glyphicon glyphicon-remove js-removeNotification" href="#" name="notifications_urls_' + numberUrls + '"></a></div>').appendTo(notifications_urlsDiv);
    return false;
  },

  removeNotificationsUrls: function removeNotificationsUrls (ev) {
    this.updateDuplicateContent(ev);
    ev.target.parentElement.remove();
    return false;
  },

  _disableBtn: function _disableBtn () {
    $('select[name=selectContent]').prop('disabled', true);
    $('.cms-duplicateContent-input').prop('disabled', true);
    $('.js-duplicateContent').prop('disabled', true);
  },

  _enableBtn: function _enableBtn () {
    $('select[name=selectContent]').prop('disabled', false);
    $('.cms-duplicateContent-input').prop('disabled', false);
    $('.js-duplicateContent').prop('disabled', false);
  },

  render: function render () {
    Spinner.hide();
    const html = template({ contents: this.contents });
    this.$el.html(html);
    $('#main').append(this.$el);
    if ((!config.get('featureFlipping').blockContentAndLocaleDelete || config.get('featureFlipping').blockContentAndLocaleDelete === 'false') && account.isSuperAdmin()) {
      $('#content-block-delete-row').removeClass('hidden');
    }
    this.$el.find('.js-duplicateContent').off().click($.proxy(function () {
      const selectContent = $('select[name=selectContent]');
      const contentId = (selectContent) ? selectContent.val() : '';
      if (contentId) {
        this.model.set('contentId', contentId);
        const inputContent = $('.cms-duplicateContent-input');
        const newContentId = (inputContent) ? inputContent.val() : '';
        if (newContentId) {
          this.model.set('newContentId', newContentId);
          if (this.model.isValid()) {
            this.duplicateContent(this.model, true);
          } else {
            notificationEvent.notify(this.model.validationError, 'danger');
          }
        } else {
          notificationEvent.notify('Please type a new Content ID.', 'danger');
        }
      } else {
        notificationEvent.notify('Please select a Content ID.', 'danger');
      }
    }, this));
    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (duplicateContent, retry) {
    const self = this;
    duplicateContent.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.model = new DuplicateContent();
          singleton.contents = model.extractContents();
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(duplicateContent, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe () {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    const duplicateContent = new DuplicateContent({});
    this.retrieveData(duplicateContent, true);
    return singleton;
  },
});
module.exports = View;
