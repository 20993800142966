'use strict';

const MigrateContent = Backbone.Model.extend({

  collection: {
    url: '/v1/migrateContent',
  },

  defaults: {
    contentId: '',
    localeIds: ['all'],
    migrationObject: {},
  },

  url: function url () {
    let url;
    if (this.get('contentId')) {
      url = '/v1/migrateContent/' + this.get('contentId');
    } else {
      url = '/v1/migrateContent';
    }
    return url + '?htmlResponse=true';
  },
});

module.exports = MigrateContent;
