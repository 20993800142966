'use strict';

const DuplicateContent = Backbone.Model.extend({

  collection: {
    url: '/v1/duplicateContent',
  },

  defaults: {
    contentId: '',
    newContentId: '',
    includeDraft: false,
    includeArchived: false,
    desc: '',
    preview_url: '',
    notifications_urls: [],
    deleteBlocked: false,
  },

  url: function url () {
    if (this.get('contentId') && this.get('newContentId')) {
      return '/v1/duplicateContent/' + this.get('contentId') + '/newContentId/' + this.get('newContentId');
    } 
      return '/v1/duplicateContent';
    
  },

  extractContents: function extractContents () {
    const output = [];
    const json = this.toJSON();
    for (const property in json) {
      if (json[property].id) {
        output.push(json[property]);
      }
    }
    return output;
  },

  validate: function validate (attributes) {
    if (attributes.contentId && attributes.newContentId) {
      if (!/^[a-z0-9\-_]{0,255}$/.test(attributes.newContentId)) {
        return 'New Content name can contain only lowercase alphanumeric characters and \'-\' or \'_\', without spaces and max length: 255';
      } else if (attributes.preview_url && !/^((http|https|ftp):\/\/)/.test(attributes.preview_url)) {
        return 'Preview url ' + attributes.preview_url + ' is not a valid url';
      } else if (attributes.notifications_urls) {
        for (let count = 0; count < attributes.notifications_urls.length; count++) {
          if (!/^((http|https|ftp):\/\/)/.test(attributes.notifications_urls[count])) {
            return 'Notifications urls ' + attributes.notifications_urls[count] + ' is not valid';
          }
        }
      }
    } else {
      return 'Select a Content to copy and fill new Content name';
    }
  },
});

module.exports = DuplicateContent;
