'use strict';

const CreateContentView = require('views/createContentView'),
  EditContentView = require('views/editContentView'),
  WelcomeView = require('views/welcomeView'),
  ContentsView = require('views/contentsView'),
  ReleasesView = require('views/releasesView'),
  ReleaseView = require('views/releaseView'),
  EditTagsView = require('views/editTagsView'),
  ArchivedView = require('views/archivedView'),
  DuplicateContentView = require('views/duplicateContentView'),
  MigrateContentView = require('views/migrateContentView'),
  MigrateContentV2View = require('views/migrateContentV2View'),
  MigrateContentDcxView = require('views/migrateContentDcxView'),
  ImportContentView = require('views/importContentView'),
  AddTokenView = require('views/addTokenView'),
  UsersView = require('views/usersView'),
  UsersCsvView = require('views/usersCsvView'),
  CreateUserView = require('views/createUserView'),
  EditUserView = require('views/editUserView'),
  account = require('models/account'),
  Users= require('models/users'),
  Spinner = require('Spinner');

const AppRouter = Backbone.Router.extend({

  routes: {
    '': 'index',
    welcome: 'welcome',
    createContent: 'createContent',
    'editContent/:contentId': 'editContent',
    contents: 'contents',
    duplicateContent: 'duplicateContent',
    migrateContent: 'migrateContent',
    migrateContentV2: 'migrateContentV2',
    migrateContentDcx: 'migrateContentDcx',
    'prodContent/:token': 'prodContent',
    'addToken/:isValidToken': 'addToken',
    users: 'users',
    createUser: 'createUser',
    'users/:hepta': 'editUser',
    usersCsv: 'usersCsv',
    '*notFound': 'index',
  },

  init: function init (options) {
    this.route(/^contents\/(.*)\/locales\/(.*)\/releases\/(.*)$/, 'release');
    this.route(/^contents\/(.*)\/locales\/(.*)\/releases\/(.*)\/editTags\/(.*?)(?:\/category\/(.*))?$/, 'editTags');
    this.route(/^contents\/(.*)\/locales\/(.*)\/releases$/, 'releases');
    this.route(/^contents\/(.*)\/locales\/(.*)\/archived$/, 'archived');
    this.route(/^contents\/(.*)\/locales\/(.*)\/allArchived$/, 'allArchived');

    options = options || {};
    options.pushState = options.pushState !== false;
    options.root = options.root ? options.root : '/app/';
    options.hashChange = options.hashChange == undefined ? false : options.hashChange;
    Backbone.history.start(options);
  },

  activeView: null,

  changeActiveView: function changeActiveView (newView) {
    if (this.activeView) {
      this.activeView.remove();
    }
    this.activeView = newView;
  },

  getLocation: function getLocation () {
    return window.location;
  },

  index: function index () {
    var self = this;
    var location = this.getLocation();
    if (location && location.href && location.href.indexOf('?code=') >= 0 && (!account || !account.get('access_token'))) {
      var queryParams = account.getQueryParams(location.href);
      Spinner.show();
      account.signin(queryParams,
        () => {
          account.saveSession();
          Spinner.hide();
          self.navigate('contents', true);
        },
        (error) => {
          if (!error || error.statusCode !== 400) {
            account.accessDenied();
          }
          Spinner.hide();
          self.navigate('welcome', true);
        });
    } else {
      Spinner.hide();
      this.navigate('welcome', true);
    }
  },

  welcome: function welcome () {
    this.changeActiveView(WelcomeView.showMe());
  },

  createContent: function createContent () {
    this.changeActiveView(CreateContentView.showMe());
  },

  duplicateContent: function duplicateContent () {
    this.changeActiveView(DuplicateContentView.showMe());
  },

  migrateContent: function migrateContent () {
    this.changeActiveView(MigrateContentView.showMe());
  },

  migrateContentV2: function migrateContentV2 () {
    this.changeActiveView(MigrateContentV2View.showMe());
  },
  
  migrateContentDcx: function migrateContentDcx () {
    this.changeActiveView(MigrateContentDcxView.showMe());
  },

  prodContent: function prodContent (token) {
    this.changeActiveView(ImportContentView.showMe(token));
  },

  addToken: function addToken (isValidToken) {
    this.changeActiveView(AddTokenView.showMe(isValidToken));
  },

  contents: function contents () {
    this.changeActiveView(ContentsView.showMe());
  },

  editContent: function editContent (contentId) {
    this.changeActiveView(EditContentView.showMe(contentId));
  },

  releases: function releases (contentId, localeId) {
    this.changeActiveView(ReleasesView.showMe(contentId, localeId));
  },

  archived: function archived (contentId, localeId) {
    this.changeActiveView(ArchivedView.showMe(contentId, localeId, true));
  },

  allArchived: function allArchived (contentId, localeId) {
    this.changeActiveView(ArchivedView.showMe(contentId, localeId, false));
  },

  release: function release (contentId, localeId, releaseId, mainTag, subTag) {
    this.changeActiveView(ReleaseView.showMe(contentId, localeId, releaseId,  mainTag, subTag));
  },

  editTags: function editTags (contentId, localeId, releaseId, tags, selectedCategory) {
    this.changeActiveView(EditTagsView.showMe(contentId, localeId, releaseId, tags, selectedCategory));
  },

  users: function users () {
    this.changeActiveView(UsersView.showMe());
  },

  usersCsv: function usersCsv () {
    this.changeActiveView(UsersCsvView.showMe());
  },

  createUser: function createUser () {
    this.changeActiveView(CreateUserView.showMe());
  },

  editUser: function editUser (hepta) {
    this.changeActiveView(EditUserView.showMe(hepta));
  },
});



var appRouter = new AppRouter();

module.exports = appRouter;
