'use strict';

const User = require('models/user');

const ImportUsers = Backbone.Collection.extend({
    url: function url() {
        return '/v1/importUsers/' + this.token;
    },

    initialize: function initialize (attributes) {
        if(!attributes) {
            return;
        }
        this.token = attributes.token;
    },

    model: User,
});

module.exports = ImportUsers;
