'use strict';

let View,
  singleton;
const  template = require('templates/contents'),
  LocalesView = require('views/localesModalView'),
  account = require('models/account'),
  errorHandler = require('utils/errorHandler'),
  Spinner = require('Spinner'),
  Contents = require('models/contents'),
  State = require('../State');



function initialize() {
  initHandlebarsHelpers();
}


function initHandlebarsHelpers() {
  Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {

    switch (operator) {
      case '==':
        return (v1 == v2) ? options.fn(this) : options.inverse(this);
      case '===':
        return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '!=':
        return (v1 != v2) ? options.fn(this) : options.inverse(this);
      case '!==':
        return (v1 !== v2) ? options.fn(this) : options.inverse(this);
      case '<':
        return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
        return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
        return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
        return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
        return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '||':
        return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });
}



View = Backbone.View.extend({

  events: {
    'click #create-new-content': 'createContent',
    'click li': 'displayLocalesModal',
    'click .editContent': 'editContent',
  },

  initialize : initialize,

  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  createContent: function createContent () {
    const appRouter = require('router');
    appRouter.navigate('createContent', { trigger: true });
  },

  displayLocalesModal: function displayLocalesModal (event) {
    const contentId = $(event.currentTarget).data('content');
    const content = this.model.get(contentId);
    LocalesView.showMe(content.locales, (contentId, localeId) => {

      State.add(State.action.SHOW_RELEASES , contentId, localeId);
      const appRouter = require('router');
      appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases', { trigger: true });
    });
  },

  editContent: function editContent (event) {
    const appRouter = require('router'),
      contentId = $(event.currentTarget).data('content');

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    appRouter.navigate('editContent/' + contentId, { trigger: true });
  },

  render: function render () {
    Spinner.hide();
    const html = template({ contents: this.model.toJSON() });
    this.$el.html(html);
    $('#main').append(this.$el);

    if (account.isAdmin()) {
      $('#create-new-content').removeClass('hidden');
      $('.editContent').removeClass('hidden');
    }

    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (contents, retry) {
    const self = this;
    contents.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.model = model;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(contents, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe () {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    const contents = new Contents();
    this.retrieveData(contents, true);
    return singleton;
  },
});

module.exports = View;
