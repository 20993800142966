const Utils = require('./utils');
const { PAGES_STR, DCX_SHARED_DATA_PROPERTY, getRelevantSharedDataReferenceNames } =require('./clubmedPagesContents');
const { getIsDescendantOfCommercialAnimation, getReleventSharedCommercialComponentsReferenceNames } = require('./commercialAnimationUtils');
function isNotObjectOrEmpty(item) {
  return !_.isObject(item) || !_.isEmpty(item);
}

const iterateDeep = (data, action, jsonPath) => {
  if (!_.isObject(data)) {
    action(data, jsonPath || '');
    return data;
  }
  if (Array.isArray(data)) {
    return data
      .map((item, index) => {
        return iterateDeep(item, action, jsonPath ? `${jsonPath}.${index}` : `${index}`);
      })
      .filter(isNotObjectOrEmpty);
  }
  action(data, jsonPath || '');
  return _.mapValues(data, (node, key) => {
    return iterateDeep(node, action, jsonPath ? `${jsonPath}.${key}` : key);
  });
};

function dcxReferenceNamesCheck({ value, contentId }) {
  const errors = [];
  iterateDeep(value, (data, jsonPath) => {
    if (
      Utils.getLastPathPart(jsonPath, '.') !== 'referenceName' ||
      Utils.getFirstPathPart(jsonPath) === DCX_SHARED_DATA_PROPERTY ||
      !Utils.pathContainsElement(jsonPath, PAGES_STR)
    ) {
      return;
    }

    if (!data) {
      return;
    }

    if (getIsDescendantOfCommercialAnimation(jsonPath)) {
      const key = Utils.getLastPathPart(Utils.getParentPath(jsonPath, '.'), '.');
      const sharedCommercialDataReferenceNames = getReleventSharedCommercialComponentsReferenceNames(
        {
          releaseValue: value,
          key,
        },
      );

      if (!sharedCommercialDataReferenceNames.includes(data)) {
        errors.push(
          formatError({
            dataPath: jsonPath,
            message: `Reference name: ${data} doesn't exist`,
            solution: 'Please remove it or replace it with one that exists',
          }),
        );
      }
      return;
    }

    const key = Utils.getLastPathPart(Utils.getParentPath(jsonPath, '.'), '.');
    const sharedDataReferenceNames = getRelevantSharedDataReferenceNames({
      releaseValue: value,
      contentId,
      path: jsonPath,
      key,
    });

    if (!sharedDataReferenceNames.includes(data)) {
      errors.push(
        formatError({
          dataPath: jsonPath,
          message: `Reference name: ${data} doesn't exist`,
          solution: 'Please remove it or replace it with one that exists',
        }),
      );
    }
  });

  return { errors };
}

const formatError = ({ dataPath, message, solution }) => ({
  property: Utils.formatToJsonPath(dataPath) || '/',
  message: message || '',
  solution: solution || '',
});

module.exports = { dcxReferenceNamesCheck };

