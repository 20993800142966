'use strict';

let View,
  singleton;
const  template = require('templates/programPublicationModal'),
  Utils = require('utils/utils');

View = Backbone.View.extend({

  close: function () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  _MIN_TIME_BEFORE_PUBLICATION_IN_MINUTES: 5,

  _isDateValid: function (date) {
    if (!date) {
      return false;
    }
    const selectedDate = moment(date);
    const currentDate = moment();
    return selectedDate && selectedDate.valueOf() >= (currentDate.valueOf() + this._MIN_TIME_BEFORE_PUBLICATION_IN_MINUTES);
  },

  removeDate: function () {
    this.$el.parent().modal('toggle');
    // Setting a past date in order for the previous one to be deleted in Release.update
    this.selectCallBack('1990-07-11T11:50:00+0000');
  },

  cancel: function () {
    this.$el.parent().modal('toggle');
    this.cancelCallBack();
  },

  render: function () {
    const html = template({});
    this.$el.html(html);
    $('#modal-programPublication').append(this.$el);

    this.$el.parent().modal('toggle');
    this.$el.find('.js-btn-remove').on('click', $.proxy(this.removeDate, this));
    this.$el.find('.js-btn-cancel').on('click', $.proxy(this.cancel, this));

    const startTime = moment().minutes(moment().minutes() + this._MIN_TIME_BEFORE_PUBLICATION_IN_MINUTES).seconds(0);
    const defaultDate = (this.registeredDate) ? moment(this.registeredDate).seconds(0) : startTime;
    const dateTimePicker = $('#datetimepicker');
    dateTimePicker.datetimepicker({
      disabledHours: [0],
      format: 'YYYY-MM-DD HH:mm',
      minDate: startTime,
      stepping: 5,
      sideBySide: true,
    });
    dateTimePicker.data('DateTimePicker').date(defaultDate);

    this.$el.find('.js-btn-OK').on('click', $.proxy(function () {
      const selectedDate = dateTimePicker.data('DateTimePicker').date();
      if (this._isDateValid(selectedDate)) {
        this.$el.parent().modal('toggle');
        this.selectCallBack(moment(selectedDate).seconds(0).format(Utils.INTERNAL_DATE_FORMAT));
      } else {
        return false;
      }
    }, this));

    this.delegateEvents();
    return this;
  },
}, {
  showMe: function (registeredDate, selectCallBack, cancelCallBack) {
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }
    singleton.selectCallBack = selectCallBack;
    singleton.cancelCallBack = cancelCallBack;
    singleton.registeredDate = registeredDate || '';
    singleton.render();
    return singleton;
  },
});

module.exports = View;
