'use strict';

let View,
  singleton;

const template = require('templates/localesModal'),
  Locales = require('models/locales'),
  config = require('models/config'),
  account = require('models/account'),
  Locale = require('models/locale'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  notificationEvent = require('NotificationEvent');

View = Backbone.View.extend({

  initialize: function initialize() {
    this.initHandlebarsHelpers();
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  initHandlebarsHelpers: function initHandlebarsHelpers() {
    Handlebars.registerHelper('oneLocaleAuthorized', function(locales, options) {
      let oneLocaleAuthorized = false;
      _.each(locales, (locale) => {
        if (locale.authorized) {
          oneLocaleAuthorized = true;
          return false;
        }
      });

      if (oneLocaleAuthorized) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    Handlebars.registerHelper('ifMoreThanOne', function(list, options) {
      if (list.length > 1) {
        return options.fn(this);
      } 
        return options.inverse(this);
      
    });
  },

  initModel: function initModel() {
    const areas = config.get('areas');
    const accountAllowedLocales = account.get('locales');
    const alreadyExistingLocales = this.model.models;
    const { contentId } = this.model;
    const allowed = account.isAllowedToReadAllLocales(contentId);
    _.each(areas, (area) => {
      _.each(area.countries, (country) => {
        _.each(country.locales, (locale) => {
          locale.exists = _.where(alreadyExistingLocales, { id: locale.id }).length > 0;
          _.each(accountAllowedLocales, (accountLocale) => {
            if (accountLocale == locale.id) {
              if (allowed) {
                locale.authorized = true;
              } else {
                locale.authorized = account.isAllowed(contentId, accountLocale, 'READ');
              }
            }
          });
        });
      });
    });
    return {
      areas: areas,
      content_id: contentId,
    };
  },

  navigateTo: function navigateTo(contentId, localeId) {
    // wait the modal to be closed before continuing
    this.$el.parent().one('hidden.bs.modal', $.proxy(function() {
      this.callBackSelect(contentId, localeId);
    }, this));
    this.$el.parent().modal('toggle');
  },

  createLocale: function createLocale(localeData) {
    const locale = new Locale({ id: localeData.id, contentId: localeData.content });
    return locale.save();
  },

  localeCreatedPromise: function localeCreatedPromise(localeData) {
    let localeCreatedPromise = $.Deferred();
    if (localeData.create) {
      localeCreatedPromise = this.createLocale(localeData);
    } else {
      localeCreatedPromise.resolve();
    }
    return localeCreatedPromise;
  },

  handleLocale: function handleLocale(event) {
    const localeData = $(event.target).data();
    if (!localeData.authorized) {
      notificationEvent.notify('You are not authorized', 'danger');
    } else if (localeData.create && !account.isAllowed(localeData.content, localeData.id, 'WRITE')) {
      notificationEvent.notify('There is no release for the locale ' + localeData.id, 'danger');
    } else {
      this.localeCreatedPromise(localeData).then($.proxy(function() {
        this.navigateTo(localeData.content, localeData.id);
      }, this));
    }
  },

  render: function render() {
    Spinner.hide();
    const templateModel = this.initModel();
    const html = template(templateModel);
    this.$el.html(html);
    $('#modal-locales').append(this.$el);

    this.$el.parent().modal('toggle');
    this.$el.find('.js-modal-releases').on('click', $.proxy(this.handleLocale, this));

    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData(locales, callBack, retry) {
    const self = this;
    locales.fetch({
      success: function() {
        Spinner.hide();
        singleton.callBackSelect = callBack;
        singleton.render();
      },
      error: function(model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function() {
            if (retry) {
              self.retrieveData(singleton.model, callBack, false);
            }
          },
          fail: function() {
          },
        });
      },
    });
  },

  showMe: function showMe(locales, callBack) {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }
    singleton.model = locales || new Locales();
    this.retrieveData(singleton.model, callBack, true);
    return singleton;
  },
});

module.exports = View;

