'use strict';

const Utils = require('./utils');
const { isB2cPagesContentId, isDcxContentId, PAGES_STR } = require('./clubmedPagesContents');
const { dcxReferenceNamesCheck } = require('./validateDcxReferenceNamesRules');

module.exports = ({ contentId = '', tagsMetadata = {}, value = {} }) => {
  if (isB2cPagesContentId(contentId)) {
    return b2cPagesVerifications({
      tagsMetadata,
      value,
    });
  } else if (isDcxContentId(contentId)) {
    return dcxPagesVerifications({
      value,
      contentId,
    });
  }
  return [];
};

function b2cPagesVerifications({ tagsMetadata = {}, value = {} }) {
  let errors = [];
  const result = b2cPagesLoopThroughPages({
    tagsMetadata,
    value,
  });
  if (Utils.isNotEmptyArray(result.errors)) {
    errors = errors.concat(result.errors);
  }
  if (Utils.isNotEmptyArray(result.pageIdList)) {
    errors = errors.concat(
      b2cPagesUniquePageIdCheck({
        pageIdList: result.pageIdList,
      }),
    );
  }
  if (
    Utils.isNotEmptyArray(result.pageUrlList) &&
    Utils.isNotEmptyArray(result.pageDisabledMetadataList)
  ) {
    errors = errors.concat(
      b2cPagesSingleActiveUrlCheck({
        pageUrlList: result.pageUrlList,
        pageDisabledMetadataList: result.pageDisabledMetadataList,
      }),
    );
  }
  return errors;
}

function b2cPagesLoopThroughPages({ tagsMetadata = {}, value = {} }) {
  const errors = [];
  const pageIdList = [];
  const pageUrlList = [];
  const pageDisabledMetadataList = [];
  const pages = _.get(value, PAGES_STR, []);
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    const pageId = _.get(page, 'pageId', '');
    pageIdList.push(pageId.trim().toLowerCase());
    if (!pageId) {
      errors.push(
        formatError({
          dataPath: 'pages.' + i + '.pageId',
          message: 'pageId cannot be empty!',
          solution: 'Please provide a unique pageId to your page before saving',
        }),
      );
    }
    const pageUrl = _.get(page, 'url', '');
    pageUrlList.push(pageUrl.trim().toLowerCase());
    const pageDisabledMetadata = _.get(page, '@metadata.disabled', false);
    pageDisabledMetadataList.push(pageDisabledMetadata);
  }
  if (tagsMetadata && tagsMetadata.hiddenPageIds && tagsMetadata.hiddenPageIds.length > 0) {
    for (let i = 0; i < tagsMetadata.hiddenPageIds.length; i++) {
      const pageId = tagsMetadata.hiddenPageIds[i];
      pageIdList.push(pageId.trim().toLowerCase());
      const pageUrl = tagsMetadata.hiddenUrls[i];
      pageUrlList.push(pageUrl.trim().toLowerCase());
      const pageDisabledMetadata = tagsMetadata.hiddenDisabledMetadatas[i];
      pageDisabledMetadataList.push(pageDisabledMetadata);
    }
  }
  return {
    errors,
    pageIdList,
    pageUrlList,
    pageDisabledMetadataList,
  };
}

function b2cPagesUniquePageIdCheck({ pageIdList = [] }) {
  const errors = [];
  const pageIdDuplicates = Utils.getDuplicates(pageIdList);
  if (Utils.isNotEmptyObject(pageIdDuplicates)) {
    for (let duplicatedPageId in pageIdDuplicates) {
      const indexesOfPagesWithDuplicates = pageIdDuplicates[duplicatedPageId];
      for (let i = 0; i < indexesOfPagesWithDuplicates.length; i++) {
        if (duplicatedPageId) {
          const pageIndex = indexesOfPagesWithDuplicates[i];
          errors.push(
            formatError({
              dataPath: 'pages.' + pageIndex + '.pageId',
              message:
                'pageId must be unique! Multiple pages have the same pageId="' +
                duplicatedPageId +
                '"',
              solution:
                'Please keep only one page with this pageId (delete the other pages or modify their pageIds)',
            }),
          );
        }
      }
    }
  }
  return errors;
}

function b2cPagesSingleActiveUrlCheck({ pageUrlList = [], pageDisabledMetadataList = [] }) {
  const errors = [];
  const pageUrlDuplicates = Utils.getDuplicates(pageUrlList);
  if (Utils.isNotEmptyObject(pageUrlDuplicates)) {
    for (let duplicatedPageUrl in pageUrlDuplicates) {
      const indexesOfPagesWithDuplicates = pageUrlDuplicates[duplicatedPageUrl];
      const dataPathsOfPagesWithDuplicates = [];
      let countOfEnabledPagesWithSameUrl = 0;
      for (let i = 0; i < indexesOfPagesWithDuplicates.length; i++) {
        const pageIndex = indexesOfPagesWithDuplicates[i];
        const isPageEnabled = !pageDisabledMetadataList[pageIndex];
        if (duplicatedPageUrl && isPageEnabled) {
          countOfEnabledPagesWithSameUrl++;
          dataPathsOfPagesWithDuplicates.push('pages.' + pageIndex + '.url');
        }
      }
      if (countOfEnabledPagesWithSameUrl > 1) {
        for (let i = 0; i < dataPathsOfPagesWithDuplicates.length; i++) {
          const dataPath = dataPathsOfPagesWithDuplicates[i];
          errors.push(
            formatError({
              dataPath,
              message:
                'url can only be used by one active page at a time! Multiple active pages have the same url="' +
                duplicatedPageUrl +
                '"',
              solution:
                'Please keep only one active page with this url (disable the other pages or modify their urls)',
            }),
          );
        }
      }
    }
  }
  return errors;
}

function dcxPagesVerifications({ value = {}, contentId }) {
  let errors = [];
  const result = dcxLoopThroughPages({
    value,
  });
  const referenceNameErrors = dcxReferenceNamesCheck({ value, contentId });
  if (Utils.isNotEmptyArray(result.errors)) {
    errors = errors.concat(result.errors);
  }
  if (Utils.isNotEmptyArray(referenceNameErrors.errors)) {
    errors = errors.concat(referenceNameErrors.errors);
  }
  if (Utils.isNotEmptyArray(result.pageIdList)) {
    errors = errors.concat(
      dcxUniquePageIdCheck({
        pageIdList: result.pageIdList,
        pageParentFolderList: result.pageParentFolderList,
      }),
    );
  }
  if (
    Utils.isNotEmptyArray(result.pageUrlList) &&
    Utils.isNotEmptyArray(result.pageDisabledMetadataList)
  ) {
    errors = errors.concat(
      dcxSingleActiveUrlCheck({
        pageUrlList: result.pageUrlList,
        pageDisabledMetadataList: result.pageDisabledMetadataList,
        pageParentFolderList: result.pageParentFolderList,
      }),
    );
  }
  return errors;
}

function dcxLoopThroughPages({ value = {} }) {
  const errors = [];
  const pageIdList = [];
  const pageParentFolderList = [];
  const pageUrlList = [];
  const pageDisabledMetadataList = [];
  Object.keys(value ?? {}).forEach((property) => {
    if (_.has(value[property], PAGES_STR)) {
      const pages = _.get(value[property], PAGES_STR, []);
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        const pageId = _.get(page, 'pageId', '');
        pageIdList.push(pageId.trim().toLowerCase());
        pageParentFolderList.push(property);
        if (!pageId) {
          errors.push(
            formatError({
              dataPath: `${property}.pages.${i}.pageId`,
              message: 'pageId cannot be empty!',
              solution: 'Please provide a unique pageId to your page before saving',
            }),
          );
        }
        const pageUrl = _.get(page, 'url', '');
        pageUrlList.push(pageUrl.trim().toLowerCase());
        const pageDisabledMetadata = _.get(page, '@metadata.disabled', false);
        pageDisabledMetadataList.push(pageDisabledMetadata);
      }
    }
  });
  return {
    errors,
    pageIdList,
    pageUrlList,
    pageDisabledMetadataList,
    pageParentFolderList,
  };
}

function dcxUniquePageIdCheck({ pageIdList = [], pageParentFolderList = [] }) {
  const errors = [];
  const pageIdDuplicates = Utils.getDuplicates(pageIdList);
  if (Utils.isNotEmptyObject(pageIdDuplicates)) {
    for (let duplicatedPageId in pageIdDuplicates) {
      const indexesOfPagesWithDuplicates = pageIdDuplicates[duplicatedPageId];
      for (let i = 0; i < indexesOfPagesWithDuplicates.length; i++) {
        if (duplicatedPageId) {
          const pageIndex = indexesOfPagesWithDuplicates[i];
          errors.push(
            formatError({
              dataPath: `${pageParentFolderList[pageIndex]}.pages.${pageIndex}.pageId`,
              message:
                'pageId must be unique! Multiple pages have the same pageId="' +
                duplicatedPageId +
                '"',
              solution:
                'Please keep only one page with this pageId (delete the other pages or modify their pageIds)',
            }),
          );
        }
      }
    }
  }
  return errors;
}

function dcxSingleActiveUrlCheck({ pageUrlList = [], pageDisabledMetadataList = [], pageParentFolderList = [] }) {
  const errors = [];
  const pageUrlDuplicates = Utils.getDuplicates(pageUrlList);
  if (Utils.isNotEmptyObject(pageUrlDuplicates)) {
    for (let duplicatedPageUrl in pageUrlDuplicates) {
      const indexesOfPagesWithDuplicates = pageUrlDuplicates[duplicatedPageUrl];
      const dataPathsOfPagesWithDuplicates = [];
      let countOfEnabledPagesWithSameUrl = 0;
      for (let i = 0; i < indexesOfPagesWithDuplicates.length; i++) {
        const pageIndex = indexesOfPagesWithDuplicates[i];
        const isPageEnabled = !pageDisabledMetadataList[pageIndex];
        if (duplicatedPageUrl && isPageEnabled) {
          countOfEnabledPagesWithSameUrl++;
          dataPathsOfPagesWithDuplicates.push(`${pageParentFolderList[pageIndex]}.pages.${pageIndex}.url`);
        }
      }
      if (countOfEnabledPagesWithSameUrl > 1) {
        for (let i = 0; i < dataPathsOfPagesWithDuplicates.length; i++) {
          const dataPath = dataPathsOfPagesWithDuplicates[i];
          errors.push(
            formatError({
              dataPath,
              message:
                'url can only be used by one active page at a time! Multiple active pages have the same url="' +
                duplicatedPageUrl +
                '"',
              solution:
                'Please keep only one active page with this url (disable the other pages or modify their urls)',
            }),
          );
        }
      }
    }
  }
  return errors;
}

const formatError = ({ dataPath, message, solution }) => ({
  property: Utils.formatToJsonPath(dataPath) || '/',
  message: message || '',
  solution: solution || '',
});
