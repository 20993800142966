'use strict';

module.exports = Backbone.Model.extend({
    collection : {
        url : '/users/export',
    },

    defaults : {
        value : '',
    },
});
