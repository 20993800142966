const B2C_PAGES_CONTENT_ID_REGEX = /^.*(b2(b|c)-pages).*$/;
const CLUBMED_PAGES_CONTENT_ID_REGEX = /^.*((b2(b|c)-pages)|dcx).*$/;
const DCX_PAGES_CONTENT_ID_REGEX = /^.*(dcx).*$/;

const B2C_PAGES_SHARED_DATA_PROPERTY = 'sharedLines';
const DCX_SHARED_DATA_PROPERTY = 'sharedComponents';
const BASE_COMPONENTS_PROPERTY = 'baseComponents';
const PAGES_STR = 'pages';
const isB2cPagesContentId = (contentId) => contentId.match(B2C_PAGES_CONTENT_ID_REGEX);
const isClubmedPagesContentId = (contentId) => contentId.match(CLUBMED_PAGES_CONTENT_ID_REGEX);
const isDcxContentId = (contentId) => contentId.match(DCX_PAGES_CONTENT_ID_REGEX);

const isPagesDocumentsIndexName = (contentId) => contentId.match(/(.*b2c-pages_pages.*)/) || contentId.match(/(.*dcx_pages.*)/); // peut rester en l'état car la construction de l'index est égale à "b2c-pages_pages_{contentId}" ou "dcx_pages_{contentId}" (cf. fonction getDocumentsIndexNames)

const isSharedDataDocumentsIndexName = (contentId) =>
  contentId.match(/(.*b2c-pages_shared-lines.*)/) || contentId.match(/(.*dcx_shared-components.*)/); // peut rester en l'état car la construction de l'index est égale à "b2c-pages_shared-lines_{contentId}" ou "dcx_shared-components_{contentId}" (cf. fonction getDocumentsIndexNames)

const isReleaseDocumentsIndexName = (contentId) => {
  return (
    (isB2cPagesContentId(contentId) || isDcxContentId(contentId)) &&
    !isPagesDocumentsIndexName(contentId) &&
    !isSharedDataDocumentsIndexName(contentId)
  );
};

const getDocumentsIndexNames = (contentId) => {
  if (isReleaseDocumentsIndexName(contentId)) {
    if (isB2cPagesContentId(contentId)) {
      return {
        releaseDocumentsIndexName: contentId,
        pagesDocumentsIndexName:
          contentId === 'b2c-pages' ? 'b2c-pages_pages' : 'b2c-pages_pages_' + contentId,
        sharedDataDocumentsIndexName:
          contentId === 'b2c-pages'
            ? 'b2c-pages_shared-lines'
            : 'b2c-pages_shared-lines_' + contentId,
      };
    } else if (isDcxContentId(contentId)) {
      return {
        releaseDocumentsIndexName: contentId,
        pagesDocumentsIndexName:
          contentId === 'dcx' ? 'dcx_pages' : 'dcx_pages_' + contentId,
        sharedDataDocumentsIndexName:
          contentId === 'dcx'
            ? 'dcx_shared-components'
            : 'dcx_shared-components_' + contentId,
      };
    }
  }
  return contentId;
};

const getSharedDataProperty = (contentId) => {
  if (isB2cPagesContentId(contentId)) {
    return B2C_PAGES_SHARED_DATA_PROPERTY;
  } else if (isDcxContentId(contentId)) {
    return DCX_SHARED_DATA_PROPERTY;
  }
  return B2C_PAGES_SHARED_DATA_PROPERTY;
};

const getElementsReferenceNames = (elements) => _(elements)
.filter((item) => item?.['@metadata']?.disabled !== true)
.map((item) => {
  return _.get(item, [_.first(_.keys(_.omit(item, '@metadata'))), 'referenceName']);
})
.filter((referenceName) => {
  return !_.isEmpty(referenceName);
})
.value();

/**
 * /!\ Only works for dcx /!\
 * @param {object} releaseValue 
 * @param {string} contentId 
 * @param {string} key 
 * @returns list of referenceNames
 */
const getSharedDataReferenceNames = (sharedComponents) => {
  return getElementsReferenceNames(sharedComponents);
};

const concatDcxBaseComponents = (releaseValue) => {
  const output = [];

  Object.keys(releaseValue ?? {}).forEach((property) => {
    const node = releaseValue[property];
    if (_.has(node, BASE_COMPONENTS_PROPERTY)) {
      const baseComponents = node[BASE_COMPONENTS_PROPERTY] ?? {};
      Object.keys(baseComponents).forEach((componentName) => {
        output.push({
          [componentName]: {
            ...baseComponents[componentName],
          },
        });
      });
    }
  });

  return output;
};

/**
 * /!\ Only works for dcx /!\
 * @param {object} releaseValue 
 * @param {string} contentId 
 * @param {string} key 
 * @returns list of relevant referenceNames based on the key
 */
const getRelevantSharedDataReferenceNames = ({ releaseValue, key }) => {
  const sharedComponents = [
    ..._.get(releaseValue, DCX_SHARED_DATA_PROPERTY, []),
    ...concatDcxBaseComponents(releaseValue),
  ];
  const relevantComponents = _.filter(sharedComponents, (item) => {
    return _.has(item, key);
  });

  return getSharedDataReferenceNames(relevantComponents);
};


module.exports = {
  B2C_PAGES_CONTENT_ID_REGEX,
  CLUBMED_PAGES_CONTENT_ID_REGEX,
  DCX_PAGES_CONTENT_ID_REGEX,
  PAGES_STR,
  isB2cPagesContentId,
  isClubmedPagesContentId,
  isDcxContentId,
  getDocumentsIndexNames,
  getRelevantSharedDataReferenceNames,
  getElementsReferenceNames,
  getSharedDataProperty,
  isPagesDocumentsIndexName,
  isReleaseDocumentsIndexName,
  isSharedDataDocumentsIndexName,
};
