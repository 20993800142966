'use strict';

let View,
  singleton;
const  account = require('models/account'),
  template = require('templates/editContent'),
  notificationEvent = require('NotificationEvent'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  config = require('models/config'),
  errorHandler = require('utils/errorHandler'),
  Content = require('models/content');

View = Backbone.View.extend({

  events: {
    'blur .cms-editable': 'updateContent',
    'click .js-addNotification': 'addNotificationsUrl',
    'click .js-removeNotification': 'removeNotificationsUrls',
  },

  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  bindContentButtons: function bindContentButtons () {
    this.$el.find('.restoreContent').off().click($.proxy(function () {
      const danger = true;
      Confirm.showMe('You are about to rollback this content and all of its releases to its state before its last update.', $.proxy(function () {
        this.restoreContent(true);
      }, this), $.proxy(() => {
      }, this), danger);
    }, this));

    this.$el.find('.deleteContent').off().click($.proxy(function () {
      const danger = true;
      Confirm.showMe('You are about to erase this content and all its releases.', $.proxy(function () {
        this.deleteContent(true);
      }, this), $.proxy(() => {
      }, this), danger);
    }, this));

    this.$el.find('.publishContent').off().click($.proxy(function () {
      if (this.model.isValid()) {
        try {
          if ($('.ace_error').length != 0) {
            notificationEvent.notify('JSON is not valid', 'danger');
          } else {
            Confirm.showMe('You are about to publish this content.', $.proxy(function () {
              this.publishContent(true);
            }, this), () => {
            });
          }
        } catch (error) {
          notificationEvent.notify(error.message, 'danger');
        }
      } else {
        notificationEvent.notify(this.model.validationError, 'danger');
      }
    }, this));
  },

  restoreContent: function restoreContent (retry) {
    const self = this;
    Spinner.show();
    notificationEvent.notify('Content rollback in progress... Please wait.', 'warning');
    const restoreContentModel = new Content({ id: this.model.get('id') });
    restoreContentModel.set('isRestoreAction', true);
    restoreContentModel.save(restoreContentModel.toJSON(), {
      success: function () {
        Spinner.hide();
        notificationEvent.notify('Content restored', 'success');
        const appRouter = require('router');
        appRouter.navigate('contents', { trigger: true });
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.restoreContent(false);
            }
          },
          fail: function () {
          },
        });
      },
    });

  },

  deleteContent: function deleteContent (retry) {
    const self = this;
    Spinner.show();
    this.model.destroy({
      success: function () {
        Spinner.hide();
        notificationEvent.notify('Content deleted', 'success');
        const appRouter = require('router');
        appRouter.navigate('contents', { trigger: true });
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.deleteContent(false);
            }
          },
          fail: function () {
          },
        });
      },
    });

  },

  addNotificationsUrl: function addNotificationsUrl () {
    const notifications_urlsDiv = $('#notifications_urls');
    const numberUrls = $('.js-editContent-urls').size();
    $('<div class="js-editContent-urls cms-editContent-urls"><input type="text" class="form-control cms-editable" id="content-notifications_urls_' + numberUrls + '"  /><a id= "content-remove_notifications_urls_' + numberUrls + '" class="cms-editContent-removeUrl glyphicon glyphicon-remove js-removeNotification" href="#"></a></div>').appendTo(notifications_urlsDiv);
    return false;
  },

  removeNotificationsUrls: function removeNotificationsUrls (ev) {
    this.updateContent(ev);
    ev.target.parentElement.remove();
    return false;
  },

  publishContent: function publishContent (retry) {
    const self = this;
    Spinner.show();
    try {
      this.model.set('schema', this.schemaEditor.get());
    } catch (error) {
      Spinner.hide();
      notificationEvent.notify('The JSON schema is empty or invalid', 'danger');
      return;
    }
    try {
      this.model.set('value', this.jsonEditor.get());
    } catch (error) {
      Spinner.hide();
      notificationEvent.notify('The JSON value is empty or invalid', 'danger');
      return;
    }
    this.model.save(this.model.toJSON(), {
      patch: true,
      success: function (model) {
        Spinner.hide();
        notificationEvent.notify('Content updated', 'success');
        self.model = model;
        self.close();
        self.render();
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.publishContent(false);
            }
          },
          fail: function () {
          },
        });
      },
    });

  },

  updateContent: function updateContent (event) {
    const $element = $(event.target);
    const id = $element.attr('id');
    if (id == 'content-preview_url') {
      this.model.set('preview_url', $element.val());
    } else if (id == 'content-block-delete') {
      this.model.set('deleteBlocked', $element.prop('checked'));
    } else if (id == 'content-desc') {
      this.model.set('desc', $element.val());
    } else if (id && id.indexOf('content-notifications_urls') !== -1) {
      const position = id.substring(id.lastIndexOf('_') + 1);
      const aNumbers = _.clone(this.model.get('notifications_urls'));
      aNumbers[position] = $element.val();
      this.model.set('notifications_urls', aNumbers);
    } else if (id && id.indexOf('content-remove_notifications_urls') !== -1) {
      const position = id.substring(id.lastIndexOf('_') + 1);
      const aNumbers = _.clone(this.model.get('notifications_urls'));
      aNumbers.splice(position, 1);
      this.model.set('notifications_urls', aNumbers);
    }
  },

  render: function render () {
    Spinner.hide();
    const html = template(this.model.toJSON());
    this.$el.html(html);
    $('#main').append(this.$el);
    const jsonContainer = document.getElementById('jsoneditor');
    const schemaContainer = document.getElementById('schemaeditor');
    const options = {
      mode: 'code',
      modes: ['code', 'tree'],
      error: function (err) {
        notificationEvent.notify(err.toString(), 'danger');
      },
    };

    if (account.isSuperAdmin()) {
      const contentId = this.model.get('id');
      const blockContentAndLocaleDelete = config.getOverriddenValue({
        jsonPath: 'featureFlipping.blockContentAndLocaleDelete',
        contentId: contentId,
      }) || 'false';
      if (!blockContentAndLocaleDelete || blockContentAndLocaleDelete === 'false') {
        $('#content-block-delete-row').removeClass('hidden');
        if (this.model.get('deleteBlocked') !== true) {
          $('#button-delete-content').removeClass('hidden');
        }
      }

      const backupContent = config.getOverriddenValue({
        jsonPath: 'featureFlipping.backupContent',
        contentId: contentId,
      }) || 'false';
      if (backupContent === 'true') {
          $('#button-restore-content').removeClass('hidden');
      }
    }
    this.bindContentButtons();

    this.jsonEditor = new JSONEditor(jsonContainer, options);
    this.jsonEditor.set(this.model.get('value'));
    this.schemaEditor = new JSONEditor(schemaContainer, options);
    this.schemaEditor.set(this.model.get('schema'));
    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (content, retry) {
    const self = this;
    content.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.model = model;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(content, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe (contentId) {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    const content = new Content({ id: contentId });
    this.retrieveData(content, true);
    return singleton;
  },
});

module.exports = View;
