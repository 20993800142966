'use strict';

const Releases = require('models/releases');

let Locale = Backbone.Model.extend({
    collection: {
        url: '/v1/locales',
    },
    initialize : function(attributes) {
        this.contentId = attributes.contentId;
        this.releases = new Releases({ contentId: attributes.contentId, localeId: attributes.id });
    },
    contentId: null,
    url: function() {
        if(this.contentId) {
            return '/v1/contents/' + this.contentId + '/locales/' + this.id;
        } 
            return '/v1/locales/' + this.id;
        
    },
});

module.exports = Locale;