const notificationEvent = _.extend({
    notify:function(message, level, persistant) {
        if(_.isUndefined(persistant)) {
            persistant = level === 'danger';
        }
        this.trigger('notification', {
            message: message,
            level: level,
            persistant: persistant,
        });
    },
}, Backbone.Events);
module.exports = notificationEvent;
