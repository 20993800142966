
var View,
  singleton,
  template = require('templates/release'),
  tagsTemplate = require('templates/releaseTags'),
  categoryTemplate = require('templates/releaseCategories'),
  Content = require('models/content'),
  account = require('models/account'),
  Release = require('models/release'),
  config = require('models/config'),
  notificationEvent = require('NotificationEvent'),
  LocalesView = require('views/localesModalView'),
  SearchAndReplaceModalView = require('views/searchAndReplaceModalView'),
  Confirm = require('views/confirmView'),
  TreeItemTools = require('utils/TreeItemTools'),
  TagsUtils = require('utils/TagsUtils'),
  Utils = require('utils/utils'),
  { getSharedDataProperty, getRelevantSharedDataReferenceNames, DCX_PAGES_CONTENT_ID_REGEX, PAGES_STR } = require('utils/clubmedPagesContents'),
  { DCX_COMMERCIAL_ANIMATION_PROPERTY } = require('utils/clubmedPagesContents'),
  UrlUtils = require('utils/UrlUtils'),
  TreeView = require('views/treeView'),
  FilterOnEvent = require('FilterOnEvent'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  SelectB2cCommonDraftOrB2cPagesDraftForPreviewModalView = require('views/selectB2cCommonDraftOrB2cPagesDraftForPreviewModalView'),
  ProgramPublicationModalView = require('views/programPublicationModalView'),
  State = require('../State');


function buildPreviewUrlByInterpolation(toBeInterpolatedUrl, input) {
  return toBeInterpolatedUrl.replace('{content}', input.contentId).replace('{locale}', input.localeId).replace('{release}', input.id);
}


function openPreviewWindow(url) {
  window.open(url, '_blank');
}


function _showSelectB2cCommonDraftOrB2cPagesDraftForPreviewModal(currentRelease, specialBehaviourContents, baseUrl) {
  SelectB2cCommonDraftOrB2cPagesDraftForPreviewModalView.showMe(currentRelease, specialBehaviourContents, baseUrl);
}

function initialize() {
  this.initHandlebarsHelpers();
  var self = this;

  $(window).bind('scroll', (ev) => {
    self.scroll(ev);
  });
}

function initModel(content, release) {
  var writeAllowed = account.isAllowed(release.get('content_id'), release.get('locale'), 'WRITE');
  var publishAllowed = account.isAllowed(release.get('content_id'), release.get('locale'), 'PUBLISH');
  this.isSavable = release.get('status') === 'draft' && (writeAllowed || publishAllowed);

  release.resetJsonPatch();
  if(this.selectedCategory) {
    TagsUtils.categoryFiltering(release, false, this.selectedCategory);
  }
  var templateModel = {
    release: release.toJSON(),
    isSavable: this.isSavable,
    selectedCategory: this.selectedCategory,
    isDeletable: release.get('id') != 0 && release.get('status') != 'live' && (writeAllowed || publishAllowed),
    isPreviewable: (content.get('preview_url')) ? content.get('preview_url') : false,
    isPublishable: release.get('status') == 'draft' && publishAllowed,
    isRollbackable: release.get('status') === 'archived' && publishAllowed,
    mediaServerUrl: config.get('mediaServerUrl'),
  };
  if (this.tagSelectView) {
    templateModel.tagsInfo = TagsUtils.getTagsInfo(release, this.isSavable, this.selectedCategory);
    templateModel.tagsJsonPath = TagsUtils.getTaggedElementsRootPath(release, false);
  }
  if(DCX_PAGES_CONTENT_ID_REGEX.test(release.get('content_id'))) {
    templateModel.categoryInfo = Object.keys(release.get('value')).filter((key) => !TagsUtils.getUnfilteredElements(release).includes(key));
  }
  if (this.releaseTags) {
    templateModel.mainTag = TagsUtils.getDecodedTags(this.releaseTags, ',').split(',')[0];
    templateModel.subTag = TagsUtils.getDecodedTags(this.releaseTags, ',').split(',')[1];
    templateModel.releaseTags = TagsUtils.getDecodedTags(this.releaseTags, ' > ');

  }
  return templateModel;
}

function resetTagDropdowns() {
  $('.cms-dropdown--content').addClass('hidden');
  $('.cms-release-tags-row').removeClass('dropdown-opened');
  $('.fa-angle-double-up').addClass('fa-angle-double-down');
  $('.fa-angle-double-up').removeClass('fa-angle-double-up');
}


function close() {
  this.$el.remove();
  this.off();
  if (this.model) {
    this.model.off(null, null, this);
  }
  if (this.treeView) {
    this.treeView.close();
  }
}

function scroll() {
  var scroll = $(window).scrollTop();
  if (!this.tagSelectView && scroll >= 98.5) {
    $('.js-menuTop-tools').addClass('cms-menuTop--fixed');
    $('#tree-menu').addClass('cms-tree-menu--moved');
  } else {
    $('.js-menuTop-tools').removeClass('cms-menuTop--fixed');
    $('#tree-menu').removeClass('cms-tree-menu--moved');
  }
}

function displayLocalesModal() {
  LocalesView.showMe(this.content.locales, (contentId, localeId) => {
    var appRouter = require('router');
    appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases', { trigger: true });
  });
}

function goToContentsPage() {

  State.add(State.action.SHOW_HOME_PAGE);
  var appRouter = require('router');
  appRouter.navigate('contents', { trigger: true });
}

function goToReleasesPage() {
  var appRouter = require('router');

  State.add(State.action.SHOW_RELEASES, this.contentId, this.localeId);
  appRouter.navigate('contents/' + this.contentId + '/locales/' + this.localeId + '/releases', { trigger: true });
}

function goToTagsPage() {
  var releaseTags = decodeURI($('#main-tag-link').attr('mainTag'));
  var appRouter = require('router');

  State.add(State.action.SHOW_MAIN_TAG, this.contentId, this.localeId, this.release.get('id'), releaseTags);
  appRouter.release(this.contentId, this.localeId, this.release.get('id'), releaseTags);
}


function displaySearchAndReplaceModal() {
  SearchAndReplaceModalView.showMe(this.release, this.isSavable, $.proxy(function (result) {
    var searchResult = _.get(result, 'searchResult', []);
    var searchStr = _.get(result, 'searchStr', '');
    var replaceStr = _.get(result, 'replaceStr', '');
    if (searchStr && searchResult.length) {
      for (var i = 0; i < searchResult.length; i++) {
        var element = searchResult[i];
        var jsonPath = _.clone(_.get(element, 'path', ''));
        var updatedValue = _.clone(_.get(element, 'value', ''));
        if (jsonPath && updatedValue) {
          updatedValue = Utils.replaceAll(updatedValue, searchStr, replaceStr);
          if (updatedValue && UrlUtils.isUrl(updatedValue)) {
            updatedValue = UrlUtils.autoformatUrl(updatedValue);
            updatedValue = UrlUtils.autoformatB2cUrl(this.contentId, updatedValue, true);
            updatedValue = UrlUtils.autoformatB2cAnchor(this.contentId, updatedValue, true);
          }

          this.release.setValue(jsonPath, updatedValue);
        }
      }
      var withTreeViewRefresh = true;
      this.saveRelease(withTreeViewRefresh);
    }
  }, this));
}

function initHandlebarsHelpers() {
  Handlebars.registerHelper('formatDate', Utils.formatDate);
  Handlebars.registerHelper('formatId', (value) => {
    return (value) ? '#' + value.substring(value.length - 4, value.length).toUpperCase() : '';
  });
  Handlebars.registerHelper('ifEq', function (value, otherValue, options) {
    if (value === otherValue) {
      return options.fn(this);
    }
    return options.inverse(this);

  });
  Handlebars.registerHelper('ifNotEq', function (value, otherValue, options) {
    if (value !== otherValue) {
      return options.fn(this);
    }
    return options.inverse(this);

  });
  Handlebars.registerHelper('ifIsImage', function (value, options) {
    if ((/\.(gif|GIF|jpg|JPG|jpeg|JPEG|tiff|TIFF|png|PNG)$/i).test(value)) {
      return options.fn(this);
    }
    return options.inverse(this);

  });
  Handlebars.registerHelper('encodeURIComponent', (value) => {
    return encodeURIComponent(value);
  });
  Handlebars.registerHelper('extractFileExtension', (value) => {
    return /[^.]+$/.exec(value);
  });
  Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
      case '==':
        return (v1 == v2) ? options.fn(this) : options.inverse(this);
      case '===':
        return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '!=':
        return (v1 != v2) ? options.fn(this) : options.inverse(this);
      case '!==':
        return (v1 !== v2) ? options.fn(this) : options.inverse(this);
      case '<':
        return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
        return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
        return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
        return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
        return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '||':
        return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });
  Handlebars.registerHelper('ifHasIndexOf', function (value, otherValue, options) {
    if (value.indexOf(otherValue) !== -1) {
      return options.fn(this);
    }
    return options.inverse(this);

  });
}

function createTypeaheadSource() {
  var self = this;
  var result = [];
  TreeItemTools.iterate(this.release.get('value'), '', (property, value, path) => {
    if (path.indexOf('@metadata') === -1) {
      if (!Array.isArray(value) && !(value instanceof Object)) {
        var item = {
          id: path,
          name: value,
        };
        if (TagsUtils.contentHasTagsEnabled(self.release.get('content_id')) && !self.releaseTags) {
          var taggedElementsPath = TagsUtils.getTaggedElementsRootPath(self.release, false);
          if (path.startsWith(taggedElementsPath) || path === taggedElementsPath) {
            var currentElementPath = TagsUtils.getTaggedElementPath(path, '.', self.selectedCategory);
            var tagsPath = currentElementPath + '.@metadata.tags';
            var tags = self.release.getValue(tagsPath);
            item.tags = tags;
            item.name += ' -- tags: ' + TagsUtils.getDecodedTags(tags, ' > ');
          } else {
            item.tags = TagsUtils.UNTAGGED_TAGS_STRING;
            item.name += ' -- tags: ' + TagsUtils.UNTAGGED_TAGS_STRING;
          }
        }
        item.name += ' -- path: ' + path;
        result.push(item);
      }
    }
  });
  return result;
}

function displayResult(item) {
  FilterOnEvent.trigger('filterOn', item);
  var selectedPath = item.id;
  if (TagsUtils.contentHasTagsEnabled(this.release.get('content_id')) && !this.releaseTags) {
    this.releaseTags = _.clone(item.tags);


    this.render(this.content, this.release, selectedPath);
  } else {
    this.treeView.scrollToPath(selectedPath);
  }
}


function displayError(error) {
  if (!error.tags) {
    var itemError = new Object();
    itemError.id = error.property;
    itemError.message = error.message;
    FilterOnEvent.trigger('openErrors', itemError);
  }
}

function scrollToFirstError(errors) {
  for (var i = 0; i < errors.length; i++) {
    var error = errors[i];
    if (!error.tags) {
      var path = error.property;
      if (this.treeView) {
        this.treeView.scrollToPath(path);
      }
      break;
    }
  }
}

function displayText(item) {
  return typeof item !== 'undefined' && typeof item.name != 'undefined' ? item.name : item;
}

function bindTypeahead() {
  var self = this;

  var $input_release_search = $('#input-release-search');
  var $cms_search = $('.cms-search');


  $input_release_search.attr('placeholder', 'Hover to load ... ');
  $input_release_search.prop('readonly', true);
  $cms_search.css('cursor', 'wait');

  $cms_search.one('mouseover', () => {
    var typeaheadSource = self.createTypeaheadSource();
    $cms_search.css('cursor', 'pointer');
    $input_release_search.prop('readonly', false);
    $input_release_search.attr('placeholder', 'Type to search... (minimum word length: 2)');


    $input_release_search
      .typeahead('destroy')
      .typeahead({
        source: typeaheadSource,
        minLength: 2,
        items: 15,
        matcher: function (item) {
          var it = self.displayText(item);
          return ~it.toLowerCase().indexOf(this.query.toLowerCase());
        },
        sorter: function (items) {
          var beginswith = [];
          var caseSensitive = [];
          var caseInsensitive = [];
          var count = 0;
          var iterationLimit = 1500;
          var item;
          while ((item = items.shift())) {
            var inner_self = this;
            var it = self.displayText(item);
            if (!it.toLowerCase().indexOf(inner_self.query.toLowerCase())) {
              count++;
              beginswith.push(item);
            } else if (~it.indexOf(inner_self.query)) {
              count++;
              caseSensitive.push(item);
            } else {
              count++;
              caseInsensitive.push(item);
            }
            if (count >= iterationLimit) {
              break;
            }
          }
          return beginswith.concat(caseSensitive, caseInsensitive);
        },
        afterSelect: $.proxy(self.displayResult, self),
      });

  });

}

function updateTitleBtnProgramPublish(model) {
  var $title = $('#titleBtn-programPublish');
  var source = (model) ? model : this.release;
  var validationErrors = source.get('value_validation_errors');
  var publishDateProgrammedAlreadyUsed = source.get('metadata').publication_programming_date_already_used;
  var publishDateProgrammed = source.get('metadata').publication_programming_date;
  if (publishDateProgrammedAlreadyUsed) {
    $title.html('Error! Date already used: ' + Utils.formatDate(publishDateProgrammed));
    this._setBtnProgramPublishStatus(publishDateProgrammed);
  } else {
    if (publishDateProgrammed) {
      if (validationErrors && validationErrors.length) {
        $title.html('Error! Draft has validation errors: ' + Utils.formatDate(publishDateProgrammed));
      } else if (Utils.isDateInPast(publishDateProgrammed)) {
        $title.html('Error! Date in the past: ' + Utils.formatDate(publishDateProgrammed));
      } else {
        $title.html('Publication programmed on: ' + Utils.formatDate(publishDateProgrammed));
      }
    } else {
      $title.html('Publish Later');
    }
    this._setBtnProgramPublishStatus(publishDateProgrammed);
  }
}


function _setBtnProgramPublishStatus(publishDateProgrammed) {
  var validationErrors = this.release.get('value_validation_errors');
  var publishDateProgrammedAlreadyUsed = this.release.get('metadata').publication_programming_date_already_used;
  var $button = $('#button-programPublish-release');
  if (!publishDateProgrammed || (publishDateProgrammed && !Utils.isDateInPast(publishDateProgrammed) && !validationErrors.length && !publishDateProgrammedAlreadyUsed)) {
    $button.removeClass('button--danger');
    $button.addClass('cms-button--publish');
  } else {
    $button.removeClass('cms-button--publish');
    $button.addClass('button--danger');
  }
}

function bindCommonButtons() {

  this.$el.find('.js-locales-link').off().click($.proxy(this.displayLocalesModal, this));
  this.$el.find('.js-contents-link').off().click($.proxy(this.goToContentsPage, this));
  this.$el.find('.js-releases-link').off().click($.proxy(this.goToReleasesPage, this));
  this.$el.find('.js-tags-link').off().click($.proxy(this.goToTagsPage, this));

  this.$el.find('.release-search-replace').off().click($.proxy(this.displaySearchAndReplaceModal, this));

  this.$el.find('#button-back').off().click($.proxy(function () {
    if (this.releaseTags) {
      this.releaseTags = null;
      this.release.set('tagsListModified', null);
      Backbone.history.loadUrl();
    } else if (this.selectedCategory) {
      this.selectedCategory = null;
      Backbone.history.loadUrl();
    } else {
      var appRouter = require('router');

      State.add(State.action.SHOW_RELEASES, this.contentId, this.localeId);
      appRouter.navigate('contents/' + this.contentId + '/locales/' + this.localeId + '/releases', { trigger: true });
    }
  }, this));


  this.$el.find('#button-back-to').off().click($.proxy(() => {

    State.goToLastView();
  }, this));


}

function bindTagsButtons() {
  this.$el.find('#create-new-release-tag').off().click($.proxy(function () {
    var $newMainTagInput = $('#release-new-main-tag');
    var newMainTagStr = $newMainTagInput.val().trim();
    if (!newMainTagStr) {
      notificationEvent.notify('Please enter new main tag', 'danger');
      return false;
    }
    if (!/^[a-zA-Z0-9\-\s]+$/.test(newMainTagStr)) {
      notificationEvent.notify('Main tag can only contain "-" special character', 'danger');
      return false;
    }
    var newMainTag = decodeURIComponent(newMainTagStr.toUpperCase());
    this.release = TagsUtils.updateTagsList({
      release: this.release,
      newTags: _.clone(newMainTag),
      operation: 'add',
    }, this.selectedCategory);
    this.render(this.content, this.release);
    this.saveRelease();


  }, this));

  this.$el.find('.cms-release-categories-row').off().click((event) => {
    if(event && event.stopPropagation) {
      event.stopPropagation();
    }

    this.selectedCategory = decodeURI($(event.currentTarget).data('category'));
    var self = this;
    Spinner.show();
    setTimeout(() => {
      self.render(self.content, self.release);
    }, 500);
  });

  this.$el.find('.cms-release-tags-row').off().click($.proxy(function (event) {


    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.releaseTags = decodeURI($(event.currentTarget).data('mainTag'));

    State.add(State.action.SHOW_MAIN_TAG, this.content.get('id'), this.localeId, this.release.get('id'), this.releaseTags);
    if (this.releaseTags === TagsUtils.ALL_TAGS_STRING) {
      notificationEvent.notify('Loading all ' + TagsUtils.getTaggedElementsRootPath(this.release, false) + '...', 'warning');
    }
    var self = this;
    Spinner.show();
    setTimeout(() => {
      self.render(self.content, self.release);
    }, 500);
  }, this));

  this.$el.find('.cms-release-sub-tags-row').off().click($.proxy(function (event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }


    var mainTag = decodeURI($(event.currentTarget).data('mainTag'));
    var subTag = decodeURI($(event.currentTarget).data('subTag'));


    State.add(State.action.SHOW_SUB_TAG, this.content.get('id'), this.localeId, this.release.get('id'), mainTag, subTag);
    this.releaseTags = [mainTag, subTag].join(',');

    var self = this;
    Spinner.show();
    setTimeout(() => {
      self.render(self.content, self.release);
    }, 500);
  }, this));

  this.$el.find('.editTags').off().click($.proxy((event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    var appRouter = require('router'),
      contentId = $(event.currentTarget).data('contentId'),
      localeId = $(event.currentTarget).data('localeId'),
      releaseId = $(event.currentTarget).data('releaseId'),
      tags = $(event.currentTarget).data('mainTag');

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    State.add(State.action.SHOW_EDIT_TAGS, contentId, localeId, releaseId, tags);
    appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases/' + releaseId + '/editTags/' + tags + (this.selectedCategory ? ('/category/' + this.selectedCategory) : '') , { trigger: true });
  }, this));

  this.$el.find('.editSubTags').off().click($.proxy((event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    var appRouter = require('router'),
      contentId = $(event.currentTarget).data('contentId'),
      localeId = $(event.currentTarget).data('localeId'),
      releaseId = $(event.currentTarget).data('releaseId'),
      mainTag = $(event.currentTarget).data('mainTag'),
      subTag = $(event.currentTarget).data('subTag'),
      tags = [mainTag, subTag].join(',');

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    State.add(State.action.SHOW_EDIT_TAGS, contentId, localeId, releaseId, tags);
    appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases/' + releaseId + '/editTags/' + tags + (this.selectedCategory ? ('/category/' + this.selectedCategory) : ''), { trigger: true });
  }, this));

  this.$el.find('.dropdownTags').off().click($.proxy(function (event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    var mainTag = $(event.currentTarget).data('mainTag');
    var dropdownId = 'dropdown-' + mainTag;
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    var $dropdownButton = $('[id="' + dropdownId + '"]');
    var $mainTagRow = $dropdownButton.closest('.cms-release-tags-row');
    if ($dropdownButton.hasClass('hidden')) {
      this.resetTagDropdowns();
      $dropdownButton.removeClass('hidden');
      $mainTagRow.addClass('dropdown-opened');
      var $faAngleDoubleDown = $dropdownButton.siblings().children('.fa-angle-double-down');
      $faAngleDoubleDown.removeClass('fa-angle-double-down');
      $faAngleDoubleDown.addClass('fa-angle-double-up');
    } else {
      this.resetTagDropdowns();
    }
  }, this));

  window.onclick = $.proxy(function (event) {
    if (!event.target.matches('.dropdownTags')) {
      this.resetTagDropdowns();
    }
  }, this);
}


function bindReleaseButtons() {
  this.$el.find('#button-save-release').off().click($.proxy(function () {

    var refresh = this.release.get('content_id') === 'feature-flip';
    this.saveRelease(refresh);
  }, this));

  this.$el.find('#button-preview-release').off().click($.proxy(function () {

    var contentId = this.release.get('content_id'),
      localeId = this.release.get('locale'),
      id = this.release.get('id');

    var specialBehaviourContents =
      config.get('specialBehaviour')
      && config.get('specialBehaviour').contentsForWhichAnUserCanChooseContentDraft;

    var currentRelease = {
      contentId: contentId,
      localeId: localeId,
      id: id,
    };

    new Content({ id: contentId }).fetch({

      success: function (content) {

        var previewUrl = content.get('preview_url');
        var [baseUrl] = previewUrl.split('?');


        // Open Modal To select B2C-Common drafts or B2C-Pages Drafts--
        if (Object.values(specialBehaviourContents).includes(contentId)) {
          _showSelectB2cCommonDraftOrB2cPagesDraftForPreviewModal(
            currentRelease, specialBehaviourContents, baseUrl,
          );
        }

        // Open Preview Link in new tab
        else {
          var realUrl = buildPreviewUrlByInterpolation(previewUrl, currentRelease);
          openPreviewWindow(realUrl);
        }
      },
    });

  }, this));

  this.$el.find('#button-delete-release').off().click($.proxy(function () {
    $('.edit-button').prop('disabled', true);
    var danger = true;
    Confirm.showMe('You are about to delete this release.', $.proxy(function () {
      this.deleteRelease(true);
    }, this), $.proxy(() => {
      $('.edit-button').prop('disabled', false);
    }, this), danger);
  }, this));

  this.$el.find('.button-publish-release').off().click($.proxy(function () {
    $('.edit-button').prop('disabled', true);
    var contentId = this.release.get('content_id'),
      localeId = this.release.get('locale'),
      releaseId = this.release.get('id'),
      previousStatus = this.release.get('status');

    var publishString = (previousStatus === 'archived') ? 're-publish' : 'publish';
    Confirm.showMe('You are about to ' + publishString + ' this release.', $.proxy(function () {
      this.patchRelease({
        contentId: contentId,
        localeId: localeId,
        releaseId: releaseId,
        previousStatus: previousStatus,
        action: 'publish',
        publishString: publishString,
        retry: true,
      });
    }, this), $.proxy(() => {
      $('.edit-button').prop('disabled', false);
    }, this));
  }, this));

  this.$el.find('#button-programPublish-release').off().click($.proxy(function () {
    $('.edit-button').prop('disabled', true);
    var contentId = this.release.get('content_id'),
      localeId = this.release.get('locale'),
      releaseId = this.release.get('id'),
      previousStatus = this.release.get('status');

    ProgramPublicationModalView.showMe(this.release.get('metadata').publication_programming_date, $.proxy(function (selectedDate) {
      this.release.get('metadata').publication_programming_date = selectedDate;
      this.patchRelease({
        contentId: contentId,
        localeId: localeId,
        releaseId: releaseId,
        previousStatus: previousStatus,
        action: 'programPublish',
        retry: true,
      });
    }, this), $.proxy(() => {
      $('.edit-button').prop('disabled', false);
    }, this));
  }, this));
}

function deleteRelease(retry) {
  var self = this;
  self.release.concurrencyCheck().done(() => {
    Spinner.show();
    self.release.destroy({
      success: function () {
        self.enableButtonsAndStopSpinner();
        State.add(State.action.SHOW_RELEASES, self.content.id, self.release.get('locale'));
        var appRouter = require('router');
        appRouter.navigate('contents/' + self.content.id + '/locales/' + self.release.get('locale') + '/releases', { trigger: true });
        notificationEvent.notify('Release deleted', 'success');
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.deleteRelease(false);
            }
          },
          fail: function () {
            self.enableButtonsAndStopSpinner();
          },
        });
      },
    });
  });
  $('.edit-button').prop('disabled', false);
}

function saveRelease(withTreeViewRefresh) {
  $('.edit-button').prop('disabled', true);
  var contentId = this.release.get('content_id'),
    localeId = this.release.get('locale'),
    releaseId = this.release.get('id'),
    previousStatus = this.release.get('status');

  if (previousStatus !== 'draft') {
    notificationEvent.notify('You can only save draft', 'danger');
  } else {
    this.cleanupSharedComponents();
    this.cleanupSharedCommercialComponents();
    this.patchRelease({
      contentId: contentId,
      localeId: localeId,
      releaseId: releaseId,
      previousStatus: previousStatus,
      action: 'save',
      withTreeViewRefresh: withTreeViewRefresh || false,
      retry: true,
    });
  }
}

function cleanupSharedComponents() {
  TreeItemTools.iterate(this.release.get('value'), '', (property, value, itemPath) => {
    if (itemPath.indexOf('referenceName') === -1 || itemPath.indexOf(PAGES_STR) === -1 || value === '') {
      return;
    }
    const key = Utils.getLastPathPart(Utils.getParentPath(itemPath, '.'), '.');
    const relevantSharedComponents = getRelevantSharedDataReferenceNames({ releaseValue: this.release.get('value'), contentId: this.release.get('content_id'), key, path: itemPath });
    
    if(relevantSharedComponents.length === 0) {
      return;
    }

    if(!relevantSharedComponents.includes(value)) {
      this.release.setValue(itemPath,'');
    }
  });
}

function cleanupSharedCommercialComponents() {
  TreeItemTools.iterate(this.release.get('value'), '', (property, value, itemPath) => {
    if (itemPath.toLowerCase().indexOf('referencename') === -1 || itemPath.indexOf(DCX_COMMERCIAL_ANIMATION_PROPERTY) === -1 || value === '') {
      return;
    }
    const key = !Utils.getLastPathPart(itemPath).includes('ReferenceName') ? Utils.getLastPathPart(Utils.getParentPath(itemPath, '.'), '.') : '';
    const relevantSharedComponents = getSharedDataProperty({ releaseValue: this.release.get('value'), contentId: this.release.get('content_id'), key });

    if(!relevantSharedComponents.includes(value)) {
      this.release.setValue(itemPath,'');
    }
  });
}

function patchRelease(input) {
  var self = this;
  self.release.concurrencyCheck().done(() => {
    self.release.self_jsonpatch_opsCheck().done(() => {
      self.release.specificContentValueCheck().done(() => {
        Spinner.show();
        const jsonRelease = self.release.formatDraftForPatch({
          title: $('#input-release-title').val(),
          last_update_user: account.get('name') || '',
          isPublish: (input.action === 'publish'),
          isRollback: (input.action === 'publish' && input.previousStatus === 'archived'),
        });
        self.release.save(jsonRelease, {
          patch: true,
          success: function () {
            switch (input.action) {
              case 'publish':
                self.publishRelease(input);
                break;
              default:
                self.getUpdatedReleaseInfo(input);
                notificationEvent.notify('Draft saved', 'success');
                break;
            }
          },
          error: function (model, error) {
            errorHandler({
              error: error,
              retry: input.retry,
              success: function () {
                if (input.retry) {
                  input.retry = false;
                  if (input.action === 'publish') {
                    self.release.set('status', input.previousStatus);
                  }
                  self.patchRelease(input);
                }
              },
              fail: function () {
                self.enableButtonsAndStopSpinner();
                input.response = error;
                self.getUpdatedRelease(input);
              },
            });
          },
        });
      }).fail((errors) => {
        var tagsMetadata = self.release.get('tagsMetadata') || {};
        var message = self._extractValidationMsg(errors, tagsMetadata);
        var baseMessageError = (input.isPublish) ? 'Your release could not be ' + input.publishString + 'ed because it has validation errors: ' : 'Your release could not been saved because it has validation errors: ';
        var errorMsg = baseMessageError + message;
        notificationEvent.notify(errorMsg, 'danger');
        self.enableButtonsAndStopSpinner();
      });
    }).fail(() => {
      self.enableButtonsAndStopSpinner();
    });
  }).fail(() => {
    self.enableButtonsAndStopSpinner();
  });
}

function getUpdatedReleaseInfo(input) {
  // Reset errors display
  FilterOnEvent.trigger('iniErrors');

  if (input.response) {
    notificationEvent.notify('Processing errors...', 'warning', true);
  }

  var updatedRelease = new Release({
    content_id: input.contentId,
    locale: input.localeId,
    id: input.releaseId,
    infoOnly: true,
  });
  var self = this;
  updatedRelease.fetch({
    success: function (model) {
      self.copyUpdatedReleaseInfo(model);
      self.filterReleaseValueWithSelectedTags();
      if (input.response) {
        self.checkValidationErrors(model, input);
      } else {
        var tagsListModificationsSaved = true;
        self.release.resetJsonPatch(tagsListModificationsSaved);
      }
      self.bindTypeahead();
      self.updateTitleBtnProgramPublish(model);
      self.enableButtonsAndStopSpinner();
      if (input.withTreeViewRefresh) {
        self.renderTreeView();
      }
    },
    error: function (model, error) {
      errorHandler({
        error: error,
        retry: input.retry,
        success: function () {
          if (input.retry) {
            input.retry = false;
            self.getUpdatedReleaseInfo(input);
          }
        },
        fail: function (jsonError) {
          self.updateTitleBtnProgramPublish();
          self.enableButtonsAndStopSpinner();
        },
      });
    },
  });
}

function getUpdatedRelease(input) {
  // Reset errors display
  FilterOnEvent.trigger('iniErrors');

  if (input.response) {
    notificationEvent.notify('Processing errors...', 'warning', true);
  }

  var updatedRelease = new Release({
    content_id: input.contentId,
    locale: input.localeId,
    id: input.releaseId,
  });
  var self = this;
  updatedRelease.fetch({
    success: function (model) {
      self.copyUpdatedReleaseInfo(model);
      self.copyUpdatedReleaseValue(model);
      self.filterReleaseValueWithSelectedTags();
      if (input.response) {
        self.checkValidationErrors(model, input);
      } else {
        var tagsListModificationsSaved = true;
        self.release.resetJsonPatch(tagsListModificationsSaved);
      }
      self.bindTypeahead();
      self.updateTitleBtnProgramPublish(model);
      self.enableButtonsAndStopSpinner();
      if (input.withTreeViewRefresh) {
        self.renderTreeView();
      }
    },
    error: function (model, error) {
      errorHandler({
        error: error,
        retry: input.retry,
        success: function () {
          if (input.retry) {
            input.retry = false;
            self.getUpdatedRelease(input);
          }
        },
        fail: function (jsonError) {
          self.updateTitleBtnProgramPublish();
          self.enableButtonsAndStopSpinner();
        },
      });
    },
  });
}

function copyUpdatedReleaseInfo(model) {
  this.release.set('status', model.get('status'));
  this.release.set('title', model.get('title'));
  this.release.set('publish_date', model.get('publish_date'));
  this.release.set('value_validation_errors', model.get('value_validation_errors'));
  this.release.set('pageCount', model.get('pageCount'));
  this.release.set('live_release_id', model.get('live_release_id'));
  this.release.set('metadata', model.get('metadata'));
}


function copyUpdatedReleaseValue(model) {
  this.release.set('value', model.get('value'));
}

function filterReleaseValueWithSelectedTags() {
  if (this.releaseTags) {
    this.release = TagsUtils.tagsFiltering(this.release, this.releaseTags, false, this.selectedCategory);
  }
}

function getTagsMapping() {
  this.release = TagsUtils.tagsMapping(this.release, false, this.selectedCategory);
}

function checkValidationErrors(model, input) {
  if (model.get('value_validation_errors') && model.get('value_validation_errors').length) {
    this.release.resetJsonPatch();
    var tagsMetadata = this.release.get('tagsMetadata') || {};
    var message = this._extractValidationMsg(model.get('value_validation_errors'), tagsMetadata);
    var baseMessageError = (input.isPublish) ? 'Your release has been saved but it could not be ' + input.publishString + 'ed because it has validation errors: ' : 'Your release has been saved but it has validation errors: ';
    var errorMsg = baseMessageError + message;
    var publishDateProgrammed = (model && model.get('metadata')) ? model.get('metadata').publication_programming_date : '';
    if (publishDateProgrammed) {
      errorMsg += '<br /><b>Warning: your release will not be publish on "' + Utils.formatDate(publishDateProgrammed) + '" until you correct all errors.</b>';
    }
    notificationEvent.notify(errorMsg, 'danger');
  } else {
    this.displayNotSavedError(input);
  }
}

function publishRelease(input) {
  this.enableButtonsAndStopSpinner();
  var appRouter = require('router');
  State.add(State.action.SHOW_RELEASES, input.contentId, input.localeId);
  appRouter.navigate('contents/' + input.contentId + '/locales/' + input.localeId + '/releases', { trigger: true });
  var notificationMessage = 'Release ' + input.publishString + 'ed!';
  if (!input.retry) {
    notificationMessage += ', please refresh';
  }
  notificationEvent.notify(notificationMessage, 'success');
}


function displayNotSavedError(input) {
  this.enableButtonsAndStopSpinner();
  var error = (input && input.response && input.response.responseJSON) ? input.response.responseJSON : null;
  var message = 'Your release could not be saved because of a technical error';
  if (error) {
    if (error.statusCode !== 500) {
      message = error.error_description;
    } else {
      message += ': ' + error.error_description;
    }
  }
  notificationEvent.notify(message, 'danger');
}

function enableButtonsAndStopSpinner() {
  Spinner.hide();
  $('.edit-button').prop('disabled', false);
}

function _extractValidationMsg(errors, tagsMetadata) {
  var MAX_NUMBER_OF_ERRORS_DISPLAYED = 3;
  var message = '<br />';
  var self = this;
  if (Array.isArray(errors) && errors.length) {
    errors = self._formatValidationErrors(errors, tagsMetadata);
    if (Utils.isNotEmptyObject(tagsMetadata) && tagsMetadata.selectedTags) {
      // Sort errors to put them on current tags first
      var errorsOnCurrentTags = errors.filter((error) => {
        return !error.tags;
      });
      var errorsOnOtherTags = errors.filter((error) => {
        return error.tags;
      });
      errors = errorsOnCurrentTags.concat(errorsOnOtherTags);
    }
    var loopLength = (errors.length < MAX_NUMBER_OF_ERRORS_DISPLAYED) ? errors.length : MAX_NUMBER_OF_ERRORS_DISPLAYED;
    for (var i = 0; i < loopLength; i++) {
      var item = errors[i];
      self.displayError(item);
      message += '<b>';
      var errorText = (item.message.indexOf('&&') < 0) ? 'Error' : 'Errors';
      var selectedTags = _.get(tagsMetadata, 'selectedTags', '');
      var taggedElementsRootPath = _.get(tagsMetadata, 'taggedElementsRootPath', '');
      var isTaggedElementError = (taggedElementsRootPath && item.property && item.property.startsWith(taggedElementsRootPath));
      var title = self._getTitle(item, tagsMetadata);
      if (title) {
        message += '- ' + errorText + ': "' + title + '" ' + item.message;
      } else {
        message += '- ' + errorText + ': ' + item.message;
      }
      message += '</b>';
      message += '<ul>';
      if (item.tags && (!selectedTags || isTaggedElementError)) {
        message += '<li><b>tag:</b> ' + TagsUtils.getDecodedTags(item.tags, ' > ') + '</li>';
      }
      if (item.property && (!isTaggedElementError || !item.tags)) {
        message += '<li><b>path:</b> ' + item.property + '</li>';
      }
      if (item.solution) {
        message += '<li><b>solution:</b> ' + item.solution + '</li>';
      }
      message += '</ul>';
    }
    this.scrollToFirstError(errors);
  } else {
    message += errors;
  }
  return message;
}

function _formatValidationErrors(errors, tagsMetadata) {
  var tagsMetadataHiddenPageIds = _.get(tagsMetadata, 'hiddenPageIds', []);
  errors = errors.map((error) => {
    // Rewrite message of pattern errors
    var { message } = error;
    if (message.indexOf('should match pattern') >= 0) {
      message = message.slice(0, message.indexOf('pattern'));
      message += 'the specified pattern (i.e. the value description)';
      error.message = message;
    }
    // Filter tagged element indexes in error property
    var taggedElementsRootPath = _.get(tagsMetadata, 'taggedElementsRootPath', '');
    if (error.property && taggedElementsRootPath) {
      if (error.property.startsWith(taggedElementsRootPath)) {
        var taggedElementParentPath = taggedElementsRootPath + '.';
        var unfilteredPath = _.clone(error.property).replace(taggedElementParentPath, '');
        error.originalProperty = _.clone(error.property);
        var splittedUnfilteredPath = unfilteredPath.split('.');
        var unfilteredIndex = (splittedUnfilteredPath && splittedUnfilteredPath.length) ? splittedUnfilteredPath[0] : '';
        if (tagsMetadata.selectedTags) {
          if (tagsMetadata.selectedTags !== TagsUtils.ALL_TAGS_STRING) {
            if (Utils.isNormalInteger(unfilteredIndex)) {
              var filteredIndex = tagsMetadata.filteredIndexesMap.indexOf(parseInt(unfilteredIndex, 10));
              if (filteredIndex === -1) {
                var tags = tagsMetadata.tagsMap[unfilteredIndex];
                error.tags = (tags) ? tags : TagsUtils.UNTAGGED_TAGS_STRING;
              } else {
                error.property = error.property.replace(taggedElementParentPath + unfilteredIndex, taggedElementParentPath + filteredIndex);
              }
            }
          }
        } else {
          var tags = tagsMetadata.tagsMap[unfilteredIndex];
          error.tags = (tags) ? tags : TagsUtils.UNTAGGED_TAGS_STRING;
        }
      } else {
        error.tags = TagsUtils.UNTAGGED_TAGS_STRING;
      }
    }
    return error;
  }, {
    tagsMetadata: tagsMetadata,
  });
  for (var i = 0; i < errors.length - 1; i++) {
    var error = errors[i];
    var nextError = errors[i + 1];
    if (error.property === nextError.property) {
      var newError = {
        message: nextError.message + ' <b>and</b> ' + error.message,
        property: error.property,
        solution: (error.solution && nextError.solution) ? nextError.solution + ' <b>or</b> ' + error.solution : error.solution + nextError.solution,
      };
      if (error.tags) {
        newError.tags = error.tags;
      }
      errors[i] = null;
      errors[i + 1] = newError;
    } else if (error.message === nextError.message && (error.message.startsWith('pageId must be unique!') || error.message.startsWith('url can only be used by one active page at a time!'))) {
      var newError = {
        message: error.message,
        property: error.property + ' <b>and</b> ' + nextError.property,
        solution: error.solution,
      };
      if (tagsMetadataHiddenPageIds.length > 0) {
        newError.property = error.originalProperty;
      }
      if (error.tags && nextError.tags) {
        if (error.tags === nextError.tags) {
          newError.tags = error.tags;
        } else {
          var splittedErrorTags = error.tags.split(',');
          var splittedNextErrorTags = nextError.tags.split(',');
          if (splittedErrorTags[0] === splittedNextErrorTags[0]) {
            newError.tags = splittedErrorTags[0];
          } else {
            newError.tags = 'ALL';
          }
        }
      }
      errors[i] = null;
      errors[i + 1] = newError;
    }
  }
  return errors.filter((error) => {
    return (Utils.isNotEmptyObject(error));
  });
}

function _getTitle(item, tagsMetadata) {
  var title = '';
  if (item.property) {
    var taggedElementsRootPath = _.get(tagsMetadata, 'taggedElementsRootPath', '');
    var isTaggedElementError = (taggedElementsRootPath && item.property && item.property.startsWith(taggedElementsRootPath));
    var path = _.clone(item.property);
    var splittedPath = path.split('.');
    var pathPartCount = splittedPath.length;
    var pathLastPart = Utils.getLastPathPart(path);
    if (isTaggedElementError) {
      var taggedElementPath = splittedPath.slice(1, 2).join('.');
      var taggedElementIndex = Utils.getLastPathPart(taggedElementPath, '.');
      if (item.message.startsWith('pageId must be unique!') || item.message.startsWith('url can only be used by one active page at a time!')) {
        return '';
      }
      var taggedElementTitle;
      if (tagsMetadata.hasOwnProperty('filteredIndexesMap')) {
        taggedElementTitle = tagsMetadata.titlesMap[tagsMetadata.filteredIndexesMap[taggedElementIndex]];
      } else {
        taggedElementTitle = tagsMetadata.titlesMap[taggedElementIndex];
      }

      var taggedElementErrorPath;
      if (pathPartCount > 2) {
        taggedElementErrorPath = splittedPath.slice(2, pathPartCount).join('.');
      }
      if (taggedElementErrorPath) {
        title = taggedElementErrorPath;
        if (taggedElementTitle) {
          title += ' from ' + taggedElementTitle;
        }
      } else {
        title = taggedElementTitle;
      }

    }
    if (!title) {
      if (pathPartCount >= 2) {
        var lastPartParent = Utils.getLastPathPart(Utils.getParentPath(path, '.'), '.');
        if (lastPartParent && !Utils.isNormalInteger(lastPartParent)) {
          title = [lastPartParent, pathLastPart].join('.');
        } else {
          title = pathLastPart;
        }
      } else {
        title = path;
      }
    }
  }
  return title;
}


function renderTreeView(selectedPath) {
  if (this.treeView) {
    this.treeView.close();
  }
  this.treeView = TreeView.showNew(this, this.content, this.release, this.releaseTags, selectedPath, this.selectedCategory);
}

function render(content, release, selectedPath) {
  Spinner.hide();


  $('.edit-button').prop('disabled', false);
  this.content = content;
  this.release = release;
  this.tagSelectView = false;
  this.categorySelectView = true;

  if (release && release.get('value')) {

    if (TagsUtils.contentHasTagsEnabled(this.release.get('content_id')) && !this.releaseTags) {
      this.getTagsMapping();
      this.tagSelectView = true;

      var templateModel = this.initModel(content, release);
      var html = tagsTemplate(templateModel);
      var categoryTemplateInstance = categoryTemplate(templateModel);
      
      if(DCX_PAGES_CONTENT_ID_REGEX.test(release.get('content_id')) && !this.selectedCategory) {
        this.$el.html(categoryTemplateInstance);
      } else {
      this.$el.html(html);
      }
      
      $('#main').append(this.$el);
      if (this.release.get('status') === 'draft') {
        $('#input-release-title').prop('disabled', false);
      }
      this.bindTypeahead();
      this.bindCommonButtons();
      this.bindReleaseButtons();
      this.bindTagsButtons();
      this.delegateEvents();
      return this;
    }
    this.filterReleaseValueWithSelectedTags();
    if (selectedPath && TagsUtils.jsonPathStartsWithTaggedRootPath(this.release.get('content_id'), selectedPath)) {
      selectedPath = TagsUtils.getTagsFilteredPath(this.release, selectedPath);
    }
    var templateModel = this.initModel(content, release);
    var html = template(templateModel);
    this.$el.html(html);
    $('#main').append(this.$el);
    if (this.release.get('status') === 'draft') {
      $('#input-release-title').prop('disabled', false);
    }
    this.bindTypeahead();

    this.bindCommonButtons();
    this.bindReleaseButtons();
    this.renderTreeView(selectedPath);
    this.updateTitleBtnProgramPublish();
    this.delegateEvents();
    if (this.releaseTags && this.release.get('tagsListModified')) {
      this.saveRelease();
    }
  } else {
    notificationEvent.notify('Release could not be loaded, please refresh', 'danger');
  }


  return this;
}


function retrieveData(content, release, retry) {
  var self = this;
  var promises = [content.fetch(), release.fetch()];
  $.when.apply($, promises).done(() => {
    Spinner.hide();
    var appRouter = require('router');
    if (!appRouter.activeView || appRouter.activeView == singleton) {
      singleton.render(content, release);
    }
  }).fail((error) => {
    errorHandler({
      error: error,
      retry: retry,
      success: function () {
        if (retry) {
          self.retrieveData(content, release, false);
        }
      },
      fail: function (jsonError) {
      },
    });
  });
}

function showMe(contentId, localeId, releaseId, mainTag, subTag) {
  Spinner.show();
  if (!singleton) {
    singleton = new View();
  }

  singleton.releaseTags = [mainTag ? mainTag : '', subTag ? ',' : '', subTag ? subTag : ''].join('');
  singleton.selectedCategory = null;
  singleton.contentId = contentId;
  singleton.localeId = localeId;
  singleton.mainTag = '';
  singleton.subTag = '';

  var content = new Content({ id: contentId });

  var release = new Release({
    id: releaseId,
    content_id: contentId,
    locale: localeId,
  });

  this.retrieveData(content, release, true);
  return singleton;
}


View = Backbone.View.extend({
  initialize: initialize,
  initModel: initModel,
  resetTagDropdowns: resetTagDropdowns,
  close: close,
  scroll: scroll,
  displayLocalesModal: displayLocalesModal,
  goToContentsPage: goToContentsPage,
  goToReleasesPage: goToReleasesPage,
  goToTagsPage: goToTagsPage,
  displaySearchAndReplaceModal: displaySearchAndReplaceModal,
  initHandlebarsHelpers: initHandlebarsHelpers,
  createTypeaheadSource: createTypeaheadSource,
  cleanupSharedComponents: cleanupSharedComponents,
  cleanupSharedCommercialComponents: cleanupSharedCommercialComponents,
  displayResult: displayResult,
  displayError: displayError,
  scrollToFirstError: scrollToFirstError,
  displayText: displayText,
  bindTypeahead: bindTypeahead,
  updateTitleBtnProgramPublish: updateTitleBtnProgramPublish,
  _setBtnProgramPublishStatus: _setBtnProgramPublishStatus,
  bindCommonButtons: bindCommonButtons,
  bindTagsButtons: bindTagsButtons,
  bindReleaseButtons: bindReleaseButtons,
  deleteRelease: deleteRelease,
  saveRelease: saveRelease,
  patchRelease: patchRelease,
  getUpdatedReleaseInfo: getUpdatedReleaseInfo,
  getUpdatedRelease: getUpdatedRelease,
  copyUpdatedReleaseInfo: copyUpdatedReleaseInfo,
  copyUpdatedReleaseValue: copyUpdatedReleaseValue,
  filterReleaseValueWithSelectedTags: filterReleaseValueWithSelectedTags,
  getTagsMapping: getTagsMapping,
  checkValidationErrors: checkValidationErrors,
  publishRelease: publishRelease,
  displayNotSavedError: displayNotSavedError,
  enableButtonsAndStopSpinner: enableButtonsAndStopSpinner,
  _extractValidationMsg: _extractValidationMsg,
  _formatValidationErrors: _formatValidationErrors,
  _getTitle: _getTitle,
  renderTreeView: renderTreeView,
  render: render,
}, {
  retrieveData: retrieveData,
  showMe: showMe,
});

module.exports = View;
