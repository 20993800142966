'use strict';

let View,
  singleton;
const  template = require('templates/header'),
  account = require('models/account'),
  config = require('models/config'),
  notificationEvent = require('NotificationEvent'),
  appRouter = require('router');

View = Backbone.View.extend({

  el: '#header',

  events: {
    'click .js-navbar-link': 'closeMenu',
    'click .js-header-toContent': 'displayContents',
    'click #item-display-feature-flip-content': 'displayFeatureFlip',
    'click #item-display-edit-feature-flip-content': 'editFeatureFlip',
    'click .js-header-toDuplicateContent': 'duplicateContent',
    'click .js-header-toImportContent': 'importContent',
    'click #button-development-api-doc': 'displayCmsDoc',
    'click #button-logout': 'userLogout',
    'click #item-display-list-migrate-content': 'displayMigrateContent',
    'click #item-display-list-migrate-content-v2': 'displayMigrateContentV2',
    'click #item-display-list-migrate-content-dcx': 'displayMigrateContentDcx',
    'click #item-display-list-users': 'displayUserAdmin',
    'click #welcome-icon': 'copyTokenToClipboard',
  },

  closeMenu: function closeMenu () {
    const menuButton = $('.js-button-toggle');
    if (!menuButton.hasClass('collapsed')) {
      $('.navbar-toggle').click();
    }
  },

  displayContents: function displayContents () {
    appRouter.navigate('contents', { trigger: true });
  },

  displayFeatureFlip: function displayFeatureFlip () {
    appRouter.navigate('/contents/feature-flip/locales/fr-FR/releases', { trigger: true });
  },
  editFeatureFlip: function editFeatureFlip () {
    appRouter.navigate('/editContent/feature-flip', { trigger: true });
  },

  duplicateContent: function duplicateContent () {
    appRouter.navigate('duplicateContent', { trigger: true });
  },

  importContent: function importContent () {
    appRouter.navigate('addToken/true', { trigger: true });
  },

  renderHtml: function renderHtml () {
    const html = template({ account: account.toJSON() });
    this.$el.html(html);
  },

  renderButtons: function renderButtons () {
    if (account.get('profile')) {
      $('#button-home').addClass('js-header-toContent');
      $('#button-display-contents-section').removeClass('hidden');
    }
    if (account.isPo()) {
      $('#po-dropdown')
        .removeClass('hidden');
      $('#item-display-feature-flip-content')
        .removeClass('hidden');
    }

    if (account.isSuperAdmin()) {
      $('#po-dropdown')
        .removeClass('hidden');
      $('#item-display-feature-flip-content')
        .removeClass('hidden');
      $('#item-display-edit-feature-flip-content')
        .removeClass('hidden');
    }

    if (account.isDev()) {
      $('#button-development-api-doc').removeClass('hidden');
      $('#admin-dropdown').removeClass('hidden');
      if (account.isSuperAdmin()) {
        $('#item-display-list-users').removeClass('hidden');
      }

      if (config && config.get('featureFlipping') && config.get('featureFlipping').duplicateContent === 'true') {
        $('#button-display-contents-duplicate').removeClass('hidden');
      }
      if (config && window.location.host !== config.get('cmsHost') && config.get('featureFlipping')) {
        if (config.get('featureFlipping').importContent === 'true') {
          $('#button-display-contents-import').removeClass('hidden');
        }
      }
    }
    if (account.get('name')) {
      $('#button-empty-welcome').addClass('hidden');
      $('#button-welcome').removeClass('hidden');
    }
  },

  listenToKC: function listenToKC () {
    const keyIntList = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
    let  i = 0;
    $(document).keydown((e) => {
      if (i <= keyIntList.length) {
        if (e.keyCode === keyIntList[i++]) {
          if (i === keyIntList.length) {
            $(document.body).css('font-family', '\'Press Start 2P\', cursive');
            i++;
          }
        } else {
          i = 0;
        }
      }
    });
  },

  displayCmsDoc: function displayCmsDoc () {
    config.redirectTo('/documentation');
  },

  displayMigrateContent: function displayMigrateContent () {
    appRouter.navigate('migrateContent', { trigger: true });
  },

  displayMigrateContentV2: function displayMigrateContentV2 () {
    appRouter.navigate('migrateContentV2', { trigger: true });
  },

  displayMigrateContentDcx: function displayMigrateContentDcx () {
    appRouter.navigate('migrateContentDcx', { trigger: true });
  },

  displayUserAdmin: function displayUserAdmin () {
    appRouter.navigate('users', { trigger: true });
  },

  copyTokenToClipboard: function copyTokenToClipboard () {
    navigator.clipboard.writeText('Bearer ' + account.get('access_token')).then(() => {
      notificationEvent.notify('Access token copied to clipboard', 'success');
    }, () => {
      notificationEvent.notify('Access token couldn\'t be copied to clipboard', 'danger');
    });
  },

  userLogout: function userLogout () {
    let  _url,
      authorizationConfig = config.get('authorization'),
      serverOAuth2Port = authorizationConfig.oauth2Port;

    localStorage.clear();
    sessionStorage.clear();

    _url = (serverOAuth2Port == 443 ? 'https://' : 'http://')
      + authorizationConfig.oauth2Hostname
      + (serverOAuth2Port != 443 ? ':' + serverOAuth2Port : '')
      + '/signout'
      + '?post_logout_redirect_uri=' + config.getHomeUrl()
      + '&id_token_hint=' + account.get('access_token');
    account.destroySession();

    config.redirectTo(_url);
  },

  renderOnAccountChange: function renderOnAccountChange () {
    account.on('change', this.render, this);
    
  },

  render: function render () {
    this.renderHtml();
    this.renderButtons();
    this.listenToKC();
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function showMe () {
    if (!singleton) {
      singleton = new View();
    }
    const returnValue = singleton.render();
    singleton.renderOnAccountChange();
    return returnValue;
  },
});

module.exports = View;
