'use strict';

let View,
  singleton;
const  template = require('templates/selectB2cCommonDraftOrB2cPagesDraftForPreviewModal'),
  Releases = require('models/releases');
const { isReleaseDocumentsIndexName, B2C_PAGES_CONTENT_ID_REGEX } = require('../utils/clubmedPagesContents');

function openPreviewWindow(url) {
  window.open(url, '_blank');
}

function buildUrlAndRedirectToNoCacheInstance(currentrelease, oppositeRelease, _baseUrl) {
  var baseUrl = _baseUrl + '?';
  var new_url = '';


  if( isReleaseDocumentsIndexName(currentrelease.contentId)) {
    new_url = baseUrl + 'locale=' + currentrelease.localeId +
      '&pagesRelease=' + currentrelease.id +
      '&commonRelease=' + oppositeRelease.id;

  }else{
    new_url = baseUrl + 'locale=' + currentrelease.localeId +
      '&commonRelease=' + currentrelease.id +
      '&pagesRelease=' + oppositeRelease.id;
  }



  openPreviewWindow(new_url);
}

function initialize() {
  this.initHandlebarsHelpers();
}

function close() {
  this.$el.remove();
  this.off();
  if (this.release) {
    this.release.off(null, null, this);
  }
}

function initHandlebarsHelpers() {
  Handlebars.registerHelper('ifEq', function(value, otherValue, options) {
    if (value === otherValue) {
      return options.fn(this);
    }
      return options.inverse(this);

  });
}

function initModel() {
  return {};
}

function cancel() {
  this.$el.parent().modal('toggle');
}

function addOnclickListenerToReleases(oppositeRealeases, currentRelease, baseUrl) {


  var _this= this;
  var callback = function(oppositeRelease) {
    buildUrlAndRedirectToNoCacheInstance(
      {
        contentId: currentRelease.contentId,
        localeId: currentRelease.localeId,
        id: currentRelease.id,
      },
      {
        contentId: oppositeRelease.content_id,
        localeId: oppositeRelease.locale,
        id: oppositeRelease.id,
      },
      baseUrl
    );
  };


  oppositeRealeases.forEach((oppositeRelease) => {
    var element = _this.$el.find('#' + oppositeRelease.content_id + '-' + oppositeRelease.locale + '-' + oppositeRelease.id);
    element.on('click', () => { callback(oppositeRelease);});
  });

}

function getHydratedHtmlTemplate(model) {
  return template(model);
}

function setHTMLto$element(html) {
  this.$el.html(html);
}

function insertModalBodyToModalContainer() {
  $('#modal-selectB2cCommonDraftOrB2cPagesDraftForPreview').append(this.$el);
}

function toggleModal() {
  this.$el.parent().modal('toggle');
}

function addBehaviourToCancelButton() {
  this.$el.find('.js-btn-cancel').on('click', $.proxy(this.cancel, this));
}

function addBehaviourToModalElements(oppositeRealeases, currentRelease, baseUrl) {
  addBehaviourToCancelButton.call(this);
  addOnclickListenerToReleases.call(this, oppositeRealeases, currentRelease, baseUrl);
}

function render(currentRelease, oppositeRealeases, baseUrl) {

  var model = {
    oppositeRealeaseType: currentRelease.contentId.match(B2C_PAGES_CONTENT_ID_REGEX) ? 'b2c-common':'b2c-pages',
    currentRelease: currentRelease,
    oppositeRealeases: oppositeRealeases,
  };

  var html = getHydratedHtmlTemplate(model);
  setHTMLto$element.call(this, html);
  insertModalBodyToModalContainer.call(this);
  toggleModal.call(this);
  addBehaviourToModalElements.call(this, oppositeRealeases, currentRelease,baseUrl);
  this.delegateEvents();
  return this;
}

function showMe(currentRelease, specialBehaviourContents, baseUrl) {
  if (!singleton) {
    singleton = new View();
  } else {
    singleton.close();
  }

  var oppositeRealeaseContentId = specialBehaviourContents[currentRelease.contentId];

  var oppositeRealeases = new Releases({
    contentId: oppositeRealeaseContentId,
    localeId: currentRelease.localeId,
    notStatus: 'archived',
    size: 30,
  });




  var promises = [oppositeRealeases.fetch()];
  $.when
    .apply($, promises)
    .done((result) => {
      singleton.render(currentRelease, result, baseUrl);
    })
    .fail((error) => {
      errorHandler({
        error: error,
        fail: function(jsonError) {
          if (jsonError && jsonError.statusCode === 404) {
            notificationEvent.notify('Releases not found', 'danger');
          }
        },
      });
    });

  return singleton;
}

View = Backbone.View.extend(
  {
    initialize: initialize,
    close: close,
    initHandlebarsHelpers: initHandlebarsHelpers,
    initModel: initModel,
    cancel: cancel,
    render: render,
  },
  {
    showMe: showMe,
  }
);

module.exports = View;
