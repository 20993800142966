let View, singleton;
const template = require('templates/imageCropper'),
  Spinner = require('Spinner'),
  UploadImage = require('models/image');

View = Backbone.View.extend(
  {
    events: {
      'click .js-saveImage': 'save',
    },

    initialize: function() {
      this.uploadImage = new UploadImage();
    },

    close: function close() {
      this.$el.remove();
      this.off();
      $('#modal-imageCropperPreview').modal('hide');
    },

    save: function save() {
      // Convert the cropped canvas to a Blob instead of a Data URL
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        Spinner.show();
        const button = this.$('.js-saveImage');
        button.prop('disabled', true);
    
        // Create a new FormData instance
        const formData = new FormData();
        const filename = `${Date.now()}-cropped.jpg`;
    
        // Append the Blob file
        formData.append('image', blob, filename);
    
        // Append the content type
        formData.append('type', blob.type);
    
        // Configure the request options
        const options = {
          processData: false, // tell Backbone to skip processing data
          contentType: false, // tell Backbone to skip setting contentType
          data: formData, // provide FormData
          success: (_model, response) => {
            this.element.val(response.url);
            Spinner.hide();
            button.prop('disabled', false);
            this.close();
          },
          error: function(_model, response) {
            console.error('Image upload failed', response);
            Spinner.hide();
            button.prop('disabled', false);
          },
        };
    
        // Save the model
        this.uploadImage.save(null, options);
      });
    },    

    render: function(imageSrc) {
      Spinner.hide();
      const html = template({
        imageSrc,
      });
      this.$el.html(html);

      const image = this.$('.cms-imageCropper img')[0];
      const options = {
        preview: '.img-preview',
        aspectRatio: NaN,
        initialAspectRatio: 16 / 9,
        viewMode: 2,
      };

      this.setupCropper(image, options);
      this.loadImage(image, imageSrc);
      this.setupCropperControls(options);

      $('#modal-imageCropperPreview').append(this.$el);
      this.$el.parent().modal('toggle');
      this.delegateEvents();

      return this;
    },

    setupCropper: function(image, options) {
      const cropper = new Cropper(image, options);
      this.cropper = cropper;
    },

    loadImage: function(image, imageSrc) {
      // Wait for the image to load
      image.onload = function() {
        // Set the aspect ratio
        const aspectRatio = image.width / image.height;
        this.cropper.setAspectRatio(aspectRatio);
      }.bind(this);

      // Handle image load errors
      image.onerror = function() {
        console.error('Failed to load image');
      };

      // Set the image source
      image.src = imageSrc;
    },

    setupCropperControls: function(options) {
      const actions = this.$('#actions');
      const uploadedImageType = 'image/jpeg';

      // Options
      actions.find('.docs-toggles').change((event) => {
        let target = $(event.target);

        if (!this.cropper) {
          return;
        }

        if (target.prop('tagName').toLowerCase() === 'label') {
          target = target.find('input');
        }

        if (target.attr('type') === 'radio') {
          this.cropper[target.attr('name')](target.val());
        }
      });

      actions.find('.docs-buttons').click(function(event) {
        let target = $(event.target);
        let cropped;
        let result;
        let input;
        let data;

        if (!this.cropper) {
          return;
        }

        while (!target.is(this)) {
          if (target.attr('data-method')) {
            break;
          }
          target = target.parent();
        }

        if (target.is(this) || target.prop('disabled') || target.hasClass('disabled')) {
          return;
        }

        data = {
          method: target.attr('data-method'),
          target: target.attr('data-target'),
          option: target.attr('data-option') || undefined,
          secondOption: target.attr('data-second-option') || undefined,
        };

        ({ cropped } = this.cropper);

        if (data.method) {
          if (typeof data.target !== 'undefined') {
            input = $(data.target);

            if (!target.attr('data-option') && data.target && input.length) {
              try {
                data.option = JSON.parse(input.val());
              } catch (e) {
                console.log(e.message);
              }
            }
          }

          switch (data.method) {
            case 'rotate':
              if (cropped && options.viewMode > 0) {
                this.cropper.clear();
              }
              break;

            case 'getCroppedCanvas':
              try {
                data.option = JSON.parse(data.option);
              } catch (e) {
                console.log(e.message);
              }

              if (uploadedImageType === 'image/jpeg') {
                if (!data.option) {
                  data.option = {};
                }

                data.option.fillColor = '#fff';
              }
              break;
          }

          result = this.cropper[data.method](data.option, data.secondOption);

          switch (data.method) {
            case 'rotate':
              if (cropped && options.viewMode > 0) {
                this.cropper.crop();
              }
              break;

            case 'scaleX':
            case 'scaleY':
              target.attr('data-option', -data.option);
              break;

            case 'destroy':
              this.cropper = null;
              break;
          }

          if (typeof result === 'object' && result !== this.cropper && input) {
            try {
              input.val(JSON.stringify(result));
            } catch (e) {
              console.log(e.message);
            }
          }
        }
      });

      $(document.body).keydown(function(event) {
        var e = event || window.event;

        if (e.target !== this || !this.cropper || $(this).scrollTop() > 300) {
          return;
        }

        switch (e.which) {
          case 37:
            e.preventDefault();
            this.cropper.move(1, 0);
            break;

          case 38:
            e.preventDefault();
            this.cropper.move(0, 1);
            break;

          case 39:
            e.preventDefault();
            this.cropper.move(-1, 0);
            break;

          case 40:
            e.preventDefault();
            this.cropper.move(0, -1);
            break;
        }
      });
    },
  },
  {
    showMe: function({ imageSrc, element }) {
      Spinner.show();
      if (!singleton) {
        singleton = new View();
      } else {
        singleton.close();
      }
      singleton.element = element;
      singleton.render(imageSrc);
    },
  },
);

module.exports = View;
