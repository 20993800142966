'use strict';

const Router = require('router'),
  account = require('models/account'),
  config = require('models/config'),
  HeaderView = require('views/headerView'),
  NotificationView = require('views/notificationView');

const App = {
  onConfigReady: function onConfigReady () {
    Backbone.$.ajaxSetup({
      beforeSend: function (xhr, options) {
        if (account.get('access_token')) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + account.get('access_token'));
        }
        const querySeparator = options.url.indexOf('?') === -1 ? '?' : '&';
        if (options.url && options.url.indexOf('client_id') === -1) {
          options.url = options.url + querySeparator + 'client_id=' + config.get('authorization').client_id;
        }
        options.crossDomain = true;
      },
    });

    Router.init();
    HeaderView.showMe();
    NotificationView.showMe();
  },

  init: function init () {
    const self = this;

    config.fetch().always(() => {
      self.onConfigReady();
    });

  },
};
module.exports = App;
