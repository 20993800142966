'use strict';

const MigrateContentDcx = Backbone.Model.extend({

  defaults: {
    contentId: 'b2c-pages',
    localeId: '',
    tag: '',
    migrationObject: {},
    type: 'landing',
  },

  collection: {
    url: '/v3/migrateB2cToDcx',
  },

  url() {
    const base = `/v3/migrateB2cToDcx/${this.get('localeId')}`;
    const typeParam = `type=${encodeURIComponent(this.get('type'))}`;
    const tagParam = this.get('tag') ? `tag=${encodeURIComponent(this.get('tag'))}`: '';
    return `${base}?${typeParam}&${tagParam}`;
  },

  sync(method, model, options) {
    // override method to 'create' for making POST requests
    method = 'create';

    // ensure that the payload is empty
    options.data = JSON.stringify({});

    // set content type
    options.contentType = 'application/json';

    return Backbone.sync(method, model, options);
  },
});


module.exports = MigrateContentDcx;
