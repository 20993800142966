'use strict';

const Content = require('models/content');

const Contents = Backbone.Collection.extend({
    url: '/v1/contents',
    model: Content,
});

module.exports = Contents;
