'use strict';

const Utils = require('./utils');

const TreeItemTools = {

  flatten: function flatten (data) {
    const result = {};

    function recurse (cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) {
          recurse(cur[i], prop ? prop + '.' + i : i);
        }
        if (l == 0) {
          result[prop] = [];
        }
      } else {
        let isEmpty = true;
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }

    recurse(data, '');
    return result;
  },

  unflatten: function unflatten (data) {
    if (Object(data) !== data || Array.isArray(data)) {
      return data;
    }
    const regex = /\.?(\d+)|\.?([^.\[\]]+)/g,
      resultholder = {};
    for (const p in data) {
      let cur = resultholder,
        prop = '',
        m;
      while (m = regex.exec(p)) {
        cur = cur[prop] || (cur[prop] = (m[1] ? [] : {}));
        prop = m[1] || m[2];
      }
      cur[prop] = data[p];
    }
    return resultholder[''] || resultholder;
  },

  filterInt: function filterInt (value) {
    if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) {
      return Number(value);
    }
    return value;
  },

  getType: function getType (type, value) {
    if (type == 'integer') {
      return this.filterInt(value);
    } else if (type == 'number') {
      return parseFloat(value);
    } else if (type == 'boolean') {
      return Boolean(value);
    } 
      return value;
    
  },

  coerceType: function coerceType (release, path, value, treeNode) {
    const self = this;
    if (!release || !release.get('schema')) {
      return value;
    }

    const schemaElement = TreeItemTools.getSchema(treeNode, path, release);
    let type;
    if (!schemaElement) {
      return value;
    }
    if (Array.isArray(schemaElement.type)) {
      if (value.length == 0 && schemaElement.type.indexOf('null')) {
        return null;
      }
      schemaElement.type.forEach((currentType) => {
        if (currentType != 'null') {
          type = self.getType(currentType, value);
        }
      });
      if (!type) {
        return value;
      }
      return type;
    } 
      return self.getType(schemaElement.type, value);
    
  },

  iterate: function iterate (obj, stack, callback) {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        let newStack;
        if (_.isArray(obj)) {
          newStack = stack + '.' + property;
        } else {
          newStack = stack ? stack + '.' + property : property;
        }

        if (typeof obj[property] == 'object') {
          if (_.isArray(obj[property])) {
            callback(property, obj[property], newStack);
          }
          this.iterate(obj[property], newStack, callback);
        } else {
          callback(property, obj[property], newStack);
        }
      }
    }
  },

  isParent: function isParent (path, otherPath) {
    if (!Utils.isNotEmptyString(path) || !Utils.isNotEmptyString(otherPath)) {
      return false;
    }
    while (path) {
      if (path === otherPath) {
        return true;
      }
      path = Utils.getParentPath(path);
    }
    return false;
  },

  getModelJsonPath: function getModelJsonPath (model) {
    if (model.get('parentIsArray')) {
      const jsonPathParent = TreeItemTools.getJsonPath(model.parent());
      const parentPath = jsonPathParent + '.';
      const sourceIndex = Utils.getLastPathPart(model.get('ref'), '.');
      return parentPath + sourceIndex;
    } 
      return TreeItemTools.getJsonPath(model);
    
  },

  getJsonPath: function getJsonPath (model) {
    let path = '';
    do {
      if (model.get('parentIsArray')) {
        const index = this._getindexOfNodeInArray(model);
        path = this.getConcatBeginPath(index, path);
      } else {
        path = this.getConcatBeginPath(model.get('title'), path);
      }
    } while (model = model.parent());
    return path;
  },

  getConcatBeginPath: function getConcatBeginPath (elementToAdd, path) {
    if (path && path.length > 0) {
      return elementToAdd + '.' + path;
    } 
      return elementToAdd;
    
  },

  getConcatEndPath: function getConcatEndPath (path, elementToAdd) {
    if (path && path.length > 0) {
      return path + '.' + elementToAdd;
    } 
      return elementToAdd;
    
  },

  closeNode: function closeNode ($element) {
    const $elementLiParent = $element.parents('li:eq(0)');
    const $associatedATags = $elementLiParent.find('a').not('.json-edit-node').not('.json-edit-node-tag').not('.json-remove-node').not('.json-disable-node').not('.json-program-disabled');
    if ($elementLiParent && $elementLiParent.hasClass('open') && $associatedATags && $associatedATags.length) {
      $elementLiParent.removeClass('open');
      $associatedATags.first().trigger('click');
    }
  },

  openNode: function openNode ($element) {
    const $elementLiParent = $element.parents('li:eq(0)');
    const $associatedATags = $elementLiParent.find('a').not('.json-edit-node').not('.json-edit-node-tag').not('.json-remove-node').not('.json-disable-node').not('.json-program-disabled');
    if ($elementLiParent && !$elementLiParent.hasClass('open') && $associatedATags && $associatedATags.length) {
      $elementLiParent.addClass('open');
      $associatedATags.first().trigger('click');
    }
  },

  toggleNodeInteractions: function toggleNodeInteractions (inputObject) {
    const $elementLiParent = inputObject.$element.parents('li:eq(0)');
    const $associatedATags = $elementLiParent.find('a').not('.json-edit-node').not('.json-edit-node-tag').not('.json-remove-node').not('.json-disable-node').not('.json-program-disabled');
    const $associatedButtonTags = $elementLiParent.find('button');
    const $associatedSelectTags = $elementLiParent.find('select');
    const $liParentDivChild = $elementLiParent.children('div');
    if (inputObject.hasOwnProperty('forceDisable')) {
      if (inputObject.forceDisable) {
        $associatedATags.addClass('deleted-text');
        $associatedATags.removeClass('toggle');
        this.toggleDisabledAttribute({ $element: $associatedButtonTags, forceDisable: inputObject.forceDisable });
        this.toggleDisabledAttribute({ $element: $associatedSelectTags, forceDisable: inputObject.forceDisable });
        $liParentDivChild.removeClass('no-dnd');
      } else {
        $associatedATags.removeClass('deleted-text');
        $associatedATags.addClass('toggle');
        this.toggleDisabledAttribute({ $element: $associatedButtonTags, forceDisable: inputObject.forceDisable });
        this.toggleDisabledAttribute({ $element: $associatedSelectTags, forceDisable: inputObject.forceDisable });
        $liParentDivChild.addClass('no-dnd');
      }
    } else {
      $associatedATags.toggleClass('deleted-text');
      $associatedATags.toggleClass('toggle');
      this.toggleDisabledAttribute({ $element: $associatedButtonTags });
      this.toggleDisabledAttribute({ $element: $associatedSelectTags });
      $liParentDivChild.toggleClass('no-dnd');
    }
  },

  toggleDisabledAttribute: function toggleDisabledAttribute (inputObject) {
    if (inputObject.hasOwnProperty('forceDisable')) {
      if (inputObject.forceDisable) {
        inputObject.$element.prop('disabled', true);
      } else {
        inputObject.$element.prop('disabled', false);
      }
    } else {
      inputObject.$element.prop('disabled', (i, v) => {
        return !v;
      });
    }
  },

  toggleArrayNodes: function toggleArrayNodes ($element, newEnabledState) {
    if ($element) {
      const $elementLiParent = $element.parents('li:eq(0)');
      const $jqueryChildenCheckboxes = $elementLiParent.find(':checkbox');
      if ($jqueryChildenCheckboxes.length > 1) {
        const self = this;
        $jqueryChildenCheckboxes.each(function (index, value) {
          if (index != 0) {
            const $currentElement = $(this);
            const currentEnabledState = $currentElement.prop('checked');
            if (currentEnabledState != newEnabledState) {
              const disableCheckboxTitle = (newEnabledState) ? 'Disable' : 'Enable';
              $currentElement.prop('checked', newEnabledState);
              $currentElement.prop('title', disableCheckboxTitle);
              self.toggleNodeInteractions({ $element: $currentElement });
            }
          }
        });
      }
    }
  },

  _getindexOfNodeInArray: function (model) {
    const title = model.get('title');
    for (const i in model.collection.models) {
      const currentNode = model.collection.models[i];
      if (currentNode.get('title') === title) {
        return i;
      }
    }
  },

  traverseTreeNodes: function traverseTreeNodes (collection, callback) {
    _.each(collection.models, function (element, index) {
      const returns = callback(element, index);
      if (returns == false) {
        return false;
      }

      const subCollection = element._nodes;
      if (subCollection) {
        this.traverseTreeNodes(subCollection, callback);
      }
    }, this);
  },

  getNodeTitle: function getNodeTitle (currentModel, release) {
    if (!currentModel) {
      return '';
    }
    const value = currentModel.get('value');
    const jsonPath = this.getModelJsonPath(currentModel);
    const schema = this.getSchema(currentModel, jsonPath, release);

    const objectLabel = this.getObjectLabel(value, schema);
    if (objectLabel) {
      return objectLabel;
    }
    const arrayLabel = this.getArrayLabel(value, schema);
    if (arrayLabel) {
      return arrayLabel;
    }
    return this.getDefaultTitle(currentModel, value);
  },

  getNodeTitleFromJsonPath: function getNodeTitleFromJsonPath (jsonPath, release) {
    if (!jsonPath || !release) {
      return '';
    }
    const value = release.getValue(jsonPath);
    const schema = release.getSchema(jsonPath);

    const objectLabel = this.getObjectLabel(value, schema);
    if (objectLabel) {
      return objectLabel;
    }
    const arrayLabel = this.getArrayLabel(value, schema);
    if (arrayLabel) {
      return arrayLabel;
    }
    const nodeTitle = (objectLabel) ? objectLabel : arrayLabel;
    return (nodeTitle) ? nodeTitle : this.getDefaultTitleFromJsonPath(jsonPath, value);
  },

  getObjectLabel: function getObjectLabel (value, schema) {
    return (value && schema && schema.objectLabel) ? _.get(value, schema.objectLabel) : '';
  },

  getArrayLabel: function getArrayLabel (value, schema) {
    return (value && schema && schema.arrayLabel) ? _.get(value, schema.arrayLabel) : '';
  },

  getDefaultTitleFromJsonPath: function getDefaultTitleFromJsonPath (jsonPath, value) {
    let title = (jsonPath) ? _.clone(jsonPath) : '';

    const metadataType = this.getMetadataType(value);
    if (metadataType) {
      title = title + '.' + metadataType.replace('#/definitions/', '');
    }
    return title;
  },

  getDefaultTitle: function getDefaultTitle (currentModel, value) {
    let title = (currentModel) ? _.clone(currentModel.getTitle()) : '';

    if (this.getMetadataType(value)) {
      title = title + '.' + value['@metadata'].type.replace('#/definitions/', '');
    }
    while (currentModel && currentModel.parent() && currentModel.get('parentIsArray')) {
      title = currentModel.parent().getTitle() + '.' + title;
      currentModel = currentModel.parent();
    }
    return title;
  },

  _getSchemaFromOrigin: function _getSchemaFromOrigin (node, path, oneOfType, release) {
    const originOneOfNode = this._getOriginOneOfNode(node, oneOfType);
    const jsonPathOfOrigin = this.getModelJsonPath(originOneOfNode);
    const jsonPathFromOrigin = (jsonPathOfOrigin.length < path.length) ? path.replace(jsonPathOfOrigin + '.', '') : '';
    return (release) ? release.getSchemaDefinitionFor(jsonPathFromOrigin, oneOfType) : null;
  },

  _getOriginOneOfNode: function _getOriginOneOfNode (node, oneOfType) {
    while (node.parent() && node.parent().get('oneOfType') == oneOfType) {
      node = node.parent();
    }
    return node;
  },

  getMetadataType: function getMetadataType (node) {
    return node && node['@metadata'] && node['@metadata'].type;
  },

  getSchema: function getSchema (node, path, release) {
    const oneOfType = node.get('oneOfType');
    if (oneOfType) {
      return this._getSchemaFromOrigin(node, path, oneOfType, release);
    } 
      return (release) ? release.getSchemaDefinitionFor(path) : null;
    
  },
};

module.exports = TreeItemTools;
