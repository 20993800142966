'use strict';


let View,
    singleton;
const    notificationEvent = require('NotificationEvent'),
    template = require('templates/notification');

View = Backbone.View.extend({

    el: '#notification',

    events: {
        'click .cms-button-close': 'closeNotification',
    },

    displayAlertNotification: function(notification) {
        if (!notification || !notification.message) {
            return;
        }
        this.$el.removeAttr('class').addClass('cms-notification cms-notification--' + notification.level);
        this.$el.find('#notification-msg').html(notification.message);
        this.displayPopup(notification.persistant);
    },

    displayPopup: function(persistant) {
        this.$el.show();
        this.$el.removeClass('cms-slideUp').addClass('cms-slideDown');
        if (!persistant) {
            setTimeout($.proxy(this.closeNotification, this), 3000);
        }
    },

    closeNotification: function() {
        this.$el.addClass('cms-slideUp');
        const self = this;
        setTimeout(() => {
            self.$el.hide().removeClass('cms-slideDown cms-slideUp');
        }, 500);
    },

    render: function() {
        const html = template();
        this.$el.html(html);

        notificationEvent.on('notification', this.displayAlertNotification, this);
        this.delegateEvents();
        return this;
    },
}, {
    showMe: function() {
        if (!singleton) {
            singleton = new View();
        }
        return singleton.render();
    },
});

module.exports = View;
