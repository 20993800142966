'use strict';

const Utils = require('./utils');
const config = require('models/config');
const { CLUBMED_PAGES_CONTENT_ID_REGEX } = require('./clubmedPagesContents');

const UrlUtils = {

  ANCHOR: '#',

  CLUB_MED_STRING: 'clubmed.',

  CLUB_MED_URL_REGEX: /(http|https):\/\/(www\.)?(clubmed\.).+/i,

  DASH: '-',

  DASH_SLASH: '-/',

  DASH_SLASH_REGEX: /(-\/)+/g,

  DIACRITICS_REGEX: /[\u0300-\u036f]/g,

  DOUBLE_DASH: '--',

  DOUBLE_DASH_REGEX: /(.-)-+/g,

  DOUBLE_SLASH: '//',

  DOUBLE_SLASH_REGEX: /([^:]\/)\/+/g,

  DOUBLE_SPACE: '  ',

  DOUBLE_SPACE_REGEX: /( ) +/g,

  ENCODED_CHARS_REGEX: /(%[0-9A-Fa-f]{2})+/gi,

  INTERROGATION_MARK: '?',

  FORBIDDEN_SPECIAL_CHARS_COMMON: [
    ' ',
    '@',
    '_',
    '~',
    '$',
    '€',
    '£',
    '\'',
    '"',
    '`',
    '^',
    '¨',
    '’',
    '{',
    '}',
    '(',
    ')',
    '[',
    ']',
    '|',
    '¤',
    'µ',
    '§',
    '*',
    '+',
    ',',
    ';',
    '!',
    '©',
    '®',
    '\\',
    '>',
    '<',
    '²',
    '°',
    '%',
    '?',
    '=',
    '&',
    '#',
    '　',
    '（',
    '）',
  ],

  FORBIDDEN_SPECIAL_CHARS_FOR_ANCHORS: [
    ':',
    '/',
    '.',
  ],

  HTTP_STRING: 'http://',

  HTTPS_NET_STORAGE_URL: 'https://ns.clubmed.',

  HTTPS_STRING: 'https://',

  MARKDOWN_URL_REGEX: /\[([^\]]*)\]([ ]*)\(([^\)]*)\)/gi,

  MEDIA_SERVER_STRING: 'media-server',

  NET_STORAGE_STRING: 'ns.clubmed.',

  PERCENTAGE: '%',

  PERCENTAGE_SPACE: '% ',

  PERCENTAGE_SPACE_REGEX: /(% )+/g,

  SLASH: '/',

  SLASH_COLON: '/:',

  SLASH_DASH: '/-',

  SLASH_DASH_REGEX: /(\/-)+/g,

  autoformatB2cAnchor: function autoformatB2cAnchor(contentId, input, isUrl) {
    let output = _.clone(input);
    if ((config.get('featureFlipping') && config.get('featureFlipping').autoformatRuleB2cAnchor === 'true') && (contentId.indexOf('b2c-common') !== -1 || contentId.match(CLUBMED_PAGES_CONTENT_ID_REGEX))) { // Apply auto format rules for Club Med URL anchors for SEO
      let completeUrl;
      let originalAnchor;
      if (isUrl) {
        if (this.isClubMedUrl(output) && output.indexOf(this.ANCHOR) >= 0) {
          completeUrl = _.clone(output);
          originalAnchor = _.clone(completeUrl.substring(completeUrl.indexOf(this.ANCHOR) + 1));
          output = _.clone(originalAnchor);
        } else {
          return output;
        }
      }
      output = output.trim();
      output = this.removeMultipleSpaces(output); // Remove multiple spaces
      output = this.convertPercentageSpaceToPercentage(output); // Convert percentage space to percentage
      output = this.decode(output); // Decode URL
      output = output.toLowerCase(); // Lowercase
      output = this.removeDiacritics(output); // Remove diacritics
      output = this.convertSpecialCharsToDashes(output, this.FORBIDDEN_SPECIAL_CHARS_COMMON.concat(this.FORBIDDEN_SPECIAL_CHARS_FOR_ANCHORS)); // Convert special chars to dashes
      output = this.removeMultipleDashes(output); // Remove multiple dashes
      output = this.removeStartDash(output); // Remove start dash
      output = this.removeFinalDash(output); // Remove final dash
      output = output.trim();
      output = (completeUrl && originalAnchor) ? completeUrl.replace(originalAnchor, output) : output;
    }
    return output;
  },

  autoformatUrl: function autoformatUrl(input) {
    let output = _.clone(input);
    output = output.trim();
    if (this.isCompleteUrl(input)) {
      output = UrlUtils.extractNetStorageUrlFromMediaServerUrl(output);
      output = UrlUtils.putNetStorageUrlInHttps(output);
    }
    return UrlUtils.removeMultipleSlashes(output);
  },

  autoformatB2cUrl: function autoformatB2cUrl(contentId, input, isCompleteUrl) {
    let output = _.clone(input);
    if ((config.get('featureFlipping') && config.get('featureFlipping').autoformatRuleB2cUrl === 'true') && (contentId.indexOf('b2c-common') !== -1 || contentId.match(CLUBMED_PAGES_CONTENT_ID_REGEX)) && this.isClubMedUrl(output)) { // Apply auto format rules for Club Med URLs for SEO
      output = output.trim();
      let unmodifiedUrlPart;
      if (output.indexOf(this.SLASH_COLON) >= 0) { // Do not apply modifications to variables and what follows (for /c/cm2c/:departdureDate/:arrivalDate)
        const completeUrl = _.clone(output);
        output = completeUrl.substring(0, completeUrl.indexOf(this.SLASH_COLON));
        unmodifiedUrlPart = completeUrl.substring(completeUrl.indexOf(this.SLASH_COLON));
      } else if (output.indexOf(this.INTERROGATION_MARK) >= 0) { // Do not apply modifications to queryParams
        const completeUrl = _.clone(output);
        output = completeUrl.substring(0, completeUrl.indexOf(this.INTERROGATION_MARK));
        unmodifiedUrlPart = completeUrl.substring(completeUrl.indexOf(this.INTERROGATION_MARK));
      } else if (output.indexOf(this.ANCHOR) >= 0) { // Do not apply modifications to anchors and what follows
        const completeUrl = _.clone(output);
        output = completeUrl.substring(0, completeUrl.indexOf(this.ANCHOR));
        unmodifiedUrlPart = completeUrl.substring(completeUrl.indexOf(this.ANCHOR));
      }
      output = output.trim();
      output = this.removeMultipleSpaces(output); // Remove multiple spaces
      output = this.convertPercentageSpaceToPercentage(output); // Convert percentage space to percentage
      output = this.decode(output); // Decode URL
      output = output.toLowerCase(); // Lowercase
      output = this.removeDiacritics(output); // Remove diacritics
      output = this.convertSpecialCharsToDashes(output, this.FORBIDDEN_SPECIAL_CHARS_COMMON); // Convert special chars to dashes
      output = this.removeMultipleDashes(output); // Remove multiple dashes
      output = this.convertDashSlashToSlash(output); // Convert dash slash combo to slash
      output = this.convertSlashDashToSlash(output); // Convert slash dash combo to slash
      if (isCompleteUrl) {
        if (!unmodifiedUrlPart || unmodifiedUrlPart.indexOf(this.INTERROGATION_MARK) === -1) {
          output = this.removeFinalSlash(output); // Remove final slash
        }
        output = this.removeFinalDash(output); // Remove final dash
      }
      output = output.trim();
      output = (unmodifiedUrlPart) ? output + unmodifiedUrlPart : output;
    }
    return output;
  },

  isNonEmptyString: function isNonEmptyString(input) {
    return (input && input.trim() && _.isString(input.trim()));
  },

  isRelativeUrl: function isRelativeUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : input.trim().toLowerCase().startsWith(this.SLASH);
  },

  isHttpUrl: function isHttpUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : input.trim().toLowerCase().startsWith(this.HTTP_STRING);
  },

  isHttpsUrl: function isHttpsUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : input.trim().toLowerCase().startsWith(this.HTTPS_STRING);
  },

  isCompleteUrl: function isCompleteUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : (this.isHttpUrl(input) || this.isHttpsUrl(input));
  },

  isUrl: function isUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : (this.isHttpUrl(input) || this.isHttpsUrl(input) || this.isRelativeUrl(input));
  },

  isClubMedUrl: function isClubMedUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : (this.isRelativeUrl(input) || this.CLUB_MED_URL_REGEX.test(input.trim().toLowerCase()));
  },

  isMediaServerUrl: function isMediaServerUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : input.trim().toLowerCase().indexOf(this.MEDIA_SERVER_STRING) !== -1;
  },

  parseMediaServerUrl: function parseMediaServerUrl(input) {
    const output = input.trim();
    const splitMediaServer = output.split(this.SLASH);
    let newUrl = splitMediaServer[splitMediaServer.length - 1];
    if (output.toLowerCase().indexOf('v2') !== -1) {
      for (let i = 0; i < splitMediaServer.length; i++) {
        if (splitMediaServer[i] && splitMediaServer[i].toLowerCase() === 'image') {
          newUrl = splitMediaServer[i + 1];
          break;
        }
      }
    }
    return decodeURIComponent(newUrl).trim();
  },

  extractNetStorageUrlFromMediaServerUrl: function extractNetStorageUrlFromMediaServerUrl(input) {
    let output = input.trim();
    while (UrlUtils.isMediaServerUrl(output)) {
      output = UrlUtils.parseMediaServerUrl(output);
    }
    return output;
  },

  isNetStorageUrl: function isNetStorageUrl(input) {
    return (!this.isNonEmptyString(input)) ? false : input.trim().toLowerCase().indexOf(this.NET_STORAGE_STRING) !== -1;
  },

  putNetStorageUrlInHttps: function putNetStorageUrlInHttps(input) {
    const output = input.trim();
    if (this.isHttpUrl(output) && this.isNetStorageUrl(output)) {
      const domainAndImagePath = output.substring(this.HTTPS_NET_STORAGE_URL.length - 1);
      const splittedDomainAndImagePath = (domainAndImagePath) ? domainAndImagePath.split(this.SLASH) : [];
      if (splittedDomainAndImagePath.length) {
        splittedDomainAndImagePath[0] = splittedDomainAndImagePath[0].toLowerCase();
      }
      return this.HTTPS_NET_STORAGE_URL + splittedDomainAndImagePath.join(this.SLASH);
    }
    return output;
  },

  convertSpecialCharsToDashes: function convertSpecialCharsToDashes(input, arrayOfForbiddenChars) {
    let output = input.trim();
    for (let i = 0; i < arrayOfForbiddenChars.length; i++) {
      const specialChar = arrayOfForbiddenChars[i];
      output = Utils.replaceAll(output, specialChar, this.DASH);
    }
    return output;
  },

  removeMultipleSlashes: function removeMultipleSlashes(input) {
    let output = input.trim();
    while (output.length && output.startsWith(this.DOUBLE_SLASH)) {
      output = output.substring(1);
    }
    return (output.indexOf(this.DOUBLE_SLASH) !== -1) ? output.replace(this.DOUBLE_SLASH_REGEX, '$1') : output;
  },

  removeMultipleSpaces: function removeMultipleSpaces(input) {
    let output = input.trim();
    while (output.length && output.startsWith(this.DOUBLE_SPACE)) {
      output = output.substring(1);
    }
    return (output.indexOf(this.DOUBLE_SPACE) !== -1) ? output.replace(this.DOUBLE_SPACE_REGEX, '$1') : output;
  },

  removeMultipleDashes: function removeMultipleDashes(input) {
    let output = input.trim();
    while (output.length && output.startsWith(this.DOUBLE_DASH)) {
      output = output.substring(1);
    }
    return (output.indexOf(this.DOUBLE_DASH) !== -1) ? output.replace(this.DOUBLE_DASH_REGEX, '$1') : output;
  },

  convertDashSlashToSlash: function convertDashSlashToSlash(input) {
    let output = input.trim();
    while (output.length && output.startsWith(this.DASH_SLASH)) {
      output = output.substring(1);
    }
    return (output.indexOf(this.DASH_SLASH) !== -1) ? output.replace(this.DASH_SLASH_REGEX, this.SLASH) : output;
  },

  convertPercentageSpaceToPercentage: function convertPercentageSpaceToPercentage(input) {
    const output = input.trim();
    return (output.indexOf(this.PERCENTAGE_SPACE) !== -1) ? output.replace(this.PERCENTAGE_SPACE_REGEX, this.PERCENTAGE) : output;
  },

  convertSlashDashToSlash: function convertSlashDashToSlash(input) {
    const output = input.trim();
    return (output.indexOf(this.SLASH_DASH) !== -1) ? output.replace(this.SLASH_DASH_REGEX, this.SLASH) : output;
  },

  removeFinalSlash: function removeFinalSlash(input) {
    const output = input.trim();
    return (output.length && output.endsWith(this.SLASH) && output !== this.SLASH) ? output.substring(0, output.length - 1) : output;
  },

  removeStartDash: function removeStartDash(input) {
    const output = input.trim();
    return (output.length && output.startsWith(this.DASH)) ? output.substring(1) : output;
  },

  removeFinalDash: function removeFinalDash(input) {
    const output = input.trim();
    return (output.length && output.endsWith(this.DASH)) ? output.substring(0, output.length - 1) : output;
  },

  removeDiacritics: function removeDiacritics(input) {
    return input.trim().normalize('NFD').replace(this.DIACRITICS_REGEX, '');
  },

  decode: function decode(input) {
    let output = input.trim();
    let match = this.ENCODED_CHARS_REGEX.exec(output);
    while (match != null) {
      try {
        output = decodeURIComponent(output).trim();
        match = this.ENCODED_CHARS_REGEX.exec(output);
      } catch (error) {
        match = null;
      }
      // Find next encoded url
    }
    this.ENCODED_CHARS_REGEX.lastIndex = 0;
    return output;
  },

};
module.exports = UrlUtils;
