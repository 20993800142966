const { dcxIcons } = require('./DcxIcons');

const icons = {
  'access-age': 'pmr-shower.svg',
  activities: 'tennis.svg',
  accommodations: 'bed.svg',
  address: 'location.svg',
  adventure: 'tree-climbing.svg',
  allInclusive: 'all-inclusive.svg',
  apres_ski: 'apres_ski.svg',
  app: 'trident.svg',
  'baby-welcome': 'baby-welcome.svg',
  baggage: 'luggage.svg',
  barsAndRestaurants: 'restaurant.svg',
  bathrobe: 'bathrobe.svg',
  beach: 'beach-view.svg',
  beachtowel: 'beach-towel.svg',
  bedRoom: 'bed.svg',
  bednight: 'bed.svg',
  'beginner-sportive': 'Beginner-Sportive.svg',
  bench: 'lounge-area-inside.svg',
  bikeRental: 'vtt.svg',
  boutique: 'shopping.svg',
  callMeBack: 'call-back.svg',
  carpark: 'parking.svg',
  champagne: 'champagne.svg',
  champagneglass: 'champagneGlass.svg',
  charmPrestige: 'charmPrestige.svg',
  check: 'check.svg',
  children: 'kid-supervisory.svg',
  climbing: 'climbing.svg',
  compass: 'Compass.svg',
  contact: 'contactDetails.svg',
  cough: 'cough.svg',
  couple: 'Couple.svg',
  creactive: 'circus.svg',
  creditcard: 'credit-card.svg',
  'culture-discovery': 'photographer.svg',
  dance: 'danse.svg',
  date: 'calendar-default.svg',
  datePlus: 'calendar-plus.svg',
  dateStar: 'calendar-favorite.svg',
  daybed: 'day-bed.svg',
  destination: 'sun-view.svg',
  digital_EC: 'digitalExclusiveCollection.svg',
  dishes: 'Dishes.svg',
  disinfection: 'covid-disinfection.svg',
  distancing: 'covid-distance.svg',
  diving: 'scuba-diving.svg',
  doctor: 'doctor.svg',
  dollar: 'dollar.svg',
  doorhanger: 'door-hanger.svg',
  dresscode: 'Dressing.svg',
  dualcabin: 'dualCabin.svg',
  duration: 'duration.svg',
  earlySun: 'Sun.svg',
  easyArrivalTitle: 'easyArrivalTitle.svg',
  entertainment: 'entertainment.svg',
  exclusiveCollection: 'exclusiveCollection.svg',
  family: 'family.svg',
  fitness: 'fitness.svg',
  flexible: 'flexible.svg',
  football: 'Football.svg',
  garden_view: 'garden-view.svg',
  gloves: 'gloves.svg',
  golf: 'golf.svg',
  'golf-view': 'Hammam.svg',
  guaranteedPrice: 'guaranteedPrice.svg',
  hammam: 'hammam-men.svg',
  handsShake: '404.svg',
  information: 'information.svg',
  insuranceCancellation: 'insuranceCancellation.svg',
  kayak: 'kayak.svg',
  kids: 'kids.svg',
  kitesurf: 'kitesurf.svg',
  'le-45': 'le-45.svg',
  lightbulb: 'lightbulb.svg',
  mask: 'covid-mask.svg',
  meetingsevents: 'clubmedMeetings.svg',
  medicalAssistance: 'health.svg',
  miniClubPlus: 'miniClubPlus.svg',
  'mountain-diner': 'mountainDiner.svg',
  'mountain-view': 'mountain-view.svg',
  musculation: 'musculation.svg',
  nature: 'garden-view.svg',
  onDemand: 'onDemand.svg',
  overflowingpool: 'pool-view.svg',
  pen: 'pen.svg',
  phone: 'phone.svg',
  phoneBubble: 'phoneBubble.svg',
  photoAlbum: 'photoAlbum.svg',
  pmr: 'pmr.svg',
  privateTransfer: 'privateTransfer.svg',
  promoCode: 'promo-code.svg',
  reception: 'reception.svg',
  receptionMask: 'ReceptionMask.svg',
  reducedCapacity: 'Capacity2.svg',
  'repartition-baby': 'repartition-baby.svg',
  resortsAdults: 'resortsAdults.svg',
  restaurantMask: 'RestaurantMask.svg',
  riding: 'horse-riding.svg',
  rooms: 'room.svg',
  'room-service': 'room-service.svg',
  sailing: 'sailing.svg',
  searchGlass: 'search.svg',
  size: 'size.svg',
  ski: 'ski.svg',
  skiEquipments: 'skiEquipments.svg',
  skiRoom: 'ski-room.svg',
  ski_in_out: 'ski-in-out.svg',
  'ski-resort': 'ski-resort.svg',
  snorkelling: 'snorkeling.svg',
  snacks: 'snacks.svg',
  snowboard: 'snowboard.svg',
  socialDistancing: 'covid-distance.svg',
  solution: 'covid-solution.svg',
  spa: 'spa.svg',
  spaMask: 'SpaMask.svg',
  specialOffers: 'special-offers.svg',
  stand_up_paddle: 'paddle.svg',
  stInsurance: 'insurance.svg',
  stSoap: 'soap.svg',
  summary: 'Summary.svg',
  swimmingpool: 'pool-view.svg',
  'table-tennis': 'table-tennis.svg',
  takeOff: 'take-off.svg',
  television: 'tv.svg',
  temperature: 'temperature-check.svg',
  tennis: 'tennis.svg',
  terrace: 'terrace.svg',
  train: 'train.svg',
  transfer: 'privateTransfer.svg',
  transport: 'luggageTransport.svg',
  trapeze: 'trapeze.svg',
  trident1: 'trident1.svg',
  trident2: 'trident2.svg',
  trident3: 'trident3.svg',
  trident4: 'trident4.svg',
  trident5: 'trident5.svg',
  trident6: '404.svg',
  trident45: 'trident45.svg',
  tridentjoyview: 'tridentjoyview.svg',
  'valley-view': 'valley_view.svg',
  vtt: 'vtt.svg',
  walking: 'hiking.svg',
  wash: 'wash-hand.svg',
  waterski: 'water-skiing.svg',
  weight: 'weight.svg',
  'well-being': 'wellBeing.svg',
  wellness: 'Wellness.svg',
  wifi: 'wifi.svg',
  windsurfing: 'wind-surf.svg',
  winterMountainResorts: 'Mountain.svg',
  yoga: 'yoga.svg',
  zenzone: 'zenZone.svg',
  ...dcxIcons,
};

const getIconPath = function getIconPath(iconName) {
  const iconsDirectoryPath = '/assets/icons/';
  return icons[iconName] ? iconsDirectoryPath + icons[iconName] : null;
};

module.exports = {
  getIconPath: getIconPath,
};
