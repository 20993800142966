'use strict';

let View,
  singleton ;
const  UsersCsv = require('models/usersCsv'),
  template = require('templates/usersCsv'),
  account = require('models/account'),
  notificationEvent = require('NotificationEvent'),
  Confirm = require('views/confirmView'),
  errorHandler = require('utils/errorHandler'),
  Spinner = require('Spinner');

View = Backbone.View.extend({

  events: {
    'click .downloadCsv': 'downloadCsv',
  },


  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  downloadCsv: function downloadCsv () {
    return Backbone.$.ajax({
      method: 'POST',
      url: '/users/export/csv',
      crossDomain: true,
      contentType: 'text/plain',
      success: function (response, status, request) {
        const disp = request.getResponseHeader('Content-Disposition');
        if (disp && disp.search('attachment') != -1) {
          const form = $('<form method="POST" action="/users/export/csv">');
          $('body').append(form);
          form.submit();
        }
      },
      error: function () {
      },
    });
  },

  publishCsv: function publishCsv (csv, retry) {
    const self = this;
    Spinner.show();
    csv.save(csv.toJSON(), {
      url: '/users/init',
      success: function (model) {
        Spinner.hide();
        notificationEvent.notify('User reset successful!', 'success');
        self.close();
        self.model = model;
        self.render();
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.publishCsv(csv, false);
            }
          },
          fail: function () {
          },
        });
      },
    });

  },

  render: function render () {
    Spinner.hide();
    if (!account.isSuperAdmin()) {
      const appRouter = require('router');
      appRouter.navigate('welcome', { trigger: true });
      return;
    }

    const usersCsv = this.model.toJSON();
    this.$el.html(template({ usersCsv: usersCsv }));
    $('#main').append(this.$el);

    this.$el.find('.publishCsv').off().click($.proxy(function () {
      try {
        Confirm.showMe('You are about to publish a new CSV file which will replace all users.', $.proxy(function () {
          const value = $('.usersCsv-input-edit').val();
          if (!value) {
            notificationEvent.notify('The new CSV file is empty', 'danger');
            return;
          }
          this.model.set('value', value);
          this.publishCsv(this.model, true);
        }, this), $.proxy(() => {
        }, this));
      } catch (error) {
        notificationEvent.notify(error.message, 'danger');
      }
    }, this));

    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (usersCsv, retry) {
    const self = this;
    usersCsv.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.model = model;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(usersCsv, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe () {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }

    const usersCsv = new UsersCsv({ value: '' });
    this.retrieveData(usersCsv, true);
    return singleton;
  },
});

module.exports = View;
