'use strict';

const Utils = require('./utils');
const { isDcxContentId }= require('./clubmedPagesContents');

const TagsUtils = {

  UNFILTERED_ELEMENTS: {
    dcx: ['sharedComponents', 'layout', 'tracking', 'commonLabels', 'commercialAnimation', 'sharedCommercialComponents', 'sharedData'],
  },

  TAGGED_CONTENT_IDS: [
    'b2c-pages',
    'dcx',
    'b2b-pages',
    'feature-flip',
  ],

  TAGGED_ELEMENTS_ROOT_PATH_MAP: {
    'b2c-pages': 'pages',
    'b2b-pages': 'pages',
    dcx: 'pages',
    'feature-flip': 'keys',
  },

  TAGGED_ELEMENTS_DEFINITION_MAP: {
    'b2c-pages': 'page',
    'b2b-pages': 'page',
    dcx: 'page',
    'feature-flip': 'key',
  },

  TAGGED_ELEMENTS_ID_PROPERTY_MAP: {
    'b2c-pages': 'pageId',
    'b2b-pages': 'pageId',
    dcx: 'pageId',
    'feature-flip': 'key',
  },

  METADATA_PROPERTY: '@metadata',

  JSON_PATH_SEPARATOR: '.',

  TAGS_PROPERTY: 'tags',

  TAGS_LIST_PROPERTY: 'tagsList',

  TAGS_JSON_PATH: '@metadata.tags',

  ALL_TAGS_STRING: 'ALL',

  UNTAGGED_TAGS_STRING: 'UNTAGGED',

  buildJsonPath: function buildJsonPath(array, prefix) {
    if (!array || !array.length) {
      return '';
    }
    if (prefix) {
      array.unshift(prefix);
    }
    return array.join(this.JSON_PATH_SEPARATOR);
  },

  getTaggedContentId: function getTaggedContentId(contentId) {
    if (contentId && this.TAGGED_CONTENT_IDS) {
      for (let i = 0; i < this.TAGGED_CONTENT_IDS.length; i++) {
        const TAGGED_CONTENT_ID = this.TAGGED_CONTENT_IDS[i];
        if (contentId === TAGGED_CONTENT_ID || contentId.indexOf(TAGGED_CONTENT_ID) !== -1) {
          return TAGGED_CONTENT_ID;
        }
      }
    }
    return '';
  },

  contentHasTagsEnabled: function contentHasTagsEnabled(contentId) {
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return false;
    }
    return true;
  },

  jsonPathEqualsTaggedRootPath: function jsonPathEqualsTaggedRootPath(contentId, jsonPath, prefix) {
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return false;
    }
    return jsonPath === this.buildJsonPath([this.TAGGED_ELEMENTS_ROOT_PATH_MAP[taggedContentId]], prefix);
  },

  jsonPathStartsWithTaggedRootPath: function jsonPathStartsWithTaggedRootPath(contentId, jsonPath, prefix) {
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return false;
    }
    return this.jsonPathEqualsTaggedRootPath(taggedContentId, jsonPath, prefix) || jsonPath.startsWith(this.buildJsonPath([this.TAGGED_ELEMENTS_ROOT_PATH_MAP[taggedContentId]], prefix));
  },

  getTaggedElementsRootPath: function getTaggedElementsRootPath(release, isJson, prefix) {
    const contentId = (isJson) ? _.get(release, 'content_id', '') : release.get('content_id');
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return '';
    }
    return this.buildJsonPath([this.TAGGED_ELEMENTS_ROOT_PATH_MAP[taggedContentId]], prefix);
  },

  getTaggedElementsDefinition: function getTaggedElementsDefinition(release, isJson) {
    const contentId = (isJson) ? _.get(release, 'content_id', '') : release.get('content_id');
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return '';
    }
    return this.TAGGED_ELEMENTS_DEFINITION_MAP[taggedContentId];
  },

  getTaggedElementsIdProperty: function getTaggedElementsIdProperty(release, isJson) {
    const contentId = (isJson) ? _.get(release, 'content_id', '') : release.get('content_id');
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return '';
    }
    return this.TAGGED_ELEMENTS_ID_PROPERTY_MAP[taggedContentId];
  },

  getTagsListJsonPath: function getTagsListJsonPath(release, isJson, prefix) {
    const contentId = (isJson) ? _.get(release, 'content_id', '') : release.get('content_id');
    const taggedContentId = this.getTaggedContentId(contentId);
    if (!taggedContentId) {
      return '';
    }
    return this.buildJsonPath([this.METADATA_PROPERTY, this.TAGGED_ELEMENTS_ROOT_PATH_MAP[taggedContentId], this.TAGS_LIST_PROPERTY], prefix);
  },

  getTagsJsonPath: function getTagsJsonPath(jsonPath) {
    if (!jsonPath) {
      return '';
    }
    return this.buildJsonPath([jsonPath, this.TAGS_JSON_PATH]);
  },

  getDecodedTags: function getDecodedTags(tags, newJoiner) {
    if (!newJoiner) {
      newJoiner = ',';
    }
    if (tags) {
      return _.clone(tags).split(',').map((tag) => {
        return decodeURIComponent(tag);
      }).join(newJoiner);
    }
    return '';
  },

  getTagsListFromArray: function getTagsListFromArray(release) {
    const tagsList = [];
    const taggedElementsRootPath = this.getTaggedElementsRootPath(release, false);
    const elements = _.get(release.get('value'), taggedElementsRootPath);
    const self = this;
    this.iterateArray(elements, this.METADATA_PROPERTY, (property, value) => {
      value = value[self.TAGS_PROPERTY];
      const splittedValue = (value) ? value.split(',') : [];
      if (splittedValue.length > 1) {
        while (splittedValue.length) {
          tagsList.push(splittedValue.join(','));
          splittedValue.pop();
        }
      } else if (splittedValue.length === 1 && splittedValue[0]) {
        tagsList.push(value);
      }
    }, () => {
    });
    return _.uniq(tagsList).sort(Utils.naturalSort);
  },

  getTagsInfo: function getTagsInfo(release, isSavable, prefix) {
    const taggedElementsRootPath = this.getTaggedElementsRootPath(release, false);
    const tagsList = (_.get(release.get('value'), this.getTagsListJsonPath(release, false, prefix)) ?? []).sort(Utils.naturalSort) || [];
    const subTagsIndex = {};
    tagsList.map((tags) => {
      const tagsArray = tags.split(',');
      const mainTag = decodeURIComponent(tagsArray.splice(0, 1));
      if (!subTagsIndex[mainTag]) {
        subTagsIndex[mainTag] = [];
      }
      if (tagsArray.length) {
        subTagsIndex[mainTag].push(tagsArray[0]);
      }
    });
    const output = [];

    const uniqueArray = function (arrArg) {
      return arrArg.filter((elem, pos, arr) => {
        return arr.indexOf(elem) == pos;
      });
    };

    for (const mainTag in subTagsIndex) {
      const subTags = uniqueArray((subTagsIndex[mainTag]).map((subtag) => {
        return decodeURIComponent(subtag);
      }));

      output.push({
        contentId: release.get('content_id'),
        localeId: release.get('locale'),
        releaseId: release.get('id'),
        isSavable: isSavable,
        mainTag: mainTag,
        mainTagStr: decodeURIComponent(mainTag),
        subTags: subTags.map((subTag) => {
          return {
            contentId: release.get('content_id'),
            localeId: release.get('locale'),
            releaseId: release.get('id'),
            isSavable: isSavable,
            mainTag: mainTag,
            subTag: subTag,
            subTagStr: decodeURIComponent(subTag),
          };
        }),
      });
    }
    return [
      {
        contentId: release.get('content_id'),
        localeId: release.get('locale'),
        releaseId: release.get('id'),
        isSavable: isSavable,
        mainTagStr: '* ' + this.ALL_TAGS_STRING + ' ' + taggedElementsRootPath,
        mainTag: this.ALL_TAGS_STRING,
        subTags: [],
      },
      {
        contentId: release.get('content_id'),
        localeId: release.get('locale'),
        releaseId: release.get('id'),
        isSavable: isSavable,
        mainTagStr: '* ' + this.UNTAGGED_TAGS_STRING + ' ' + taggedElementsRootPath,
        mainTag: this.UNTAGGED_TAGS_STRING,
        subTags: [],
      }].concat(output);

  },

  updateTagsList: function updateTagsList(input, prefix) {
    if (!input || !input.release) {
      return null;
    }
    if (!input.newTags || !input.operation) {
      return input.release;
    }

    const metadataPath = this.METADATA_PROPERTY;
    const tagsListJsonPath = this.getTagsListJsonPath(input.release, false, prefix);
    const tagsListParentJsonPath = Utils.getParentPath(tagsListJsonPath, this.JSON_PATH_SEPARATOR);
    const tagsList = input.release.getValue(tagsListJsonPath) || [];
    const splittedTags = input.newTags.split(',');
    const splittedOldTags = (input.oldTags) ? input.oldTags.split(',') : [];
    const mainTag = (splittedTags.length > 1) ? splittedTags[0] : null;
    const tagsListPathExists = input.release.pathExists(tagsListJsonPath);
    let tagsListModified = false;

    if ((input.operation === 'replace' || input.operation === 'remove') && splittedOldTags.length === 1 && input.oldTags !== this.UNTAGGED_TAGS_STRING) {
      const matchingTagsList = tagsList.filter((tags) => {
        return (tags === input.oldTags || tags.startsWith(input.oldTags + ','));
      });
      if (matchingTagsList.length) {
        tagsListModified = true;
        const self = this;
        matchingTagsList.map((tagsToRemove) => {
          const tagsToModifyIndex = tagsList.indexOf(tagsToRemove);
          const tagsToModifyJsonPath = self.buildJsonPath([tagsListJsonPath, tagsToModifyIndex]);
          switch (input.operation) {
            case 'replace':
              const newValue = tagsList[tagsToModifyIndex].replace(input.oldTags, input.newTags);
              tagsList[tagsToModifyIndex] = newValue;
              if (tagsListPathExists) {
                input.release.pushOperation(false, 'replace', tagsToModifyJsonPath, newValue);
              }
              break;
            case 'remove':
              tagsList.splice(tagsToModifyIndex, 1);
              if (tagsListPathExists) {
                input.release.pushOperation(false, 'remove', tagsToModifyJsonPath);
              }
              break;
          }
        });
      }
    } else {
      if ((input.operation === 'replace' || input.operation === 'remove') && input.oldTags) {
        if (splittedOldTags.length > 1 && tagsList.includes(input.oldTags)) {
          tagsListModified = true;
          const oldTagsIndex = tagsList.indexOf(input.oldTags);
          tagsList.splice(oldTagsIndex, 1);
          if (tagsListPathExists) {
            const oldTagsJsonPath = this.buildJsonPath([tagsListJsonPath, oldTagsIndex]);
            input.release.pushOperation(false, 'remove', oldTagsJsonPath);
          }
        }
      }
      if (input.operation === 'add' || input.operation === 'replace') {
        if (mainTag && !tagsList.includes(mainTag)) {
          tagsListModified = true;
          tagsList.push(mainTag);
          if (tagsListPathExists) {
            const newTagIndex = tagsList.length - 1;
            const newTagJsonPath = this.buildJsonPath([tagsListJsonPath, newTagIndex]);
            input.release.pushOperation(false, 'add', newTagJsonPath, mainTag);
          }
        }
        if (!tagsList.includes(input.newTags)) {
          tagsListModified = true;
          tagsList.push(input.newTags);
          if (tagsListPathExists) {
            const newTagIndex = tagsList.length - 1;
            const newTagJsonPath = this.buildJsonPath([tagsListJsonPath, newTagIndex]);
            input.release.pushOperation(false, 'add', newTagJsonPath, input.newTags);
          }
        }
      }
    }

    if (tagsListModified) {
      input.release.set('tagsListModified', true);
      input.release.setValue(tagsListJsonPath, tagsList);
      if (!tagsListPathExists) {
        if (!input.release.pathExists(metadataPath)) {
          const value = {};
          value[this.getTaggedElementsRootPath(input.release)] = {
            tagsList: tagsList,
          };
          input.release.pushOperation(false, 'add', metadataPath, value);
        } else if (!input.release.pathExists(tagsListParentJsonPath)) {
          const value = {
            tagsList: tagsList,
          };
          input.release.pushOperation(false, 'add', tagsListParentJsonPath, value);
        } else if (!input.release.pathExists(tagsListJsonPath)) {
          input.release.pushOperation(false, 'add', tagsListJsonPath, tagsList);
        }
      }
    }
    return input.release;
  },

  iterateArray: function iterateArray(array, propertyToFind, callback, errorCallback) {
    if (array && array.length) {
      array.forEach((element) => {
        for (const property in element) {
          if (element.hasOwnProperty(property) && property === propertyToFind) {
            callback(property, element[property]);
          }
        }
      });
    } else {
      errorCallback();
    }
  },

  tagsMapping: function tagsMapping(release, isJson, prefix) {
    const taggedElementsRootPath = this.getTaggedElementsRootPath(release, isJson, prefix);
    const releaseValue = (isJson) ? _.get(release, 'value', {}) : release.get('value');
    const value = _.get(releaseValue, taggedElementsRootPath, []);
    const tagsMetadata = {
      taggedElementsRootPath: taggedElementsRootPath,
      tagsMap: {},
      definitionsMap: {},
      labelsMap: {},
      titlesMap: {},
      prefix,
    };
    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      const elementPath = this.buildJsonPath([taggedElementsRootPath, i], prefix);
      const elementTags = _.get(element, this.TAGS_JSON_PATH, '');
      _.set(tagsMetadata.tagsMap, i, elementTags);
      const elementMetadata = this.getTaggedElementMetadata(elementPath, release, isJson);
      _.set(tagsMetadata.definitionsMap, i, _.get(elementMetadata, 'definition', ''));
      _.set(tagsMetadata.labelsMap, i, _.get(elementMetadata, 'label', ''));
      _.set(tagsMetadata.titlesMap, i, _.get(elementMetadata, 'title', ''));
    }
    if (isJson) {
      _.set(release, 'tagsMetadata', tagsMetadata);
    } else {
      release.set('tagsMetadata', tagsMetadata);
    }
    return release;
  },

  getTaggedElementMetadata: function getTaggedElementMetadata(jsonPath, release, isJson) {
    const output = {};
    if (!jsonPath || !release) {
      return output;
    }
    const value = release.getValue(jsonPath);
    const definition = this.getTaggedElementsDefinition(release, isJson);
    const definitionRef = '#/definitions/' + definition;
    const schema = release.getSchemaDefinitionFor('', definitionRef);

    output.definition = definition;
    if (value && schema && schema.objectLabel) {
      output.label = schema.objectLabel;
      output.title = output.definition + ' with ' + schema.objectLabel + ' = ' + _.get(value, schema.objectLabel);
    } else if (value && schema && schema.arrayLabel) {
      output.label = schema.objectLabel;
      output.title = output.definition + ' with ' + schema.arrayLabel + ' = ' + _.get(value, schema.arrayLabel);
    }
    return output;
  },

  getUnfilteredElements: function getUnfilteredElements(release) {
    if(isDcxContentId(release.get('content_id'))) {
      return this.UNFILTERED_ELEMENTS['dcx'] || [];
    }
    return this.UNFILTERED_ELEMENTS[release.get('content_id')] || [];
  },

  getCategories: function getCategories(release) {
    const unfilteredElements = this.getUnfilteredElements(release);
    return Object.keys(release.get('value')).filter((key) => !unfilteredElements.includes(key));
  },

  categoryFiltering: function categoryFiltering(release, isJson, prefix) {
    const filteredValue = {};
    const dontFilterKeyList = this.getUnfilteredElements(release);
    const releaseValue = (isJson) ? _.get(release, 'value', {}) : release.get('value');
    for (const key in releaseValue) {
      if (key.startsWith(prefix) || dontFilterKeyList.includes(key)) {
        filteredValue[key] = releaseValue[key];
      }
    }
    if (isJson) {
      // _.set(release, 'tagsMetadata', tagsMetadata);
      _.set(release, 'value', filteredValue ?? []);
    } else {
      // release.set('tagsMetadata', tagsMetadata);
      release.set('value', filteredValue ?? []);
    }
  },

  tagsFiltering: function tagsFiltering(release, selectedTags, isJson, prefix) {
    const taggedElementsRootPath = this.getTaggedElementsRootPath(release, isJson, prefix);
    const releaseValue = (isJson) ? _.get(release, 'value', {}) : release.get('value');
    let value = _.get(releaseValue, taggedElementsRootPath, []);
    const tagsMetadata = (isJson) ? _.get(release, 'tagsMetadata', {}) : release.get('tagsMetadata') || {};
    tagsMetadata.taggedElementsRootPath = taggedElementsRootPath;
    tagsMetadata.unfilteredElementsLength = value.length;
    tagsMetadata.selectedTags = selectedTags;
    tagsMetadata.filteredIndexesMap = [];
    tagsMetadata.hiddenPageIds = [];
    tagsMetadata.hiddenUrls = [];
    tagsMetadata.hiddenDisabledMetadatas = [];
    if (selectedTags !== this.ALL_TAGS_STRING) {
      const self = this;
      value = value.filter((element, index) => {
        const elementTags = _.get(element, self.TAGS_JSON_PATH, '');
        if (selectedTags === self.UNTAGGED_TAGS_STRING && !elementTags) {
          tagsMetadata.filteredIndexesMap.push(index);
          return true;
        }
        if (selectedTags !== self.UNTAGGED_TAGS_STRING && elementTags) {
          const splittedTags = selectedTags.split(',');
          const splittedElementTags = elementTags.split(',');
          for (let i = 0; i < splittedTags.length; i++) {
            if (splittedTags[i] !== splittedElementTags[i]) {
              tagsMetadata.hiddenPageIds.push(_.get(element, 'pageId', ''));
              tagsMetadata.hiddenUrls.push(_.get(element, 'url', ''));
              tagsMetadata.hiddenDisabledMetadatas.push(_.get(element, '@metadata.disabled', false));
              return false;
            }
          }
          tagsMetadata.filteredIndexesMap.push(index);
          return true;
        }
        tagsMetadata.hiddenPageIds.push(_.get(element, 'pageId', ''));
        tagsMetadata.hiddenUrls.push(_.get(element, 'url', ''));
        tagsMetadata.hiddenDisabledMetadatas.push(_.get(element, '@metadata.disabled', false));
        return false;
      });
    }
    if (!Array.isArray(_.get(releaseValue, taggedElementsRootPath))) {
      return release;
    }
    if (isJson) {
      _.set(release, 'tagsMetadata', tagsMetadata);
      _.set(release, 'value', _.set(releaseValue, taggedElementsRootPath, value, []));
    } else {
      release.set('tagsMetadata', tagsMetadata);
      release.set('value', _.set(releaseValue, taggedElementsRootPath, value, []));
    }
    return release;
  },

  getTagsFilteredPath: function getTagsFilteredPath(release, path) {
    const tagsMetadata = release.get('tagsMetadata');
    if (Utils.isNotEmptyObject(tagsMetadata) && tagsMetadata.selectedTags && tagsMetadata.selectedTags !== this.ALL_TAGS_STRING) {
      const taggedElementUnfilteredPath = this.getTaggedElementPath(_.clone(path), this.JSON_PATH_SEPARATOR, tagsMetadata.prefix);
      const taggedElementUnfilteredIndex = parseInt(Utils.getLastPathPart(taggedElementUnfilteredPath, this.JSON_PATH_SEPARATOR), 10);
      const taggedElementFilteredIndex = tagsMetadata.filteredIndexesMap.indexOf(taggedElementUnfilteredIndex);
      const operationFilteredPath = this.buildJsonPath([tagsMetadata.taggedElementsRootPath, taggedElementFilteredIndex]);
      return path.replace(taggedElementUnfilteredPath, operationFilteredPath);
    }
    return path;
  },

  updateTagsPathIndex: function updateTagsPathIndex(release, isMoveOp, op, path) {
    const oldPath = path;
    const tagsMetadata = _.cloneDeep((release.get('tagsMetadata')));
    if (Utils.isNotEmptyObject(tagsMetadata) && tagsMetadata.selectedTags && tagsMetadata.selectedTags !== this.ALL_TAGS_STRING) {
      const taggedElementPath = this.getTaggedElementPath(_.clone(path), this.JSON_PATH_SEPARATOR, tagsMetadata.prefix);
      const taggedElementIndex = parseInt(Utils.getLastPathPart(taggedElementPath, this.JSON_PATH_SEPARATOR), 10);
      let taggedElementUnfilteredIndex = parseInt(tagsMetadata.filteredIndexesMap[taggedElementIndex], 10);
      const isTaggedElementOperation = (path === taggedElementPath);
      const isIndexOperation = Utils.lastPartIsIndex(path, this.JSON_POINTER_SEPARATOR);
      if (isTaggedElementOperation && isIndexOperation) {
        switch (op) {
          case 'remove':
            tagsMetadata.filteredIndexesMap = tagsMetadata.filteredIndexesMap.map((unfilteredIndex) => {
              return (unfilteredIndex > taggedElementUnfilteredIndex) ? unfilteredIndex - 1 : unfilteredIndex;
            });
            tagsMetadata.filteredIndexesMap.splice(taggedElementIndex, 1);
            tagsMetadata.unfilteredElementsLength--;
            break;
          case 'add':
            if (!isMoveOp) {
              taggedElementUnfilteredIndex = _.clone(tagsMetadata.unfilteredElementsLength);
              tagsMetadata.filteredIndexesMap.push(taggedElementUnfilteredIndex);
            } else {
              if (taggedElementIndex - 1 >= 0) {
                taggedElementUnfilteredIndex = tagsMetadata.filteredIndexesMap[taggedElementIndex - 1] + 1;
              } else {
                taggedElementUnfilteredIndex = tagsMetadata.filteredIndexesMap[taggedElementIndex] - 1;
              }
              tagsMetadata.filteredIndexesMap = tagsMetadata.filteredIndexesMap.map((unfilteredIndex) => {
                return (unfilteredIndex >= taggedElementUnfilteredIndex) ? unfilteredIndex + 1 : unfilteredIndex;
              });
              tagsMetadata.filteredIndexesMap.splice(taggedElementIndex, 0, taggedElementUnfilteredIndex);
            }
            tagsMetadata.unfilteredElementsLength++;
            break;
          default:
            break;
        }
        release.set('tagsMetadata', tagsMetadata);
      }
      const operationUnfilteredPath = this.buildJsonPath([tagsMetadata.taggedElementsRootPath, taggedElementUnfilteredIndex]);
      const newPath = path.replace(taggedElementPath, operationUnfilteredPath);
      return newPath;
    }
    return path;
  },

  getTaggedElementPath: function getTaggedElementPath(path, separator, hasPrefix) {
    if (path) {
      const splittedPath = path.split(separator);
      if (splittedPath.length > 2) {
        if (hasPrefix) {
          return splittedPath.splice(0, 3).join(separator);
        }
        return splittedPath.splice(0, 2).join(separator);
      }
    }
    return path;
  },

};
module.exports = TagsUtils;
