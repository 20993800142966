'use strict';

const Locales = require('models/locales');

const Content = Backbone.Model.extend({

  collection: {
    url: '/v1/contents',
  },

  defaults: {
    id: '',
    desc: '',
    preview_url: '',
    notifications_urls: [],
    deleteBlocked: false,
  },

  url: function url () {
    let url = '/v1/contents';
    if (this.get('id')) {
      if (this.get('isRestoreAction')) {
        url = '/v1/restoreContent';
      }
      url += '/';
      url += this.get('id');
      if (!this.get('isRestoreAction')) {
        url += '?noBackup=true';
      }
    }
    return url;
  },

  initialize: function initialize (attributes) {
    this.locales = new Locales({ contentId: attributes ? attributes.id : undefined });
    this.on('change:id', function () {
      this.locales.contentId = this.get('id');
    }, this);
  },

  validate: function validate (attributes) {
    if (attributes.id) {
      if (!/^[a-z0-9\-_]{0,255}$/.test(attributes.id)) {
        return 'Content name can contain only lowercase alphanumeric characters and \'-\' or \'_\', without spaces and max length: 255';
      } else if (attributes.preview_url && !/^((http|https|ftp):\/\/)/.test(attributes.preview_url)) {
        return 'Application url ' + attributes.preview_url + ' is not valid';
      } else if (attributes.notifications_urls) {
        for (let count = 0; count < attributes.notifications_urls.length; count++) {
          if (!/^((http|https|ftp):\/\/)/.test(attributes.notifications_urls[count])) {
            return 'Notifications urls ' + attributes.notifications_urls[count] + ' is not valid';
          }
        }
      }
    } else {
      return 'Please enter a Content name';
    }
  },

  getValue: function getValue (path) {
    return _.get(this.get('value'), path);
  },

  setValue: function setValue (path, newValue) {
    _.set(this.get('value'), path, newValue);
    this.trigger('change');
  },
});

module.exports = Content;
