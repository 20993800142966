'use strict';

let  View,
  singleton;
const  template = require('templates/searchAndReplaceModal'),
  Confirm = require('views/confirmView'),
  TreeItemTools = require('utils/TreeItemTools'),
  DataUtils = require('utils/DataUtils'),
  TagsUtils = require('utils/TagsUtils'),
  UrlUtils = require('utils/UrlUtils');


function buildConfirmationMessage(replacementString) {
  if (replacementString) {
    return 'You are about to replace all occurrences of "'
      + this.searchStr
      + '" in the current release with "'
      + replacementString
      + '".';
  } 
    return 'You are about to delete all occurrences of "'
      + this.searchStr
      + '" in the current release.';
  
}

function applyURLFormatting(replacementString) {
  replacementString = UrlUtils.autoformatUrl(replacementString);
  replacementString = UrlUtils.autoformatB2cUrl(this.contentId, replacementString, false);
  replacementString = UrlUtils.autoformatB2cAnchor(this.contentId, replacementString, true);
  return replacementString;
}


function search() {
  this.resetErrorAndWarningDivs();
  const result = [];
  const $searchInput = $('#input-release-modal-search');
  const searchStr = ($searchInput) ? $searchInput.val() : '';
  const searchCanBePerformed = searchStr && searchStr.length >= 2;

  const iterationCallback = function(property, value, path) {
    if (!Array.isArray(value) && isNaN(value) && !(value instanceof Object) && path.indexOf('@metadata') === -1 && value.indexOf(searchStr) !== -1) {
      const item = {};
      if (TagsUtils.jsonPathStartsWithTaggedRootPath(self.release.get('content_id'), path)) {
        const taggedElementPath = TagsUtils.getTaggedElementPath(path, '.');
        const taggedElementIdProperty = TagsUtils.getTaggedElementsIdProperty(self.release, false);
        if (taggedElementPath && taggedElementIdProperty) {
          const taggedElementIdPath = TagsUtils.buildJsonPath([taggedElementPath, taggedElementIdProperty]);
          item[taggedElementIdProperty] = self.release.getValue(taggedElementIdPath);
        }
      }
      item.path = path;
      item.value = value;
      result.push(item);
    }
  };


  if (searchCanBePerformed) {
    var self = this;
    TreeItemTools.iterate(this.release.get('value'), '', iterationCallback);
  }

  const doesResearchReturnedSomething = result.length > 0;


  if (doesResearchReturnedSomething) {
    $('#input-release-modal-replace').removeAttr('disabled');
    $('.js-btn-replace').removeAttr('disabled');
  } else {
    $('#input-release-modal-replace').attr('disabled', true);
    $('.js-btn-replace').attr('disabled', true);
    this.showWarningDiv($('<div class="cms-release-search-title-label--warning"><b>No match found</b></div>'));
  }

  this.searchStr = searchStr;
  this.searchResult = result;
  this.jsonEditor.set(result);
  
}

function replace() {
  this.resetErrorAndWarningDivs();

  const doesResearchReturnedSomething = this.searchResult && this.searchResult.length;

  if (doesResearchReturnedSomething) {

      const $replaceHTMLInput = $('#input-release-modal-replace');
    let  replacementString = ($replaceHTMLInput) ? $replaceHTMLInput.val() : '';
      const isReplacementStringAnURL = replacementString && UrlUtils.isUrl(replacementString);
      const confirmationMessage = buildConfirmationMessage.call(this, replacementString);


      if (isReplacementStringAnURL) {
        replacementString = applyURLFormatting.call(this, replacementString);
      }


      const callBackReplace = function() {
        this.callBackReplace({
          searchResult: this.searchResult,
          searchStr: this.searchStr,
          replaceStr: replacementString,
        });
        return true;
      };

      const toggleModalCallback = function() {
        this.$el.parent().modal('toggle');
        return false;
      };

      Confirm.showMe(confirmationMessage, $.proxy(callBackReplace, this), $.proxy(toggleModalCallback, this));
  } else {
    this.showErrorDiv($('<div class="cms-release-search-title-label--error"><b>ERROR:</b> cannot replace because search did not match any result</div>'));
    return false;
  }
}


function renderJsonEditor() {
  const jsonContainer = document.getElementById('node-jsoneditor');
  const options = {
    mode: 'code',
    error: function(err) {
      notificationEvent.notify(err.toString(), 'danger');
    },
  };
  this.jsonEditor = new JSONEditor(jsonContainer, options);
}

function close() {
  this.$el.remove();
  this.off();
  if (this.release) {
    this.release.off(null, null, this);
  }
}

function initModel() {
  return {
    isSavable: this.isSavable,
  };
}


function downloadJson() {
  this.resetErrorAndWarningDivs();
  if (this.searchResult && this.searchResult.length) {
    const data = JSON.stringify(this.searchResult);
    const fileName = [this.contentId, this.localeId, this.searchStr, 'search', (new Date()).toISOString().slice(0, 10).replace(/-/g, '')].join('_') + '.json';
    const fileMimeType = 'application/json';
    DataUtils.downloadData(data, fileName, fileMimeType);
    return false;
  } 
    this.showErrorDiv($('<div class="cms-release-search-title-label--error"><b>ERROR:</b> no result to download</div>'));
    return false;
  
}



function convertResult2Csv() {
  const csvLines = [];
  if (!_.isArray(this.searchResult)) {
    throw new Error();
  }
  for (let  i = 0; i < this.searchResult.length; i++) {
    const item = this.searchResult[i];
    csvLines.push('``' + item.path + '``,``' + item.value + '``');
  }
  return csvLines.join('\n');
}

function downloadXlsx() {
  this.resetErrorAndWarningDivs();
  if (this.searchResult && this.searchResult.length) {
    const csvValue = this.convertResult2Csv();

    const data = DataUtils.csv2Array(csvValue);
    const fileName = [this.contentId, this.localeId, this.searchStr, 'search', (new Date()).toISOString().slice(0, 10).replace(/-/g, '')].join('_') + '.xlsx';
    const worksheetName = 'search result'; // worksheet names are limited to 31 chars
    DataUtils.downloadXlsx(data, fileName, worksheetName);
    return false;
  } 
    this.showErrorDiv($('<div class="cms-release-search-title-label--error"><b>ERROR:</b> no result to download</div>'));
    return false;
  
}

function resetErrorAndWarningDivs() {
  const $errorDiv = $('.cms-release-search--error');
  $errorDiv.addClass('hidden');
  const $errorTitleLabel = $('.cms-release-search-title-label--error');
  $errorTitleLabel.remove();
  const $errorLabel = $('.cms-release-search-label--error');
  $errorLabel.remove();

  const $warningDiv = $('.cms-release-search--warning');
  $warningDiv.addClass('hidden');
  const $warningTitleLabel = $('.cms-release-search-title-label--warning');
  $warningTitleLabel.remove();
  const $warningLabel = $('.cms-release-search-label--warning');
  $warningLabel.remove();
}

function showWarningDiv($warningElement) {
  const $warningDiv = $('.cms-release-search--warning');
  $warningDiv.removeClass('hidden');
  const $warningField = $('.cms-release-search-field--warning');
  $warningElement.appendTo($warningField);
}

function showErrorDiv($errorElement) {
  const $errorDiv = $('.cms-release-search--error');
  $errorDiv.removeClass('hidden');
  const $errorField = $('.cms-release-search-field--error');
  $errorElement.appendTo($errorField);
}


function cancel() {
  this.resetErrorAndWarningDivs();
  this.$el.parent().modal('toggle');
}

function showMe(release, isSavable, callBack) {
  if (!singleton) {
    singleton = new View();
  } else {
    singleton.close();
  }
  singleton.release = release;
  singleton.contentId = release.get('content_id');
  singleton.localeId = release.get('locale');
  singleton.isSavable = isSavable;
  singleton.callBackReplace = callBack;
  singleton.render();
  return singleton;
}

function render() {
  const self = this;
  const templateModel = this.initModel();
  const html = template(templateModel);
  this.$el.html(html);
  $('#modal-searchAndReplace').append(this.$el);
  this.renderJsonEditor();
  this.$el.parent().modal('toggle');
  $('#input-release-modal-search').keypress( (event) => {
    if (event.keyCode === 13) {
      self.search();
    }
  });
  this.delegateEvents();
  return this;
}

View = Backbone.View.extend({

  events: {
    'click .js-btn-dl-json': 'downloadJson',
    'click .js-btn-dl-xlsx': 'downloadXlsx',
    'click #basic-addon-search': 'search',
    'click .js-btn-replace': 'replace',
    'click .js-btn-cancel': 'cancel',
  },

  close: close,
  initModel: initModel,
  downloadJson: downloadJson,
  convertResult2Csv: convertResult2Csv,
  downloadXlsx: downloadXlsx,
  search: search,
  replace: replace,
  resetErrorAndWarningDivs:resetErrorAndWarningDivs,
  showWarningDiv: showWarningDiv,
  showErrorDiv:showErrorDiv ,
  cancel: cancel,
  renderJsonEditor:renderJsonEditor ,
  render: render,
}, {
  showMe: showMe,
});

module.exports = View;
