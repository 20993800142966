'use strict';


var View,
    singleton,
    template = require('templates/userImportModal'),
    Users = require('models/users'),
    ImportUsers = require('models/importUsers'),
    ImportScopes = require('models/importScopes'),
    User = require('models/user'),
    Spinner = require('Spinner'),
    errorHandler = require('utils/errorHandler'),
    notificationEvent = require('NotificationEvent'),

    View = Backbone.View.extend({
        events: {
            'click .checkall': 'checkall',
            'click .viewDiff': 'toggleViewDiff',
            'click .importUsers': 'importUserList',
            'change .searchUsers': 'searchUsers',
        },

        createUser: function createUser(user, retry) {
            var self = this;
            user.sync('create', user, {
                success: function () {
                    self.importUserScopes(user, true);
                    self.callback();
                },
                error: function (error) {
                    errorHandler({
                        error: error,
                        retry: retry,
                        success: function () {
                            if (retry) {
                                self.createUser(user, false);
                            }
                        },
                        fail: function () {
                            notificationEvent.notify('Unable to create User', 'danger');
                        },
                    });
                },
            });
        },

        importUserScopes: function importUserScopes(user, retry) {
            var self = this;

            const scopes = new ImportScopes({ hepta: user.get('hepta'), token: self.token });
            scopes.sync('update', scopes, {
                success: function () {
                },
                error: function (error) {
                    errorHandler({
                        error: error,
                        retry: retry,
                        success: function () {
                            if (retry) {
                                self.importUserScopes(user, false);
                            }
                        },
                        fail: function () {
                            notificationEvent.notify('Error importing Scopes', 'danger');
                        },
                    });
                },
            });
        },

        checkall: function checkall() {
            $('.checkbox-user').prop('checked', true);
        },

        toggleViewDiff: function toggleViewDiff() {
            this.viewDiff = !this.viewDiff;
            this.render(false);
        },

        importUserList: function importUserList() {
            Spinner.show();
            var self = this;

            $.each(this.$('input:checked'), function () {
                var user = new User(JSON.parse($(this).val()));
                if (!singleton.users.models.some((u) => u.get('hepta') === user.get('hepta'))) {
                    self.createUser(user, true);
                } else {
                    self.importUserScopes(user, true);
                }
            }).promise().done(() => {
                notificationEvent.notify('Users imported successfully', 'success');
                Spinner.hide();
            }
            );

        },

        searchUsers: function searchUsers(e) {
            var search = e.target.value;
            this.$('.cms-user-item').each(function () {
                const checkbox = $(this).find('.checkbox-user');
                const value = checkbox.val() && JSON.parse(checkbox.val());
                if (
                    value?.hepta.indexOf(search) === -1
                    && value?.email.indexOf(search) === -1
                    && value?.firstname.indexOf(search) === -1
                    && value?.lastname.indexOf(search) === -1
                    && !checkbox.is(':checked')
                ) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        },

        close: function close() {
            this.$el.remove();
            this.off();
            if (this.model) {
                this.model.off(null, null, this);
            }
        },

        render: function render(toggle = true) {
            Spinner.hide();
            const importUsers = this.importUsers.toJSON().filter((user) => {
                if (this.viewDiff) {
                    return !this.users.models.some((u) => u.get('hepta') === user.hepta);
                };
                return true;
            });
            var html = template({ users: this.users.toJSON(), importUsers, viewDiff: this.viewDiff });
            this.$el.html(html);

            $('#modal-userImport').append(this.$el);
            if (toggle) {
                this.$el.parent().modal('toggle');
            }
            this.delegateEvents();
            return this;
        },

    }, {

        retrieveData: function retrieveData(users, importUsers, retry) {
            var self = this;
            Spinner.show();
            $.when(
                users.fetch({
                    success: function (model) {
                        Spinner.hide();
                        singleton.users = model;
                    },
                    error: function (model, error) {
                        errorHandler({
                            error: error,
                            retry: retry,
                            success: function () {
                                if (retry) {
                                    self.retrieveData(users, importUsers, false);
                                }
                            },
                            fail: function () {
                            },
                        });
                    },
                }),
                importUsers.fetch({
                    success: function (model) {
                        Spinner.hide();
                        singleton.importUsers = model;
                    }, error: function (model, error) {
                        errorHandler({
                            error: error,
                            retry: retry,
                            success: function () {
                                if (retry) {
                                    self.retrieveData(users, importUsers, false);
                                }
                            },
                            fail: function () {
                            },
                        });
                    },
                }),
            ).done(() => {
                singleton.render();
            });
        },
        showMe: function showMe({ token, callback }) {
            Spinner.show();
            if (!singleton) {
                singleton = new View();
            } else {
                singleton.close();
            }

            singleton.token = token;
            singleton.callback = callback;
            singleton.viewDiff = true;

            var users = new Users();
            var importUsers = new ImportUsers({ token });

            singleton.users = users;

            this.retrieveData(users, importUsers);
            return singleton;
        },
    });

module.exports = View;

