'use strict';

let View,
  singleton;
const  template = require('templates/selectNodeModal'),
  Release = require('models/release'),
  Spinner = require('Spinner'),
  notificationEvent = require('NotificationEvent'),
  Utils = require('utils/utils'),
  errorHandler = require('utils/errorHandler');

View = Backbone.View.extend({

  initialize: function initialize() {
    this.initHandlebarsHelpers();
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.release) {
      this.release.off(null, null, this);
    }
  },

  initHandlebarsHelpers: function initHandlebarsHelpers() {
    Handlebars.registerHelper('ifEq', function(value, otherValue, options) {
      if (value === otherValue) {
        return options.fn(this);
      } 
        return options.inverse(this);
      
    });
  },
  refreshSearchResult: function refreshSearchResult() {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById('searchBarImport');
    filter = input.value.toUpperCase();
    table = document.getElementById('myTable');
    tr = table.getElementsByTagName('tr');

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName('td')[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }
  },
  initModel: function initModel() {
    var listDefsToFind = [];
    var listNodeToSelect = [];
    var basePathForIteration = this.nodeJsonPath;

    if (Utils.isPathRecursiveArrays(basePathForIteration)) {
      basePathForIteration = Utils.getFirstArrayPath(this.nodeJsonPath);
    }

    var nodeArray = _.get(this.release.get('value'), basePathForIteration);
    if (_.isArray(nodeArray)) {
      var arrayLabel;
      var parentSchema = this.release.getSchemaDefinitionFor(basePathForIteration);
      if (parentSchema && parentSchema.items) {
        arrayLabel = parentSchema.items.arrayLabel;
      }

      listNodeToSelect = this._findElementsToImportFirstIteration(basePathForIteration, nodeArray, arrayLabel, listDefsToFind);
    }


    return {
      nodeJsonPath: basePathForIteration,
      listNodeToSelect: listNodeToSelect,
      maxNodeSelectCount: this.MAX_NODE_SELECT_COUNT,
      isPathSelection: this.isPathSelection,
      showButton: this.$el.find('input:checked').length > 0,
    };
  },

  _findElementsToImportFirstIteration: function _findElementsToImportFirstIteration(basePathForIteration, arrayValue, arrayLabel) {
    var listNodeToSelect = [];
    if (basePathForIteration && arrayValue && arrayValue.length) {
      for (var i = 0; i < arrayValue.length; i++) {
        var elementJsonPath = basePathForIteration + '.' + i;
        var elementValue = arrayValue[i];
        var importNodeName = Utils.getLastPathPart(basePathForIteration);

        var tempList = this._findElementsToImportRecursive(elementJsonPath, elementValue, importNodeName, arrayLabel);
        if (tempList && tempList.length) {
          listNodeToSelect = listNodeToSelect.concat(tempList);
          if (tempList.length > 1) {
            listNodeToSelect.push(['', '']);
          }
        }
      }
    }

    return listNodeToSelect;
  },

  _findElementsToImportRecursive: function _findElementsToImportRecursive(parentJsonPath, parentValue, importNodeName, arrayLabel) {
    var listNodeToSelect = [];
    if (parentValue && parentJsonPath) {
      if (importNodeName && parentValue[importNodeName] && parentValue[importNodeName].length) {
        listNodeToSelect.push(['', '']);

        var arrayTypeAndLabel = this._getObjectTypeAndLabel(parentValue);
        var arrayTitle = this._getNodeTitle(parentJsonPath, parentValue, arrayLabel, arrayTypeAndLabel);
        listNodeToSelect.push([arrayTitle, 'arrayTitle']);

        var arrayJsonPath = parentJsonPath + '.' + importNodeName;
        var arrayValue = parentValue[importNodeName];
        for (var i = 0; i < arrayValue.length; i++) {
          var elementJsonPath = arrayJsonPath + '.' + i;
          var elementValue = arrayValue[i];
          var tempList = this._findElementsToImportRecursive(elementJsonPath, elementValue, importNodeName, arrayLabel);
          if (tempList && tempList.length) {
            listNodeToSelect = listNodeToSelect.concat(tempList);
          }
        }
      } else {
        var objectTypeAndLabel = this._getObjectTypeAndLabel(parentValue);
        var objectTitle = this._getNodeTitle(parentJsonPath, parentValue, arrayLabel, objectTypeAndLabel);
        listNodeToSelect.push([objectTitle, parentJsonPath]);
      }
    }

    return listNodeToSelect;
  },

  _getObjectTypeAndLabel: function _getObjectTypeAndLabel(nodeValue) {
    var objectType = '';
    var objectLabel = '';
    if (nodeValue['@metadata'] && nodeValue['@metadata'].type) {
      objectType = nodeValue['@metadata'].type.replace('#/definitions/', '');
      var nodeSchema = this.release.getSchemaDefinitionFor('', nodeValue['@metadata'].type);
      if (nodeSchema) {
        objectLabel = nodeSchema.objectLabel;
      }
    }

    return {
      objectType: objectType,
      objectLabel: objectLabel,
    };
  },

  _getNodeTitle: function _getNodeTitle(nodeJsonPath, nodeValue, arrayLabel, objectTypeAndLabel) {
    var title = _.clone(nodeJsonPath);
    if (objectTypeAndLabel.objectLabel) {
      title = _.get(nodeValue, objectTypeAndLabel.objectLabel);
      if (objectTypeAndLabel.objectType) {
        title += ' (' + objectTypeAndLabel.objectType + ')';
      }
    } else if (arrayLabel) {
      title = _.get(nodeValue, arrayLabel);
      if (objectTypeAndLabel.objectType) {
        title += ' (' + objectTypeAndLabel.objectType + ')';
      }
    } else {
      if (objectTypeAndLabel.objectType) {
        title += '.' + objectTypeAndLabel.objectType;
      }
    }

    return title;
  },

  navigateTo: function navigateTo() {
    var listNodeToAdd = this._getListNodeToAdd();

    this.$el.parent().modal('toggle');
    this.callBackSelect(listNodeToAdd);
  },

  updateNodeSelectLeftCount: function updateNodeSelectLeftCount() {
    var nodeSelectCheckboxCount = $('.js-node-import-checkbox:checked').size();
    var numberOfNodeSelectLeft = this.MAX_NODE_SELECT_COUNT - nodeSelectCheckboxCount;
    $('#node-select-left-count').text(numberOfNodeSelectLeft);
    if (numberOfNodeSelectLeft <= 0) {
      $('.js-node-import-checkbox:not(:checked)').attr('disabled', true);
    } else {
      $('.js-node-import-checkbox:not(:checked)').removeAttr('disabled');
    }
    if(nodeSelectCheckboxCount) {
      $('.js-btn-import').removeAttr('disabled');
    } else {
      $('.js-btn-import').attr('disabled');
    }
    return true;
  },

  _getListNodeToAdd: function _getListNodeToAdd() {
    var $listInput = this.$el.find('input:checked');
    var listNodeToAdd = [];
    for (var i = 0, l = $listInput.length; i < l; i++) {
      listNodeToAdd.push(this.isPathSelection ? $listInput[i].value : _.get(this.release.get('value'), $listInput[i].value));
    }
    return listNodeToAdd;
  },


  cancel: function cancel() {
    this.$el.parent().modal('toggle');
  },

  render: function render() {
    Spinner.hide();
    var templateModel = this.initModel();
    var html = template(templateModel);
    this.$el.html(html);
    $('#modal-selectNode').append(this.$el);

    this.$el.parent().modal('toggle');
    this.$el.find('.js-node-import-checkbox').on('click', $.proxy(this.updateNodeSelectLeftCount, this));
    this.$el.find('.js-btn-import').on('click', $.proxy(this.navigateTo, this));
    this.$el.find('.js-btn-cancel').on('click', $.proxy(this.cancel, this));
    this.$el.find('#searchBarImport').keyup( $.proxy(this.refreshSearchResult, this));

    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData(release, retry) {
    var self = this;
    var promises = [release.fetch()];
    $.when.apply($, promises).done(() => {
      Spinner.hide();
      singleton.render();
    }).fail((error) => {
      errorHandler({
        error: error,
        retry: retry,
        success: function() {
          if (retry) {
            self.retrieveData(release, false);
          }
        },
        fail: function(jsonError) {
          if (jsonError && jsonError.statusCode === 404) {
            notificationEvent.notify('Live Release not found', 'danger');
          }
        },
      });
    });
  },

  showMe: function showMe(contentId, localeId, nodeJsonPath, isPathSelection, callBack) {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }

    singleton.release = new Release({
      id: 'live',
      content_id: contentId,
      locale: localeId,
    });
    singleton.callBackSelect = callBack;
    singleton.nodeJsonPath = nodeJsonPath;
    singleton.isPathSelection = isPathSelection;
    singleton.MAX_NODE_SELECT_COUNT = isPathSelection ? 1 : 20;
    this.retrieveData(singleton.release, true);
    return singleton;
  },
});

module.exports = View;
