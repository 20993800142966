const Config = Backbone.Model.extend({
  url: '/config',

  generateNonce: function () {
    return Math.random().toString(36).substr(2, 10);
  },

  getHomeUrl: function () {
    return encodeURIComponent(window.location.protocol + '//' + window.location.host + '/app/');
  },

  redirectTo: function (url) {
    window.location.replace(url);
  },

  getOverriddenValue: function getOverriddenValue (input) { // can't use dash in docker-compose environment keys
    const override = this.get('override');
    if (override && input.contentId) {
      if (override) {
        const parsedContentId = input.contentId.replace('-', '_');
        if (input.localeId) {
          const parsedLocaleId = input.localeId.replace('-', '_');
          const overridenValue = _.get(override, [parsedContentId, parsedLocaleId, input.jsonPath].join('.'));
          if (overridenValue) {
            return overridenValue;
          }
        }
        const overridenValue = _.get(override, [parsedContentId, input.jsonPath].join('.'));
        if (overridenValue) {
          return overridenValue;
        }
      }
    }
    const splittedJsonPath = input.jsonPath.split('.');
    if (splittedJsonPath.length > 1) {
      const parentProperty = splittedJsonPath.splice(0, 1)[0];
      const newJsonPath = splittedJsonPath.join('.');
      return _.get(this.get(parentProperty), newJsonPath);
    } 
      return this.get(input.jsonPath);
    
  },
});

module.exports = new Config();
