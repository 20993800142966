'use strict';

let View,
  singleton;
const template = require('templates/markdownCheatsheetModal');

View = Backbone.View.extend({

  close: function() {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  render: function() {
    const html = template({});
    this.$el.html(html);
    $('#modal-markdownCheatsheet').append(this.$el);
    this.$el.parent().modal('toggle');
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function() {
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }
    singleton.render();
    return singleton;
  },
});

module.exports = View;
