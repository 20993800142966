'use strict';


const Locale = require('models/locale');

let Locales = Backbone.Collection.extend({
  url: function() {
    if (this.contentId) {
      return '/v1/contents/' + this.contentId + '/locales';
    } 
      return '/v1/locales';
    
  },
  initialize: function(attributes) {
    if (attributes) {
      this.contentId = attributes.contentId;
    }
  },
  contentId: null,
  model: Locale,
});

module.exports = Locales;