
function set(key, value) {
  return localStorage.setItem(key, JSON.stringify(value));
}

function get(key) {
  return JSON.parse(localStorage.getItem(key));
}

set('current_view', { action: 'SHOW_HOME_PAGE' });

var State = {
  action: {
    SHOW_EDIT_TAGS:'SHOW_EDIT_TAGS',
    SHOW_HOME_PAGE: 'SHOW_HOME_PAGE',
    SHOW_RELEASES: 'SHOW_RELEASES',
    SHOW_RELEASE: 'SHOW_RELEASE',
    SHOW_MAIN_TAG: 'SHOW_MAIN_TAG',
    SHOW_SUB_TAG: 'SHOW_SUB_TAG',
  },

  add: function(action, content, locale, release, mainTag, subTag) {
    set('last_view', get('current_view'));
    set('current_view', {
      action: action,
      content: content,
      locale: locale,
      release: release,
      mainTag: mainTag,
      subTag: subTag,
    });
  },

  goToLastView : function goToLastView() {
    var last = { ...get('last_view') };
    var current = { ...get('current_view') };
    set('last_view', current);
    set('current_view', last);
    var appRouter = require('router');

    switch (last.action) {
      case this.action.SHOW_HOME_PAGE:
        appRouter.navigate('contents', { trigger: true });
        break;
      case this.action.SHOW_RELEASES :
        appRouter.navigate('contents/' + last.content + '/locales/' + last.locale + '/releases', { trigger: true });
        break;
      case this.action.SHOW_RELEASE :
        appRouter.release(last.content, last.locale, last.release);
        break;
      case this.action.SHOW_MAIN_TAG :
        appRouter.release(last.content, last.locale, last.release, last.mainTag);
        break;
      case this.action.SHOW_SUB_TAG :
        appRouter.release(last.content, last.locale, last.release, last.mainTag, last.subTag);
        break;
      case this.action.SHOW_EDIT_TAGS :
        appRouter.navigate('contents/' + last.content + '/locales/' + last.locale + '/releases/' + last.release + '/editTags/' + last.mainTag, { trigger: true });
        break;
    }
  },
};

module.exports = State;
