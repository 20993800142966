const colors =  {
  black: '#000000', // Black coal dans DSM
  blueWave: '#008080', // Blue Wave dans DSM
  lavender: '#9EAEF4', // Lavender blue ans DSM
  lightSand: '#F6EFE7', // Light Sas DSM
  saffron: '#FDBE00', // Saffron Yellow dans DSM
  sand: '#D5CDC2', // Sable dans DSM
  sienna: '#924833', // Sienna dans DSM
  ultramarine:'#1E2643', // Ultramarine Blue dans DSM
  verdigris: '#ACBEB9', // Verdigris dans DSM
};
// DSM link: https://clubmed.invisionapp.com/board/XR3UNN1W5Z2

module.exports = {
  getCssColorByName : function getCssColorByName(colorName) {
    return colors[colorName];
  },
};
