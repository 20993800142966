'use strict';

const
  Release = require('models/release'),
  config = require('models/config'),
  { CLUBMED_PAGES_CONTENT_ID_REGEX } = require('../utils/clubmedPagesContents');


function initHandlebarsHelpers() {
  Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
      case '==':
        return (v1 == v2) ? options.fn(this) : options.inverse(this);
      case '===':
        return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '!=':
        return (v1 != v2) ? options.fn(this) : options.inverse(this);
      case '!==':
        return (v1 !== v2) ? options.fn(this) : options.inverse(this);
      case '<':
        return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
        return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
        return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
        return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
        return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '&&!':
        return (v1 && !v2) ? options.fn(this) : options.inverse(this);
      case '||':
        return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });
  Handlebars.registerHelper('ifMatch', function(value, otherValue, options) {
    if (value.match(otherValue)) {
      return options.fn(this);
    }
      return options.inverse(this);

  });

  Handlebars.registerHelper('CLUBMED_PAGES_CONTENT_ID_REGEX', () => {
    return CLUBMED_PAGES_CONTENT_ID_REGEX;
  });
}


let Releases = Backbone.Collection.extend({
  url: function () {
    if (this.contentId && this.localeId) {
      let size = 10;
      if (this.status && this.status == 'archived' && !this.filter) {
        size = config.getOverriddenValue({
          jsonPath: 'db.general.nbOfArchivedReleasesPerLocale',
          contentId: this.contentId,
          localeId: this.localeId,
        }) || 10;
      }
      if (this.notStatus && this.notStatus == 'archived') {
        size = (config.getOverriddenValue({
          jsonPath: 'db.general.nbOfDraftReleasesPerLocale',
          contentId: this.contentId,
          localeId: this.localeId,
        }) + 1) || 4;
      }
      let url = '/v1/contents/' + this.contentId + '/locales/' + this.localeId + '/releases?size=' + size;

      if(this.infoOnly) {
        url += '&infoOnly=' + 'true';
      }else{
        url += '&infoOnly=' + 'false';
      }
      if (this.status) {
        url += '&status=' + this.status;
      } else {
        if (this.notStatus) {
          url += '&notStatus=' + this.notStatus;
        }
      }
      return url;
    }
      return '/v1/releases';

  },
  initialize: function (attributes) {

    initHandlebarsHelpers();

    this.contentId = attributes.contentId;
    this.localeId = attributes.localeId;
    this.status = attributes.status;
    this.notStatus = attributes.notStatus;
    this.filter = attributes.filter;
    this.infoOnly = attributes.infoOnly;
  },
  contentId: null,
  localeId: null,
  status: null,
  notStatus: null,
  infoOnly:false,
  filter: true,
  model: Release,
});

module.exports = Releases;
