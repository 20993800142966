'use strict';

let View,
  singleton;
const  template = require('templates/users'),
  account = require('models/account'),
  notificationEvent = require('NotificationEvent'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  User = require('models/user'),
  Users = require('models/users'),
  addTokenModalView = require('views/addTokenModalView'),
  errorHandler = require('utils/errorHandler');


View = Backbone.View.extend({

  initialize: function initialize () {
    this.initHandlebarsHelpers();
  },

  events: {
    'click .createUser': 'createUser',
    'click .displayImportUser': 'displayImportUser',
    'click .editCsv': 'editCsv',
    'click .downloadCsv': 'downloadCsv',
    'click .editUser': 'editUser',
    'click .deleteUser': 'deleteUser',
  },

  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  initHandlebarsHelpers: function initHandlebarsHelpers () {
    Handlebars.registerHelper('ifNotEq', function (value, otherValue, options) {
      if (value !== otherValue) {
        return options.fn(this);
      } 
        return options.inverse(this);
      
    });
  },

  createUser: function createUser () {
    const appRouter = require('router');
    appRouter.navigate('createUser', { trigger: true });
  },

  displayImportUser: function displayImportUser () {
    const self = this;
    addTokenModalView.showMe({ users: this.users, callback: $.proxy(() => self.retrieveData(self.users,true), this) });
  },

  editCsv: function editCsv () {
    const appRouter = require('router');
    appRouter.navigate('usersCsv', { trigger: true });
  },

  downloadCsv: function downloadCsv () {
    return Backbone.$.ajax({
      method: 'POST',
      url: '/users/export/csv',
      crossDomain: true,
      contentType: 'text/plain',
      success: function (response, status, request) {
        const disp = request.getResponseHeader('Content-Disposition');
        if (disp && disp.search('attachment') != -1) {
          const form = $('<form method="POST" action="/users/export/csv">');
          $('body').append(form);
          form.submit();
        }
      },
      error: function () {
      },
    });
  },

  editUser: function editUser (event) {
    const appRouter = require('router');
    const userData = $(event.currentTarget).data();
    const userHepta = userData.hepta;
    appRouter.navigate('users/' + userHepta, { trigger: true });
  },

  deleteUser: function deleteUser (event) {
    const userData = $(event.currentTarget).data();
    const userHepta = userData.hepta;
    const user = new User({ hepta: userHepta });
    const danger = true;
    Confirm.showMe('You are about to delete the user: "' + userHepta + '"', $.proxy(() => {
      Spinner.show();
      user.destroy({
        success: function () {
          Spinner.hide();
          $('li[id=' + userHepta + ']').remove();
          notificationEvent.notify('User ' + userHepta + ' was deleted.', 'success');
        },
        error: function () {
          Spinner.hide();
          notificationEvent.notify('Failed to delete ' + userHepta + '.', 'danger');
        },
      });
    }, this), $.proxy(() => {
    }, this), danger);
  },

  render: function render () {
    Spinner.hide();
    if (!account.isSuperAdmin()) {
      const appRouter = require('router');
      appRouter.navigate('welcome', { trigger: true });
      return;
    }

    const html = template({ users: this.model.toJSON() });
    this.$el.html(html);
    $('#main').append(this.$el);

    if (account.isSuperAdmin()) {
      $('.editUser').removeClass('hidden');
      $('.deleteUser').removeClass('hidden');
    }

    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (users, retry) {
    const self = this;
    users.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.model = model;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(users, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe () {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    const users = new Users();

    singleton.users = users;
    singleton.retrieveData = this.retrieveData;

    this.retrieveData(users, true);
    return singleton;
  },
});

module.exports = View;
