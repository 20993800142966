'use strict';

const _PERMISSION_MAP = {
    NONE: 0,
    READ: 1,
    WRITE: 2,
    PUBLISH: 3,
};

function _isUsedScope(scope) {
    return (scope.get('perm') > _PERMISSION_MAP.NONE);
}

function _isUsedScopeValidation(scope) {
    return (scope.perm > _PERMISSION_MAP.NONE);
}

const Scopes = Backbone.PageableCollection.extend({
    state: {
        pageSize: 15,
        sortKey: 'content',
        order: 1,
    },

    defaults: {
        allScopes: false,
    },

    initialize: function (model, options) {
        if (options.allScopes) {
            this.allScopes = options.allScopes;
        }
        if (options.hepta) {
            this.hepta = options.hepta;
        }
    },

    url: function () {
        if (this.allScopes) {
            return '/scopes';
        }
        return '/users/' + this.hepta + '/scopes';
    },

    resetPermissions: function () {
        this.fullCollection.models.forEach((model) => {
            model.set('perm', _PERMISSION_MAP.NONE);
        });
    },

    validation: function (profile) {
        if (profile !== 'CMS_USER') {
            return true;
        }
        const fullCollection = JSON.parse(JSON.stringify(this.fullCollection));
        const filteredFullCollection = fullCollection.filter(_isUsedScopeValidation);
        if (filteredFullCollection && filteredFullCollection.length) {
            return true;
        } 
            return false;
        
    },

    sync: function (method, model, options) {
        const fullScopes = this.models;

        if (options.allScopes) {
            return Backbone.sync.call(this, method, this, options);
        }

        switch (method) {
            case 'create':
            case 'update':
                this.reset(this.fullCollection.filter(_isUsedScope));
                Backbone.sync.call(this, method, this, options);
                this.reset(fullScopes);
                break;

            case 'delete':
                this.reset();
                Backbone.sync.call(this, 'update', this, options);
                this.reset(fullScopes);
                break;

            default:
                Backbone.sync.call(this, method, this, options);
                break;
        }
    },
});

module.exports = Scopes;
