const { getElementsReferenceNames } = require('./clubmedPagesContents');

const DCX_SHARED_COMMERCIAL_COMPONENTS_PROPERTY = 'sharedCommercialComponents';
const DCX_COMMERCIAL_ANIMATION_PROPERTY = 'commercialAnimation';

const getSharedCommercialComponentsReferenceNames = (components) => getElementsReferenceNames(components);

const getReleventSharedCommercialComponentsReferenceNames = ({ releaseValue, key = '' }) => {
  const sharedCommercialComponents = _.get(releaseValue, DCX_SHARED_COMMERCIAL_COMPONENTS_PROPERTY, []);
  
  const relevantComponents = key ? _.filter(sharedCommercialComponents, (item) => {
    return _.has(item, key);
  }) : sharedCommercialComponents;

  return getSharedCommercialComponentsReferenceNames(relevantComponents);
};

const getIsDescendantOfCommercialAnimation = (ref) => ref?.includes(DCX_COMMERCIAL_ANIMATION_PROPERTY);

module.exports = {
  getSharedCommercialComponentsReferenceNames,
  getReleventSharedCommercialComponentsReferenceNames,
  getIsDescendantOfCommercialAnimation,
};

