'use strict';

let View,
  singleton;
const Utils = require('utils/utils'),
  TreeItemTools = require('utils/TreeItemTools'),
  template = require('templates/treeVisualiser'),
  imageCropperView = require('views/imageCropperView');

View = Backbone.View.extend({
  
  events: {
    'click .js-imageCropper': 'displayImageCropper',
  },

  initialize: function () {
    const self = this;

    $(window).bind('scroll', (ev) => {
      self.scroll(ev);
    });
  },

  close: function () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  displayImageCropper: function () {
    if(!this.canPreview) {
      return;
    }
    imageCropperView.showMe({ imageSrc: this.imageSrc, element: this.element });
  },

  scroll: function (ev) {
    const scroll = $(window).scrollTop();
    if (scroll >= 75) {
      $('#tree-visualiser').addClass('tree-visualiser-sticky');
    } else {
      $('#tree-visualiser').removeClass('tree-visualiser-sticky');
    }
  },

  showVisualiser: function (value) {
    const extension = /[^.]+$/.exec(value);
    let imgSrc = value;
    if (value.indexOf('ns.clubmed.com') > -1) {
      imgSrc = 'https://media-server.clubmed.com/v2/image/' + encodeURIComponent(value) + '/290/2000/resize/80';
    }
    this.imageSrc = imgSrc;
    const html = template({
      imageSrc: imgSrc,
      extension: extension,
    });
    this.$el.html(html);
    $('#tree-visualiser').append(this.$el);

    // wait for image to load before allowing preview
    this.$('img').on('load', () => {
      this.canPreview = true;
    });

    $('#tree-visualiser').removeClass('tree-hidden');
  },

  showToolTip: function (value, release, node) {
    const props = TreeItemTools.getSchema(node, node.get('ref'), release);
    const isMarkdown = (props && props.metadata && props.metadata.markdown);
    const isRestricted = (props && props.metadata && props.metadata.restrictedAccess);
    const type = Utils.adaptName(props);
    let html = '';
    let name;
    for (const prop in props) {
      if (prop == 'type') {
        if (isMarkdown) {
          name = 'markdown text';
        } else {
          name = type;
        }
      } else {
        name = props[prop];
      }
      if (name != undefined && name != '' && prop.toLowerCase() != 'pattern' && prop.toLowerCase() != 'definitions' && prop.toLowerCase() != 'metadata') {
        html += '<b>' + prop.charAt(0).toUpperCase() + prop.substring(1).toLowerCase() + '</b> : ' + name + '<br/>';
      }
    }
    if (isRestricted) {
      html += '<div class=\'restricted-access-warning\'>WARNING: this value should not be changed!</div><br/>';
    }
    if (html != '') {
      $('#tree-visualiser').removeClass('tree-hidden');
      this.$el.html(html);
      $('#tree-visualiser').append(this.$el);
    }
  },

  hideVisualiser: function () {
    $('#tree-visualiser').addClass('tree-hidden');
  },

  render: function () {
    this.on('focusField', function (element, release, node) {
      if (element.prop('tagName') == 'INPUT') {
        this.showToolTip(element, release, node);
        
      } else {
        if (!(/\.(gif|GIF|jpg|JPG|jpeg|JPEG|tiff|TIFF|png|PNG)$/i).test(element.val())) {
          this.showToolTip(element, release, node);
          return;
        }
        setTimeout(() => {
          this.showVisualiser(element.val());
          this.element = element;
        }, 150);
      }
    }, this);
    this.on('blurField', () => {
      setTimeout(() => { this.hideVisualiser(); }, 100);
    }, this);
    return this;
  },
}, {
  showMe: function () {
    if (!singleton) {
      singleton = new View();
    }
    return singleton.render();
  },
});

module.exports = View;
