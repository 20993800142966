'use strict';

const Utils = {

  /**
   * Faster than _.cloneDeep but will not keep: Dates, functions, undefined, Infinity, RegExps,
   *  Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types
   **/
  deepClone: function deepClone(object) {
    if (this.isNotEmptyObject(object)) {
      return JSON.parse(JSON.stringify(object));
    }
    return object;
  },

  guid: function guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  },

  s4: function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  },

  htmlDecode: function htmlDecode(value) {
    if (value) {
      return $('<div/>').html(value).text();
    } 
      return '';
    
  },

  INTERNAL_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',

  formatDate: function formatDate(date) {
    if (date && date != '') {
      return moment(date, this.INTERNAL_DATE_FORMAT).format('DD MMMM, YYYY HH:mm:ss');
    } 
      return '';
    
  },

  escapeRegExp: function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  },

  replaceAll: function replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  },

  naturalSort: function naturalSort(a, b) {
    const ax = [];
    const bx = [];

    a.replace(/[ ]/g, '/').replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/[ ]/g, '/').replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
      if (nn) {return nn;}
    }

    return ax.length - bx.length;
  },

  getDuplicates: function getDuplicates(array) {
    const duplicates = {};
    for (let i = 0; i < array.length; i++) {
      if (duplicates.hasOwnProperty(array[i])) {
        duplicates[array[i]].push(i);
      } else if (array.lastIndexOf(array[i]) !== i) {
        duplicates[array[i]] = [i];
      }
    }
    return duplicates;
  },

  isDateInPast: function isDateInPast(date) {
    const paramDate = moment(date, 'YYYY-MM-DDTHH:mm:ssZZ');
    const nowDate = moment().add(30, 'seconds');
    return paramDate.isBefore(nowDate);
  },

  adaptName: function adaptName(object) {
    if (!object || !object.type || Array.isArray(object.type)) {
      return undefined;
    }
    switch (object.type.toLowerCase()) {
      case 'string':
        return 'text';
      case 'float':
      case 'integer':
        return 'number';
      case 'bool':
      case 'boolean':
        return 'true or false';
      default:
        return object.type;
    }
  },

  isNormalInteger: function isNormalInteger(str) {
    return /^\+?\d+$/.test(str);
  },

  isNotEmpty: function isNotEmpty(item) {
    return this.isNotEmptyObject(item) || this.isNotEmptyString(item);
  },

  isNotEmptyObject: function isNotEmptyObject(item) {
    return _.isObject(item) && !_.isEmpty(item);
  },

  isNotEmptyArray: function isNotEmptyArray(item) {
    return _.isObject(item) && !_.isEmpty(item) && _.isArray(item);
  },

  isNotEmptyString: function isNotEmptyString(item) {
    return _.isString(item) && !_.isEmpty(item);
  },

  JSON_PATH_SEPARATOR: '.',
  JSON_POINTER_SEPARATOR: '/',

  cleanPath: function cleanPath(path) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    let newPath = _.clone(path);
    newPath = this._convertIndexesToProperties(newPath);
    newPath = this._stripLeadingDot(newPath);
    newPath = this._stripFinalDot(newPath);
    return newPath;
  },

  _stripLeadingDot: function _stripLeadingDot(path) {
    return (this.isNotEmptyString(path)) ? path.replace(/^\./, '') : path;
  },

  _stripFinalDot: function _stripFinalDot(path) {
    return (this.isNotEmptyString(path)) ? path.replace(/\.$/, '') : path;
  },

  _convertIndexesToProperties: function _convertIndexesToProperties(path) {
    const bracketsAndContentRegex = /\[(\d+)\]/g;
    return (this.isNotEmptyString(path)) ? path.replace(bracketsAndContentRegex, '.$1') : path;
  },

  cleanJsonPointerPath: function cleanJsonPointerPath(path) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    let newPath = _.clone(path);
    newPath = this._convertJsonPointerIndexesToProperties(newPath);
    newPath = this._convertSlashesToPoints(newPath);
    newPath = this._stripLeadingDot(newPath);
    newPath = this._stripFinalDot(newPath);
    return newPath;
  },

  _convertJsonPointerIndexesToProperties: function _convertJsonPointerIndexesToProperties(path) {
    const slashesAndContentRegex = /\/(\d+)\//g;
    return (this.isNotEmptyString(path)) ? path.replace(slashesAndContentRegex, '.$1.') : path;
  },

  _convertSlashesToPoints: function _convertSlashesToPoints(path) {
    const slashesRegex = /\//g;
    return (this.isNotEmptyString(path)) ? path.replace(slashesRegex, this.JSON_PATH_SEPARATOR) : path;
  },

  getParentPath: function getParentPath(path, separator) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    if (!separator) {
      separator = this.JSON_PATH_SEPARATOR;
    }
    const splittedPath = this.cleanPath(path).split(separator);
    return splittedPath.slice(0, -1).join(separator);
  },

  getFirstArrayPath: function getFirstArrayPath(path) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    const firstDigitIndex = path.search(/\d/);
    if (firstDigitIndex === -1) {
      return path;
    } 
      return path.substring(0, firstDigitIndex - 1); // substracting 1 for dot character

    
  },

  getFirstPathPart: function getFirstPathPart(path, separator) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    if (!separator) {
      separator = this.JSON_PATH_SEPARATOR;
    }
    const splittedPath = this.cleanPath(path).split(separator);
    return splittedPath.shift();
  },

  getLastPathPart: function getLastPathPart(path, separator) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    if (!separator) {
      separator = this.JSON_PATH_SEPARATOR;
    }
    const splittedPath = this.cleanPath(path).split(separator);
    return splittedPath.pop();
  },

  pathContainsElement: function pathContainsElement(path, element, separator) {
    if (!this.isNotEmptyString(path)) {
      return false;
    }
    if (!separator) {
      separator = this.JSON_PATH_SEPARATOR;
    }
    const splittedPath = this.cleanPath(path).split(separator);
    return splittedPath.indexOf(element) >= 0;
  },

  lastPartIsIndex: function lastPartIsIndex(path, separator) {
    if (!this.isNotEmptyString(path)) {
      return false;
    }
    if (!separator) {
      separator = this.JSON_PATH_SEPARATOR;
    }
    const lastPathPart = this.getLastPathPart(path, separator);
    return this.isNormalInteger(lastPathPart);
  },

  pathContainsArray: function pathContainsArray(path) {
    if (path) {
      if (path !== this.getFirstArrayPath(path)) {
        return true;
      }
    }
    return false;
  },

  isPathRecursiveArrays: function isPathRecursiveArrays(path, separator) {
    if (path && this.pathContainsArray(path)) {
      const currentArrayRef = this.getLastPathPart(path, separator);
      const firstArrayRef = this.getLastPathPart(this.getFirstArrayPath(path), separator);
      if (currentArrayRef === firstArrayRef) {
        return true;
      }
    }
    return false;
  },

  isArrayElementPath: function isArrayElementPath(path, separator) {
    return _.isInteger(parseInt(this.getLastPathPart(path, separator), 10));
  },

  formatToJsonPath: function formatToJsonPath(path) {
    if (!this.isNotEmptyString(path)) {
      return path;
    }
    let output = _.clone(path);
    output = decodeURIComponent(output);
    if (output.startsWith(this.JSON_POINTER_SEPARATOR)) {
      output = output.substring(1);
    }
    return (output.indexOf(this.JSON_POINTER_SEPARATOR) >= 0) ? output.split(this.JSON_POINTER_SEPARATOR).join(this.JSON_PATH_SEPARATOR) : output;
  },

  formatToJsonPointer: function formatToJsonPointer(path) {
    if (!this.isNotEmptyString(path)) {
      return this.JSON_POINTER_SEPARATOR;
    }
    let output = _.clone(path);
    output = decodeURIComponent(output);
    if (output.startsWith(this.JSON_PATH_SEPARATOR)) {
      output = output.substring(1);
    }
    output = (output.indexOf(this.JSON_PATH_SEPARATOR) >= 0) ? output.split(this.JSON_PATH_SEPARATOR).join(this.JSON_POINTER_SEPARATOR) : output;
    return (!output.startsWith(this.JSON_POINTER_SEPARATOR)) ? this.JSON_POINTER_SEPARATOR + output : output;
  },

  padNumber: function padNumber(number) {
    return (number < 10 ? '0' : '') + number;
  },

  padThreeDigitNumber: function padThreeDigitNumber(number) {
    if (number < 10) {
      return '00' + number;
    }
    if (number < 100) {
      return '0' + number;
    }
    return number;
  },

  getTimestamp: function getTimestamp() {
    const now = new Date();
    return now.getFullYear() +
      this.padNumber(now.getMonth() + 1) +
      this.padNumber(now.getDate()) +
      this.padNumber(now.getHours()) +
      this.padNumber(now.getMinutes()) +
      this.padNumber(now.getSeconds()) +
      this.padThreeDigitNumber(now.getMilliseconds());
  },

};
module.exports = Utils;
