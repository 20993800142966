'use strict';

let View,
  singleton;
const  template = require('templates/editTags'),
  notificationEvent = require('NotificationEvent'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  Utils = require('utils/utils'),
  TagsUtils = require('utils/TagsUtils'),
  Release = require('models/release'),
  State = require('../State');

View = Backbone.View.extend({

  events: {
    'click #button-back': 'previousPage',
    'click #button-back-to': 'previousView',
  },

  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  previousPage: function previousPage () {
    const appRouter = require('router');
    appRouter.navigate('contents/' + this.contentId + '/locales/' + this.localeId + '/releases/' + this.releaseId, { trigger: true });
  },
  previousView: function previousView() {
    State.goToLastView();
  },
  bindSaveButtons: function bindSaveButtons () {
    this.$el.find('.removeTags').off().click($.proxy(function () {
      this.updateRelease('removeTags');
    }, this));

    this.$el.find('.disableElements').off().click($.proxy(function () {
      this.updateRelease('disable');
    }, this));

    this.$el.find('.enableElements').off().click($.proxy(function () {
      this.updateRelease('enable');
    }, this));

    this.$el.find('.editTags').off().click($.proxy(function () {
      if (this.currentTags === TagsUtils.UNTAGGED_TAGS_STRING) {
        this.updateRelease('addTags');
      } else {
        this.updateRelease('editTags');
      }
    }, this));

    this.$el.find('.addSubTag').off().click($.proxy(function () {
      this.updateRelease('addSubTag');
    }, this));
  },

  initialize: function initialize () {
    this.initHandlebarsHelpers();
  },

  initHandlebarsHelpers: function initHandlebarsHelpers () {
    Handlebars.registerHelper('formatId', (value) => {
      return '#' + value.substring(value.length - 4, value.length).toUpperCase();
    });

    Handlebars.registerHelper('ifEq', function (value, otherValue, options) {
      if (value === otherValue) {
        return options.fn(this);
      } 
        return options.inverse(this);
      
    });

    Handlebars.registerHelper('ifExists', function (value, options) {
      if (value) {
        return options.fn(this);
      } 
        return options.inverse(this);
      
    });
  },

  initModel: function initModel (release, tags) {
    release.resetJsonPatch();
    this.tagsProperty = Utils.getLastPathPart(TagsUtils.getTaggedElementsRootPath(release, false, this.selectedCategory), '.');
    const splittedTags = tags.split(',');
    this.mainTag = (splittedTags.length >= 1) ? splittedTags[0] : '';
    this.mainTagStr = (splittedTags.length >= 1) ? decodeURIComponent(splittedTags[0]) : '';
    this.subTag = (splittedTags.length >= 2) ? splittedTags[1] : '';
    this.subTagStr = (splittedTags.length >= 2) ? decodeURIComponent(splittedTags[1]) : '';

    return {
      release: release.toJSON(),
      tags: tags,
      releaseTags: TagsUtils.getDecodedTags(this.currentTags, ' > '),
      tagsProperty: this.tagsProperty,
      mainTag: this.mainTag,
      mainTagStr: this.mainTagStr,
      subTag: this.subTag,
      subTagStr: this.subTagStr,
    };
  },

  updateRelease: function updateRelease (jsonPatchType) {
    if ((jsonPatchType === 'editTags' || jsonPatchType === 'addTags') && !this.validateTags()) {
      return;
    }
    if (jsonPatchType === 'addSubTag' && !this.validateSubTag()) {
      return;
    }
    try {
      let confirmMsg = '';
      let confirmDanger = false;
      let  notificationMsg = '';
      const mainTagString = 'main tag [' + this.mainTagStr + ']';
      let  tagString = (this.subTagStr) ? 'sub tag [' + this.subTagStr + '] from ' + mainTagString : mainTagString;
      switch (jsonPatchType) {
        case 'removeTags':
          confirmDanger = true;
          if (!this.subTagStr) {
            tagString += ' and all of its sub tags';
          }
          confirmMsg = 'You are about to delete the ' + tagString + ' which will also be removed from all corresponding ' + this.tagsProperty + ' that will become untagged';
          notificationMsg = 'Removing tags from ' + this.tagsProperty + '...';
          break;
        case 'disable':
          confirmMsg = 'You are about to disable all ' + this.tagsProperty + ' with the following ' + tagString;
          notificationMsg = 'Disabling ' + this.tagsProperty + '...';
          break;
        case 'enable':
          confirmMsg = 'You are about to enable all ' + this.tagsProperty + ' with the following ' + tagString;
          notificationMsg = 'Enabling ' + this.tagsProperty + '...';
          break;
        case 'addTags':
          confirmMsg = 'You are about to add the following tags [' + TagsUtils.getDecodedTags(this.newTags) + '] to all untagged ' + this.tagsProperty;
          notificationMsg = 'Adding tags to the untagged ' + this.tagsProperty + '...';
          break;
        case 'addSubTag':
          confirmMsg = 'You are about to add the following sub tag [' + TagsUtils.getDecodedTags(this.newSubTag) + '] to the existing main tag [' + TagsUtils.getDecodedTags(this.currentTags) + ']';
          break;
        case 'editTags':
          confirmMsg = 'You are about to replace the existing tags [' + TagsUtils.getDecodedTags(this.currentTags) + '] with new ones [' + TagsUtils.getDecodedTags(this.newTags) + '] for all corresponding ' + this.tagsProperty;
          notificationMsg = 'Editing ' + this.tagsProperty + '...';
          break;
        default:
          return;
      }
      const self = this;
      Confirm.showMe(confirmMsg, $.proxy(() => {
        self.release.concurrencyCheck().done(() => {
          Spinner.show();
          if (notificationMsg) {
            notificationEvent.notify(notificationMsg, 'warning');
          }
          setTimeout(() => {
            if (jsonPatchType !== 'addSubTag') {
              const unfilteredReleaseValue = _.cloneDeep(self.release.get('value'));
              self.release = TagsUtils.tagsFiltering(self.release, self.currentTags, false, self.selectedCategory);
              self.pushTagsFilteredOperations(self.release, unfilteredReleaseValue, jsonPatchType);
            }
            let  operation = '';
            let  oldTags = '';
            switch (jsonPatchType) {
              case 'addSubTag':
                operation = 'add';
                break;
              case 'addTags':
              case 'editTags':
                operation = 'replace';
                oldTags = self.currentTags;
                break;
              case 'removeTags':
                operation = 'remove';
                oldTags = self.currentTags;
              default:
                break;
            }
            if (operation) {
              self.release = TagsUtils.updateTagsList({
                release: self.release,
                newTags: _.clone(self.newTags),
                operation: operation,
                oldTags: oldTags,
              }, self.selectedCategory);
            }
            self.saveRelease(jsonPatchType, true);
          });
        }, 500);
      }, this), $.proxy(() => {
      }, this), confirmDanger);
    } catch (error) {
      notificationEvent.notify(error.message, 'danger');
    }
  },

  saveRelease: function saveRelease (jsonPatchType, retry) {
    const self = this;
    Spinner.show();
    self.release.save(self.release.formatDraftForPatch(), {
      patch: true,
      success: function () {
        Spinner.hide();
        notificationEvent.notify('Draft saved', 'success');
        const tagsListModificationsSaved = true;
        self.release.resetJsonPatch(tagsListModificationsSaved);
        switch (jsonPatchType) {
          case 'removeTags':
            var  appRouter = require('router');
            appRouter.navigate('contents/' + self.contentId + '/locales/' + self.localeId + '/releases/' + self.releaseId, { trigger: true });
            break;
          case 'addTags':
          case 'editTags':
            var  appRouter = require('router');
            appRouter.navigate('contents/' + self.contentId + '/locales/' + self.localeId + '/releases/' + self.releaseId + '/editTags/' + self.newTags, { trigger: true });
            break;
          default:
            self.close();
            self.render();
            break;
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.saveRelease(jsonPatchType, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  validateTags: function validateTags () {
    const $mainTagInput = $('#release-main-tag');
    const mainTagStr = $mainTagInput.val().trim();
    const $subTagInput = $('#release-sub-tag');
    const subTagStr = $subTagInput.val().trim();
    if (subTagStr) {
      if (!mainTagStr) {
        notificationEvent.notify('Main tag cannot be empty if there is a sub tag', 'danger');
        return false;
      } else if (!/^[a-zA-Z0-9\-\s]+$/.test(subTagStr)) {
        notificationEvent.notify('Sub tag can only contain "-" special character', 'danger');
        return false;
      }
    }
    if (mainTagStr) {
      if (!/^[a-zA-Z0-9\-\s]+$/.test(mainTagStr)) {
        notificationEvent.notify('Main tag can only contain "-" special character', 'danger');
        return false;
      }
    }
    const mainTag = encodeURIComponent(mainTagStr.toUpperCase());
    const subTag = encodeURIComponent(subTagStr);
    this.newTags = (mainTag && subTag) ? [mainTag, subTag].join(',') : mainTag;
    if (this.newTags === this.currentTags) {
      notificationEvent.notify('New tags must be different than current tags', 'danger');
      return false;
    }
    return true;
  },

  validateSubTag: function validateSubTag () {
    const $newSubTagInput = $('#release-new-sub-tag');
    const newSubTagStr = $newSubTagInput.val().trim();
    if (!newSubTagStr) {
      notificationEvent.notify('Please enter new sub tag', 'danger');
      return false;
    } 
      if (newSubTagStr.indexOf(',') >= 0) {
        notificationEvent.notify('New sub tag cannot contain commas', 'danger');
        return false;
      }
    
    this.newSubTag = encodeURIComponent(newSubTagStr);
    this.newTags = [this.currentTags, this.newSubTag].join(',');
    return true;
  },

  pushTagsFilteredOperations: function pushTagsFilteredOperations (release, unfilteredReleaseValue, jsonPatchType) {
    const tagsMetadata = release.get('tagsMetadata');
    tagsMetadata.filteredIndexesMap.map(function (unfilteredIndex, currentIndex) {
      const unfilteredPath = [tagsMetadata.taggedElementsRootPath, unfilteredIndex].join('.');
      const currentPath = [tagsMetadata.taggedElementsRootPath, currentIndex].join('.');
      const element = _.get(unfilteredReleaseValue, unfilteredPath, {});
      if (Utils.isNotEmptyObject(element)) {
        switch (jsonPatchType) {
          case 'removeTags':
            return this.pushRemoveTagsOperation(release, element, currentPath);
          case 'disable':
            return this.pushDisableOperation(release, element, currentPath);
          case 'enable':
            return this.pushEnableOperation(release, element, currentPath);
          case 'addTags':
          case 'editTags':
            return this.pushEditTagsOperation(release, element, currentPath);
          default:
            return null;
        }
      }
      return null;
    }, this);
    return release;
  },

  pushRemoveTagsOperation: function pushRemoveTagsOperation (release, element, path) {
    const operationPath = [path, TagsUtils.TAGS_JSON_PATH].join('.');
    if (_.has(element, TagsUtils.TAGS_JSON_PATH)) {
      return release.pushOperation(false, 'replace', operationPath, '');
    }
    return release.pushOperation(false, 'add', operationPath, '');
  },

  pushDisableOperation: function pushDisableOperation (release, element, path) {
    return release.pushOperation(false, 'add', path + '.@metadata.disabled', true);
  },

  pushEnableOperation: function pushEnableOperation (release, element, path) {
    return release.pushOperation(false, 'remove', path + '.@metadata.disabled');
  },

  pushEditTagsOperation: function pushEditTagsOperation (release, element, path) {
    const operationPath = [path, TagsUtils.TAGS_JSON_PATH].join('.');
    if (this.currentTags && this.currentTags.split(',').length === 1) { // edit only main tag
      const newMainTag = this.newTags;
      const existingTagsArray = _.get(element, TagsUtils.TAGS_JSON_PATH, '').split(',');
      existingTagsArray[0] = newMainTag;
      if (_.has(element, TagsUtils.TAGS_JSON_PATH)) {
        return release.pushOperation(false, 'replace', operationPath, existingTagsArray.join(','));
      }
      return release.pushOperation(false, 'add', operationPath, existingTagsArray.join(','));
    } 
      if (_.has(element, TagsUtils.TAGS_JSON_PATH)) {
        return release.pushOperation(false, 'replace', operationPath, this.newTags);
      }
      return release.pushOperation(false, 'add', operationPath, this.newTags);
    
  },

  render: function render () {
    Spinner.hide();
    const templateModel = this.initModel(this.release, this.currentTags);
    const html = template(templateModel);
    this.$el.html(html);
    $('#main').append(this.$el);
    if (this.currentTags && (this.currentTags === TagsUtils.UNTAGGED_TAGS_STRING || this.currentTags.split(',').length > 1)) {
      $('.hidden').removeClass('hidden');
      $('#js-addTag-sub-tag').addClass('hidden');
      $('#button-tags-add-sub-tag').addClass('hidden');
    }
    this.bindSaveButtons();
    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (release, retry) {
    const self = this;
    release.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.release = model;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(release, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe (contentId, localeId, releaseId, tags, selectedCategory) {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    singleton.contentId = contentId;
    singleton.localeId = localeId;
    singleton.releaseId = releaseId;
    singleton.selectedCategory = selectedCategory;

    if (tags) {
      let  tagsArray = tags.split(',');
      tagsArray = tagsArray.map((tag) => {
        while (tag.indexOf('%') > -1) {
          tag = decodeURIComponent(tag);
        }
        return encodeURIComponent(tag);
      });
      tags = tagsArray.join(',');
    }

    singleton.currentTags = _.clone(tags);
    singleton.newTags = _.clone(tags);
    singleton.newSubTag = '';

    const release = new Release({
      content_id: contentId,
      locale: localeId,
      id: releaseId,
    });
    this.retrieveData(release, true);
    return singleton;
  },
});

module.exports = View;
