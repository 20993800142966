'use strict';

var View,
    singleton,
    token,
    template = require('templates/addTokenModal'),
    userImportModalView = require('views/userImportModalView'),
    Spinner = require('Spinner');

View = Backbone.View.extend({
        events: {
            'click .js-addToken': 'addToken',
        },

        close: function close() {
            this.$el.remove();
            this.off();
            if (this.model) {
                this.model.off(null, null, this);
            }
        },

        addToken: function (event) {
            token = $('.js-addToken-field').val();
            const cleanToken = this.validateAndCleanToken(token);
            if(cleanToken) {
                $('#modal-addToken').modal('hide');
                userImportModalView.showMe({ token: cleanToken, callback: this.callback });
            } else {
                this.$('.js-addToken-error').text('Please insert a valid token');
            }
        },

        validateAndCleanToken: function (token) {
            if (token && token.startsWith('Bearer ')) {
                return token.replace('Bearer ', '');
            }
            return token;
        },

        render: function () {
            Spinner.hide();
            var html = template();
            this.$el.html(html);

            $('#modal-addToken').append(this.$el);
            this.$el.parent().modal('toggle');
            this.delegateEvents();
            return this;
        },
    },
    {
        showMe: function showMe({ callback }) {
            Spinner.show();
            if (!singleton) {
                singleton = new View();
            } else {
                singleton.close();
            }
            singleton.callback = callback;
            singleton.render();
            return singleton;
        },
    });

module.exports = View;

