let View,
  singleton;
const template = require('templates/releases'),
  Releases = require('models/releases'),
  Release = require('models/release'),
  notificationEvent = require('NotificationEvent'),
  Content = require('models/content'),
  account = require('models/account'),
  LocalesView = require('views/localesModalView'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  Utils = require('utils/utils'),
  FilterOnEvent = require('FilterOnEvent'),
  State = require('../State');

View = Backbone.View.extend({

  initialize: function initialize() {
    this.initHandlebarsHelpers();
  },

  events: {
    'click .js-locales-link': 'displayLocalesModal',
    'click #button-back': 'previousPage',
    'click #button-back-to': 'previousView',
    'click .clickable-row': 'detailRelease',
    'click .releases': 'liveDraftReleases',
    'click .allArchived': 'allArchivedReleases',
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  initHandlebarsHelpers: function initHandlebarsHelpers() {
    Handlebars.registerHelper('formatDate', Utils.formatDate);
    Handlebars.registerHelper('formatId', (value) => {
      return (value) ? '#' + value.substring(value.length - 4, value.length).toUpperCase() : '';
    });
    Handlebars.registerHelper('releaseDateMsg', this.getReleaseDateMsg);
  },

  displayLocalesModal: function displayLocalesModal() {
    LocalesView.showMe(this.content.locales, (contentId, localeId) => {
      const appRouter = require('router');
      appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases', { trigger: true });
    });
  },

  previousPage: function previousPage() {
    window.history.back();
  },
  previousView: function previousView() {
    State.goToLastView();
  },
  getReleaseDateMsg: function getReleaseDateMsg(release) {
    let dateMsg = '';
    if (release.publish_date) {
      dateMsg = Utils.formatDate(release.publish_date);
    } else if (release.metadata && release.metadata.publication_programming_date) {
      const formatedProgrammedDate = Utils.formatDate(release.metadata.publication_programming_date);
      if (Utils.isDateInPast(release.metadata.publication_programming_date)) {
        dateMsg = '<span style="color:red"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error! The publish failed on: ' + formatedProgrammedDate + '</span>';
      } else if (release.metadata.publication_programming_date_already_used) {
        dateMsg = '<span style="color:red"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error! Date already used: ' + formatedProgrammedDate + '</span>';
      } else if (release.value_validation_errors && release.value_validation_errors.length) {
        dateMsg = '<span style="color:red"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error! Draft has validation errors: ' + formatedProgrammedDate + '</span>';
      } else {
        dateMsg = 'Publication programmed on: ' + formatedProgrammedDate;
        dateMsg = '<span style="color:green" class="cms-dashboard-content-programmedDate"><span class="glyphicon glyphicon-time"></span> ' + dateMsg + '</span>';
      }
    } else {
      dateMsg = '';
    }
    return dateMsg;
  },

  sortByPublishDateDesc: function sortByPublishDateDesc(a, b) {
    return moment(a.publish_date).valueOf() < moment(b.publish_date).valueOf() ? 1 : -1;
  },

  setIsRollbackableOnReleases: function setIsRollbackableOnReleases() {
    this.model.each((release) => {
      const publishAllowed = account.isAllowed(release.get('content_id'), release.get('locale'), 'PUBLISH');
      release.set('isRollbackable', release.get('status') === 'archived' && publishAllowed);
    });
  },


  navigateTo: function navigateTo(contentId, localeId, releaseId) {
    const appRouter = require('router');
    appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases/' + releaseId, { trigger: true });
  },

  detailRelease: function detailRelease(event) {
    const releaseData = $(event.currentTarget).data();
    this.navigateTo(releaseData.contentId, releaseData.localeId, releaseData.releaseId);
  },

  liveDraftReleases: function liveDraftReleases() {
    const appRouter = require('router');
    appRouter.navigate('contents/' + this.model.contentId + '/locales/' + this.model.localeId + '/releases', { trigger: true });
  },

  allArchivedReleases: function allArchivedReleases() {
    const appRouter = require('router');
    appRouter.navigate('contents/' + this.model.contentId + '/locales/' + this.model.localeId + '/allArchived', { trigger: true });
  },

  rollbackRelease: function rollbackRelease(event, retry) {
    const self = this;
    Spinner.show();
    const releaseData = $(event.currentTarget).data();
    const content_id = releaseData.contentId;
    const locale_id = releaseData.localeId;
    const release_id = releaseData.releaseId;
    const previousStatus = releaseData.status;

    const release = new Release({
      id: release_id,
      content_id: content_id,
      locale: locale_id,
    });
    release.fetch({
      success: function(release) {
        const jsonRelease = release.formatDraftForPatch({
          last_update_user: account.get('name') || '',
          isPublish: true,
          isRollback: true,
        });
        release.save(jsonRelease, {
          patch: true,
          success: function() {
            Spinner.hide();
            const appRouter = require('router');
            appRouter.navigate('contents/' + content_id + '/locales/' + locale_id + '/releases', { trigger: true });
            notificationEvent.notify('Release re-published!', 'success');
          },
          error: function(model, error) {
            errorHandler({
              error: error,
              retry: retry,
              success: function() {
                if (retry) {
                  release.set('status', previousStatus);
                  self.rollbackRelease(event, false);
                }
              },
              fail: function() {
                FilterOnEvent.trigger('iniErrors');
                release.set('status', previousStatus);
              },
            });
          },
        });
      },
      error: function(model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function() {
            if (retry) {
              self.rollbackRelease(event, false);
            }
          },
          fail: function() {
          },
        });
      },
    });
  },

  render: function render() {
    Spinner.hide();
    this.setIsRollbackableOnReleases();
    var releases = this.model.toJSON().sort(this.sortByPublishDateDesc);
    releases = releases.map((release) => {
      release['pageCountUsedForDiff'] = release.pageCountOfPreviousLive;
      release['pageCountDiff'] = release.pageCount - release.pageCountOfPreviousLive;
      return release;
    });
    const html = template({
      content_id: this.releasesOptions.contentId,
      locale_id: this.releasesOptions.localeId,
      releases: releases,
    });
    this.$el.html(html);
    $('#main').append(this.$el);
    this.delegateEvents();
    this.$el.find('#create-new-release').addClass('hidden');
    if (!this.releasesOptions.filter) {
      this.$el.find('#showAllArchived').remove();
    }
    this.$el.find('.button-publish-release').off().click($.proxy(function(event) {
      event.preventDefault();
      event.stopPropagation();
      Confirm.showMe('You are about to re-publish this release.', $.proxy(function() {
        this.rollbackRelease(event, true);
      }, this), $.proxy(() => {
      }, this));
    }, this));

    return this;
  },
}, {
  retrieveData: function retrieveData(content, releases, retry) {
    const self = this;
    const promises = [content.fetch(), releases.fetch()];
    // when all of them are complete...
    $.when.apply($, promises).done(() => {
      Spinner.hide();
      const appRouter = require('router');
      if (!appRouter.activeView || appRouter.activeView == singleton) {
        singleton.model = releases;
        singleton.content = content;
        singleton.render();
      }
    }).fail((error) => {
      errorHandler({
        error: error,
        retry: retry,
        success: function() {
          if (retry) {
            self.retrieveData(content, releases, false);
          }
        },
        fail: function() {
        },
      });
    });
  },

  showMe: function showMe(contentId, localeId, filter) {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    const content = new Content({ id: contentId });
    singleton.releasesOptions = {
      contentId: contentId,
      localeId: localeId,
      status: 'archived',
      filter: filter,
    };
    const releases = new Releases(singleton.releasesOptions);
    this.retrieveData(content, releases, true);
    return singleton;
  },
});

module.exports = View;
