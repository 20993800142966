'use strict';

let View,
  singleton;
const  template = require('templates/importContent'),
  ImportContent = require('models/importContent'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  notificationEvent = require('NotificationEvent');

View = Backbone.View.extend({

  events: {
    'click .js-locales-deselect-all': 'deselectAllLocales',
    'click .js-locales-select-all': 'selectAllLocales',
    'click .clickable-row': 'toggleLocale',
    'click .js-showLocales': 'loadLocaleList',
    'click .js-importContent': 'importContent',
  },

  initialize: function initialize (importContent) {
    if (importContent) {
      if (!singleton) {
        singleton = this;
      }
      singleton.importContent = importContent;
    }
  },

  loadLocaleList: function loadLocaleList (event, noRetry) {
    const self = this;
    const selectContent = $('select[name=selectContent]');
    const contentId = (selectContent) ? selectContent.val() : '';
    if (contentId) {
      Spinner.show();
      self._disableBtn();
      notificationEvent.notify('Loading content\'s locale list... Please wait.', 'warning');
      singleton.importContent = new ImportContent({
        token: singleton.importContent.token,
        contents: singleton.importContent.contents,
        contentId: contentId,
        includeDraft: $('#input-include-draft').prop('checked'),
        includeArchived: $('#input-include-archived').prop('checked'),
      });
      singleton.importContent.fetch({
        success: function (model) {
          Spinner.hide();
          self._enableBtn();
          singleton.importContent.locales = model.toJSON();
          singleton.render();
        },
        error: function (model, error) {
          errorHandler({
            error: error,
            retry: !noRetry,
            success: function () {
              if (!noRetry) {
                self.loadLocaleList(event, true);
              }
            },
            fail: function () {
            },
          });
        },
      });
    } else {
      notificationEvent.notify('Please select a Content.', 'danger');
    }
  },

  deselectAllLocales: function deselectAllLocales () {
    $('input:checkbox[name=selectLocale]').prop('checked', false);
  },

  selectAllLocales: function selectAllLocales () {
    $('input:checkbox[name=selectLocale]').prop('checked', true);
  },

  toggleLocale: function toggleLocale (event) {
    const localeData = $(event.currentTarget).data();
    const targetId = '#' + event.target.id;
    const { localeId } = localeData;
    const inputId = '#input-locales-select--' + localeId;
    if (targetId !== inputId) {
      if ($(inputId).prop('checked')) {
        $(inputId).prop('checked', false);
      } else {
        $(inputId).prop('checked', true);
      }
    }
  },

  importContent: function importContent () {
    const selectContent = $('select[name=selectContent]');
    const contentId = (selectContent) ? selectContent.val() : '';
    if (contentId) {
      singleton.importContent.contentId = contentId;
      singleton.importContent.includeDraft = $('#input-include-draft').prop('checked');
      singleton.importContent.includeArchived = $('#input-include-archived').prop('checked');
      singleton.importContent.allLocales = false;
      if (singleton.importContent.locales) {
        const selectedLocales = [];
        $('input:checkbox[name=selectLocale]:checked').each(function () {
          selectedLocales.push($(this).val());
        });
        if (selectedLocales.length > 0) {
          singleton.importContent.selectedLocales = selectedLocales;
          const allLocales = (selectedLocales.length === singleton.importContent.locales.length);
          singleton.importContent.allLocales = allLocales;

          if (allLocales) {
            Confirm.showMe('You are about to import the Content [' + contentId + '] with ALL of its locales + its schema & default value.', $.proxy(function () {
              return this.startImport(true);
            }, this), $.proxy(() => {
            }, this));
          } else {
            Confirm.showMe('You are about to import the Content [' + contentId + '] with ' + singleton.importContent.selectedLocales.length + ' out of ' + singleton.importContent.locales.length + ' of its locales (WITHOUT its schema & default value).', $.proxy(function () {
              return this.startImport(true);
            }, this), $.proxy(() => {
            }, this));
          }
          return;
        }
        notificationEvent.notify('Please select at least one locale.', 'danger');
        return;
      }
      singleton.importContent.allLocales = true;
      Confirm.showMe('You are about to import the Content [' + contentId + '] with ALL of its locales + its schema & default value.', $.proxy(function () {
        return this.startImport(true);
      }, this), $.proxy(() => {
      }, this));
      return;
    }
    notificationEvent.notify('Please select a Content.', 'danger');
    
  },

  startImport: function startImport (retry) {
    const self = this;
    Spinner.show();
    self._disableBtn();
    notificationEvent.notify('Import in progress... Please wait.', 'warning');
    const importContent = new ImportContent({
      token: singleton.importContent.token,
      contentId: singleton.importContent.contentId,
      includeDraft: singleton.importContent.includeDraft,
      includeArchived: singleton.importContent.includeArchived,
      allLocales: singleton.importContent.allLocales,
      locales: singleton.importContent.selectedLocales,
    });
    importContent.fetch({
      success: function () {
        Spinner.hide();
        singleton = null;
        notificationEvent.notify('Import success!', 'success');
        const appRouter = require('router');
        appRouter.navigate('contents', { trigger: true });
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.startImport(false);
            }
          },
          fail: function () {
            self._enableBtn();
          },
        });
      },
    });
  },

  _disableBtn: function _disableBtn () {
    $('select[name=selectContent]').prop('disabled', true);
    $('.js-importContent').prop('disabled', true);
  },

  _enableBtn: function _enableBtn () {
    $('select[name=selectContent]').prop('disabled', false);
    $('.js-importContent').prop('disabled', false);
  },

  render: function render () {
    Spinner.hide();
    let html;
    if (singleton.importContent.contentId && singleton.importContent.locales) {
      html = template({
        contents: singleton.importContent.contents,
        contentId: singleton.importContent.contentId,
        includeDraft: singleton.importContent.includeDraft,
        includeArchived: singleton.importContent.includeArchived,
        locales: singleton.importContent.locales,
      });
    } else {
      html = template({ contents: singleton.importContent.contents });
    }
    this.$el.html(html);
    $('#main').append(this.$el);
    if (singleton.importContent.contentId) {
      $('select[name=selectContent]').val(singleton.importContent.contentId);
    }
    if (!singleton.importContent.locales) {
      $('#show-locales-button').removeClass('hidden');
    } else {
      $('#import-locales-with-schema-div').removeClass('hidden');
      $('.js-importContent').text('Import');
      $('select[name=selectContent]').on('change', $.proxy(this.loadLocaleList, this));
    }
    return this;
  },
}, {
  retrieveData: function retrieveData (importContent, retry) {
    const self = this;
    importContent.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.importContent.contents = model.toJSON();
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(importContent, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe (token) {
    Spinner.show();
    singleton = new View();
    singleton.importContent = new ImportContent({ token: token });
    this.retrieveData(singleton.importContent, true);
    return singleton;
  },
});
module.exports = View;
