'use strict';

let View,
  singleton,
  token;
const  template = require('templates/addToken');

View = Backbone.View.extend({

  events: {
    'click .js-validateToken': 'validateToken',
  },

  validateToken: function (event) {
    const appRouter = require('router');
    token = $('.js-addToken-field').val();
    if (token && token.startsWith('Bearer ')) {
      token = token.replace('Bearer ', '');
    }
    appRouter.navigate('prodContent/' + token, { trigger: true });
  },

  render: function (isValidToken) {
    const html = template();
    this.$el.html(html);
    if (isValidToken != 'true') {
      this.$('.js-addToken-error').text('Please insert a validate token');
    }
    $('#main').append(this.$el);
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function (isValidToken) {
    if (!singleton) {
      singleton = new View();
    }
    return singleton.render(isValidToken);
  },
});

module.exports = View;
