'use strict';

 let View,
     singleton ;
const  template = require('templates/editMultipleTagModal'),
    TagsUtils = require('utils/TagsUtils');


function close () {
  this.$el.remove();
  this.off();
  if (this.release) {
    this.release.off(null, null, this);
  }
}

function initModel () {
  const release = this.release.toJSON();
  const tagsMetadata = this.release.get('tagsMetadata');

  let tags = this.defaultTag;
  this.currentTags =  _.clone(tags);
  this.newTags =  _.clone(tags);
  const splittedTags = [this.defaultTag !== 'ALL' ? this.defaultTag : '', ''];

  let uniqueArray = function(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) == pos;
    });
  };

  const allTags = TagsUtils.getTagsInfo(this.release, true, tagsMetadata.prefix);

  let mainTagStr = (splittedTags.length >= 1) ? decodeURIComponent(splittedTags[0]) : '';
  let subTagStr = (splittedTags.length >= 2) ? decodeURIComponent(splittedTags[1]) : '';
  const mainTagOptions = ['<option value="">-- No main Tag --</option>'];



  mainTagOptions.push( uniqueArray(
    allTags.map((o) => {  return decodeURIComponent(o.mainTag); }))
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .filter((s) => {return s !== 'ALL';})
    .map((s) => { return  mainTagStr === s ? '<option value="' + s + '" selected="selected">' + s + '</option>' : '<option value="' + s + '">' + s + '</option>'; }));

  let subTagOptions = ['<option value="">-- No sub Tag --</option>'];

  subTagOptions.push( uniqueArray(
    allTags.map((o) => { return o.subTags; })
      .flat()
      .map((o) => {return o.subTagStr; }))
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .filter((s) => {return s !== 'ALL'; })
    .map((s) => { return '<option value="' + s + '">' + s + '</option>'; }));


  return {
    mainTagOptions: mainTagOptions.join(''),
    subTagOptions: subTagOptions.join(''),
    release: release,
    title: this.title,
    jsonPath: this.jsonPath,
    tags: tags,
    mainTag: (splittedTags.length >= 1) ? splittedTags[0] : '',
    mainTagStr: mainTagStr,
    subTag: (splittedTags.length >= 2) ? splittedTags[1] : '',
    subTagStr: subTagStr,
  };
}

function saveTags () {
  if (this.validateTags()) {
    this.$el.parent().modal('toggle');
    this.callBackEdit(decodeURIComponent(this.newTags));
    return true;
  }
  return false;
}

function validateTags () {
  $('.cms-label--error').remove();
  let $mainTagInput = $('#multiple-release-main-tag');
  let mainTagStr = $mainTagInput.val().trim();
  let $subTagInput = $('#multiple-release-sub-tag');
  let subTagStr = $subTagInput.val().trim();

  if (subTagStr) {
    if (!mainTagStr) {
      $mainTagInput.addClass('cms-field--error');
      $mainTagInput.after('<div class="cms-label--error">Main tag cannot be empty if there is a sub tag</div>');
      return false;
    } else if (subTagStr.indexOf(',') >= 0) {
      $subTagInput.addClass('cms-field--error');
      $subTagInput.after('<div class="cms-label--error">Sub tag cannot contain commas</div>');
      return false;
    }
  }
  if (mainTagStr) {
    if (mainTagStr.indexOf(',') >= 0) {
      $mainTagInput.addClass('cms-field--error');
      $mainTagInput.after('<div class="cms-label--error">Main tag cannot contain commas</div>');
      return false;
    }
  }
  const mainTag = encodeURIComponent(mainTagStr.toUpperCase());
  const subTag = encodeURIComponent(subTagStr);
  this.newTags = (mainTag && subTag) ? [mainTag, subTag].join(',') : mainTag;

  return true;
}

function cancel () {
  this.$el.parent().modal('toggle');
}

function render () {
  const templateModel = this.initModel();
  const html = template(templateModel);
  this.$el.html(html);
  $('#modal-editMultipleTag').append(this.$el);


  $('.selectpicker').selectpicker({
    noneResultsText: '<button type="button" class="btn btn-outline-secondary" onclick=(add_opt(this))> Add new tag ... </button>',
  });



  this.$el.parent().modal('toggle');
  this.delegateEvents();
  return this;
}


function showMe(release,defaultTag, defaultSubTag,callBack) {
  if (!singleton) {
    singleton = new View();
  } else {
    singleton.close();
  }
  singleton.release = release;
  singleton.defaultTag = defaultTag;
  singleton.defaultSubTag = defaultSubTag;
  singleton.currentTags = '';
  singleton.newTags = '';
  singleton.callBackEdit = callBack;
  singleton.render();
  return singleton;
}

View = Backbone.View.extend({
  events: {
    'click .js-btn-edit-tag': 'saveTags',
    'click .js-btn-cancel': 'cancel',
  },
  close: close,
  initModel:initModel ,
  saveTags: saveTags,
  validateTags:validateTags ,
  cancel: cancel,
  render:render,
}, {
  showMe:showMe,
});

module.exports = View;
