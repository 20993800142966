'use strict';

const MigrateContentV2 = Backbone.Model.extend({

  collection: {
    url: '/v2/migrateContent',
  },

  defaults: {
    migrationList: [{
      id: '',
      contentId: '',
      locales: ['all'],
      migrations: [],
    }],
  },

  url: function url() {
    return '/v2/migrateContent?htmlResponse=true';
  },
});

module.exports = MigrateContentV2;
