'use strict';

module.exports = function isProgramDisabled (inputObject) {
  const { disableStartDate, enableStartDate } = inputObject;
  if (disableStartDate && enableStartDate) {
    const disableDateFirst = isDisableDateBeforeEnableDate({
      disableStartDate: disableStartDate,
      enableStartDate: enableStartDate,
    });
    if (isNowDateWithinProgrammedInterval({
      startDate: (disableDateFirst) ? disableStartDate : enableStartDate,
      endDate: (disableDateFirst) ? enableStartDate : disableStartDate,
    })) {
      return disableDateFirst;
    } 
      return !disableDateFirst;
    
  } 
    if (disableStartDate) {
      return isNowDateAfterStartDate({ startDate: disableStartDate });
    } 
      return !isNowDateAfterStartDate({ startDate: enableStartDate });
    
  
};

function isDisableDateBeforeEnableDate (inputObject) {
  return moment(inputObject.disableStartDate).seconds(0).isBefore(moment(inputObject.enableStartDate).seconds(0));
}

function isNowDateWithinProgrammedInterval (inputObject) {
  const nowDate = moment().seconds(0);
  return (nowDate.isAfter(moment(inputObject.startDate).seconds(0)) && nowDate.isBefore(moment(inputObject.endDate).seconds(0)));
}

function isNowDateAfterStartDate (inputObject) {
  const nowDate = moment().seconds(0);
  return nowDate.isAfter(moment(inputObject.startDate).seconds(0));
}
