let View,
  singleton;
const template = require('templates/releases'),
  Releases = require('models/releases'),
  Release = require('models/release'),
  notificationEvent = require('NotificationEvent'),
  Content = require('models/content'),
  account = require('models/account'),
  Spinner = require('Spinner'),
  config = require('models/config'),
  errorHandler = require('utils/errorHandler'),
  Utils = require('utils/utils'),
  LocalesView = require('views/localesModalView'),
  Confirm = require('views/confirmView'),
  State = require('../State');

View = Backbone.View.extend({

  initialize: function initialize() {
    this.initHandlebarsHelpers();
  },

  events: {
    'click .js-locales-link': 'displayLocalesModal',
    'click #button-back': 'previousPage',
    'click #button-back-to': 'previousView',
    'click .clickable-row': 'detailRelease',
    'click .archived': 'archivedReleases',
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  initHandlebarsHelpers: function initHandlebarsHelpers() {
    Handlebars.registerHelper('formatDate', Utils.formatDate);
    Handlebars.registerHelper('formatId', (value) => {
      return (value) ? '#' + value.substring(value.length - 4, value.length).toUpperCase() : '';
    });
    Handlebars.registerHelper('releaseDateMsg', this.getReleaseDateMsg);
  },

  displayLocalesModal: function displayLocalesModal() {
    LocalesView.showMe(this.content.locales, (contentId, localeId) => {
      const appRouter = require('router');
      appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases', { trigger: true });
    });
  },

  previousPage: function previousPage() {
    const appRouter = require('router');
    appRouter.navigate('contents', { trigger: true });
  },

  previousView: function previousView() {
    State.goToLastView();
  },

  getReleaseDateMsg: function getReleaseDateMsg(release) {
    let dateMsg = '';
    if (release.publish_date) {
      dateMsg = Utils.formatDate(release.publish_date);
    } else if (release.metadata && release.metadata.publication_programming_date) {
      const formatedProgrammedDate = Utils.formatDate(release.metadata.publication_programming_date);
      if (Utils.isDateInPast(release.metadata.publication_programming_date)) {
        dateMsg = '<span style="color:red"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error! The publish failed on: ' + formatedProgrammedDate + '</span>';
      } else if (release.metadata.publication_programming_date_already_used) {
        dateMsg = '<span style="color:red"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error! Date already used: ' + formatedProgrammedDate + '</span>';
      } else if (release.value_validation_errors && release.value_validation_errors.length) {
        dateMsg = '<span style="color:red"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error! Draft has validation errors: ' + formatedProgrammedDate + '</span>';
      } else {
        dateMsg = 'Publication programmed on: ' + formatedProgrammedDate;
        dateMsg = '<span style="color:green" class="cms-dashboard-content-programmedDate"><span class="glyphicon glyphicon-time"></span> ' + dateMsg + '</span>';
      }
    } else {
      dateMsg = '';
    }
    return dateMsg;
  },

  createNewRelease: function createNewRelease(contentId, localeId, retry) {
    const newRelease = new Release({
      content_id: contentId,
      locale: localeId,
    });
    const self = this;
    return newRelease.save(newRelease.toJSON(), {
      success: function(model) {
        Spinner.hide();
        self.navigateTo(contentId, localeId, model.id);
        notificationEvent.notify('Release created', 'success');
      },
      error: function(model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function() {
            if (retry) {
              self.createNewRelease(contentId, localeId, false);
            }
          },
          fail: function() {
          },
        });
      },
    });
  },

  bindNewReleaseButton: function bindNewReleaseButton(releases, contentId, localeId) {
    let numberOfDraftReleases = 0;
    let numberOfLiveReleases = 0;
    for (let i = 0; i < releases.length; i++) {
      const release = releases[i];
      switch (release.get('status')) {
        case 'draft':
          numberOfDraftReleases++;
          break;
        case 'live':
          numberOfLiveReleases++;
          break;
        default:
          break;
      }
    }
    var nbOfDraftReleasesPerLocale = config.getOverriddenValue({
      jsonPath: 'db.general.nbOfDraftReleasesPerLocale',
      contentId: contentId,
      localeId: localeId,
    }) || 3;

    if (account.isAllowed(contentId, localeId, 'WRITE')
      && ((numberOfLiveReleases > 0 && numberOfDraftReleases < nbOfDraftReleasesPerLocale)
        || (numberOfLiveReleases === 0 && numberOfDraftReleases === 0))) {
      this.$el.find('#create-new-release').removeAttr('disabled');
      this.$el.find('#create-new-release').click($.proxy(function() {
        Confirm.showMe('You are about to create a new release.', $.proxy(function() {
          Spinner.show();
          this.$el.find('#create-new-release').attr('disabled', 'disabled');
          this.createNewRelease(contentId, localeId, true);
        }, this), $.proxy(() => {
        }, this), false);
      }, this));
    }
  },

  navigateTo: function navigateTo(contentId, localeId, releaseId) {
    var appRouter = require('router');
    appRouter.navigate('contents/' + contentId + '/locales/' + localeId + '/releases/' + releaseId, { trigger: true });
  },

  detailRelease: function detailRelease(event) {

    var releaseData = $(event.currentTarget).data();
    State.add(State.action.SHOW_RELEASE, releaseData.contentId, releaseData.localeId, releaseData.releaseId);
    this.navigateTo(releaseData.contentId, releaseData.localeId, releaseData.releaseId);
  },

  archivedReleases: function archivedReleases() {
    var appRouter = require('router');
    appRouter.navigate('contents/' + this.model.contentId + '/locales/' + this.model.localeId + '/archived', { trigger: true });
  },

  sortLiveFirst: function sortLiveFirst(releases) {
    if (!releases || releases.length < 2) {
      return releases;
    }
    for (var i = releases.length - 1; i > 0; i--) {
      var release = releases[i];
      if (release.status === 'live') {
        var liveRelease = releases.splice(i, 1)[0];
        releases.splice(0, 0, liveRelease);
        break;
      }
      continue;
    }
    return releases;
  },

  goToContentsPage: function goToContentsPage() {
    var appRouter = require('router');
    appRouter.navigate('contents', { trigger: true });
  },

  goToReleasesPage: function goToReleasesPage() {
    var appRouter = require('router');
    appRouter.navigate('contents/' + this.releasesOptions.contentId + '/locales/' + this.releasesOptions.localeId + '/releases', { trigger: true });
  },

  render: function render() {
    Spinner.hide();
    if (this.model && this.model.models.length && this.model.models[0].get('id')) {
      var releases = this.sortLiveFirst(this.model.toJSON());
      releases = releases.map((release) => {
        release['pageCountUsedForDiff'] = release.pageCountOfPreviousLive;
        release['pageCountDiff'] = release.pageCount - release.pageCountOfPreviousLive;
        return release;
      });
      var html = template({
        content_id: this.releasesOptions.contentId,
        locale_id: this.releasesOptions.localeId,
        archivedShow: true,
        releasesShow: false,
        releases: releases,
      });
      this.$el.html(html);
      $('#main').append(this.$el);
      this.delegateEvents();
      this.$el.find('#archived').removeClass('hidden');
      this.$el.find('#create-new-release').removeClass('hidden');
      this.$el.find('.js-cms-rollback-archived').remove();
      this.$el.find('#showAllArchived').remove();
      this.bindNewReleaseButton(this.model.models, this.releasesOptions.contentId, this.releasesOptions.localeId);
    } else {
      notificationEvent.notify('Releases could not be loaded, please refresh', 'danger');
    }

    return this;
  },
}, {
  retrieveData: function retrieveData(content, releases, retry) {

    var self = this;
    var promises = [
      content.fetch()
      , releases.fetch(),
    ];
    // when all of them are complete...
    $.when.apply($, promises).done(() => {
      Spinner.hide();
      var appRouter = require('router');
      if (!appRouter.activeView || appRouter.activeView == singleton) {
        singleton.model = releases;
        singleton.content = content;
        singleton.render();
      }
    }).fail((error) => {
      errorHandler({
        error: error,
        retry: retry,
        success: function() {
          if (retry) {
            self.retrieveData(content, releases, false);
          }
        },
        fail: function() {
        },
      });
    });
  },

  showMe: function showMe(contentId, localeId) {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }
    var content = new Content({ id: contentId });
    singleton.releasesOptions = {
      contentId: contentId,
      localeId: localeId,
      notStatus: 'archived',
      filter: false,
      infoOnly: true,
    };
    var releases = new Releases(singleton.releasesOptions);
    this.retrieveData(content, releases, true);
    return singleton;
  },
});

module.exports = View;
