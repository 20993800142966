'use strict';

const ImportScopes = Backbone.Model.extend({
    url: function url() {
        return `/v1/importScopes/${this.hepta}/${this.token}`;
    },

    initialize: function initialize (attributes) {
      if(!attributes) {
          return;
      }
      this.token = attributes.token;
      this.hepta = attributes.hepta;
  },
});

module.exports = ImportScopes;
