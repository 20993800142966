'use strict';

let View, singleton;
const template = require('templates/migrateContentDcx'),
  account = require('models/account'),
  notificationEvent = require('NotificationEvent'),
  Spinner = require('Spinner'),
  Locales = require('models/locales'),
  MigrateContentDcx = require('models/migrateContentDcx'),
  errorHandler = require('utils/errorHandler'),
  Utils = require('utils/utils'),
  Release = require('models/release');

View = Backbone.View.extend(
  {
    close: function close() {
      this.$el.remove();
      this.off();
      if (this.contents) {
        this.contents.off(null, null, this);
      }
    },

    bindButtons: function bindButtons() {
      this.$el
        .find('#button-get-migrate-content')
        .off()
        .click(
          $.proxy(function () {
            try {
              if ($('.ace_error').length != 0) {
                notificationEvent.notify('JSON is not valid', 'danger');
              } else {
                this.migrateContent(true);
              }
            } catch (error) {
              notificationEvent.notify(error.message, 'danger');
            }
          }, this),
        );
    },

    _initializeLocalesSelect: function _initializeLocalesSelect() {
      if (this.locales && this.locales.length) {
        $('#locales').attr('disabled', false);
        for (let i = 0; i < this.locales.length; i++) {
          const locale = this.locales[i];
          const localeId = locale.id;
          $('#locales').append('<option value="' + localeId + '">' + localeId + '</option>');
        }
        this._initializeTagsSelect();
      } else {
        $('#locales').attr('disabled', true);
        $('.selectpicker').selectpicker();
      }
      $('.selectpicker').selectpicker('refresh');
    },

    _initializeTagsSelect: function _initializeTagsSelect() {
      const localeId = this.locales[0].id;
      console.log({ localeId, locales: this.locales });
      if (localeId) {
        this._getTagsSelectOptions(localeId);
      }
    },

    _getTagsSelectOptions: function _getTagsSelectOptions(localeId) {
      const getOptions = (val) => {
        const localeSelectedValue = val || this._getLocalesSelectValue();
        if (localeSelectedValue) {
          $('#tags').attr('disabled', true);
          Spinner.show();
          const release = new Release({
            id: 'live',
            content_id: 'b2c-pages',
            locale: localeSelectedValue,
          });
          release.fetch({
            success: function (release) {
              Spinner.hide();
              $('#tags').empty();
              const { tagsList } = release.get('value')['@metadata'].pages;
              const tags = tagsList ? [...new Set(tagsList.map((tag) => decodeURIComponent(tag)))] : [];
              console.log(tags);
              $('#tags').attr('disabled', false);
              for (let i = 0; i < tags.length; i++) {
                const tag = tags[i];
                $('#tags').append('<option value="' + encodeURIComponent(tag) + '">' + decodeURIComponent(tag) + '</option>');
              }
              $('.selectpicker').selectpicker('refresh');
            },
          });
        }
      };

      getOptions(localeId);
      $('#locales').on('change', (e) => {
        getOptions(e.target.value);
      });
    },

    _resetLocalesSelect: function _resetLocalesSelect() {
      $('#locales').empty();
      $('#locales').attr('disabled', true);
      $('.selectpicker').selectpicker('refresh');
    },

    _getLocalesSelectValue: function _getLocalesSelectValue() {
      return $('#locales').selectpicker('val');
    },

    _getTagsSelectValue: function _getTagsSelectValue() {
      return $('#tags').selectpicker('val');
    },

    _getTypeSelectValue: function _getTypeSelectValue() {
      return $('#type').selectpicker('val');
    },

    _disableBtn: function _disableBtn() {
      $('#button-get-migrate-content').prop('disabled', true);
    },

    _enableBtn: function _enableBtn() {
      $('#button-get-migrate-content').prop('disabled', false);
    },

    migrateContent: function migrateContent(retry) {
      const self = this;
      const localeId = this._getLocalesSelectValue();
      const tag = this._getTagsSelectValue();
      const type = this._getTypeSelectValue();

      Spinner.show();
      self._disableBtn();
      notificationEvent.notify('Getting Migration content...', 'warning');
      setTimeout(() => {
        const contentMigrationDcx = new MigrateContentDcx({
          localeId,
          type,
          tag,
        });
        contentMigrationDcx.save(
          {},
          {
            success: function (response) {
              Spinner.hide();
              self._enableBtn();
              if (response) {
                const { result } = response.toJSON();
                self.jsonEditor.set(result);
              }
              notificationEvent.notify('Migration content fetched!', 'success');
            },
            error: function (model, error) {
              errorHandler({
                error: error,
                retry: retry,
                success: function () {
                  if (retry) {
                    self.migrateContent(false);
                  }
                },
                fail: function () {
                  self._enableBtn();
                },
              });
            },
          },
        );
      }, 500);
    },

    validateMigrationList: function validateMigrationList(migrationList) {
      if (!Utils.isNotEmptyArray(migrationList)) {
        this.showErrorDiv(
          $(
            '<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration data, it must be a non-empty array of migration objects or a non-empty migration object with mandatory "id", "contentId", "locales" and "migrations" properties (migrations must be a non-empty array)</li>',
          ),
        );
        throw new Error();
      }

      for (let i = 0; i < migrationList.length; i++) {
        const migration = migrationList[i];
        this.checkMigrationProperties(migration);
      }
    },

    checkMigrationProperties: function checkMigrationProperties(migration) {
      if (!Utils.isNotEmptyObject(migration)) {
        this.showErrorDiv(
          $(
            '<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration, it must be a non-empty object with mandatory "id", "contentId", "locales" and "migrations" (migrations must be a non-empty array)</li>',
          ),
        );
        throw new Error();
      }

      if (
        !migration.id ||
        !migration.contentId ||
        !migration.locales ||
        !migration.migrations ||
        !migration.migrations.length
      ) {
        this.showErrorDiv(
          $(
            '<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration, it must be a non-empty object with mandatory "id", "contentId", "locales" and "migrations" properties (migrations must be a non-empty array)</li>',
          ),
        );
        throw new Error();
      }
    },

    resetInfoDivs: function resetInfoDivs() {
      const $errorDiv = $('.cms-migrateContent-error-display');
      $errorDiv.addClass('hidden');
      const $errorItems = $('.cms-migrateContent-error-item');
      $errorItems.remove();

      const $resultDiv = $('.cms-migrateContent-result-display');
      $resultDiv.addClass('hidden');
      const $resultContent = $('.cms-migrateContent-result-content');
      $resultContent.empty();
    },

    showErrorDiv: function showErrorDiv($errorElement) {
      this.resetInfoDivs();
      const $errorDiv = $('.cms-migrateContent-error-display');
      $errorDiv.removeClass('hidden');
      const $errorList = $('.cms-migrateContent-error-list');
      $errorElement.appendTo($errorList);
    },

    showResultDiv: function showResultDiv(result) {
      this.resetInfoDivs();
      const $resultDiv = $('.cms-migrateContent-result-display');
      $resultDiv.removeClass('hidden');
      const $resultContent = $('.cms-migrateContent-result-content');
      const sortedKeys = Object.keys(result).sort();
      const failedLocales = [];
      for (let i = 0; i < sortedKeys.length; i++) {
        const key = sortedKeys[i];
        const value = result[key];
        if (value.indexOf('FAIL') >= 0) {
          failedLocales.push(key);
        }
      }
      if (failedLocales.length === 0) {
        $(
          '<h3 class="cms-migrateContent-result-message cms-migrateContent-result-success">All locales successfully migrated</h3>',
        ).appendTo($resultContent);
      } else {
        $(
          '<h3 class="cms-migrateContent-result-message cms-migrateContent-result-fail">' +
          failedLocales.length +
          ' locales could not be migrated: ' +
          failedLocales.join(', ') +
          '</h3>',
        ).appendTo($resultContent);
      }
      $('<br>').appendTo($resultContent);
      for (let i = 0; i < sortedKeys.length; i++) {
        const key = sortedKeys[i];
        const value = result[key];
        $('<h4 class="cms-migrateContent-result-locale">Locale ' + key + ':</h4>').appendTo(
          $resultContent,
        );
        $('<p class="cms-migrateContent-result-message">' + value + '</p>').appendTo(
          $resultContent,
        );
        $('<br>').appendTo($resultContent);
      }
    },

    loadLocaleList: function loadLocaleList(event, noRetry) {
      this._resetLocalesSelect();
      const self = this;
      const contentId = 'b2c-pages';
      if (contentId) {
        Spinner.show();
        this._disableBtn();
        this.locales = new Locales({
          contentId: contentId,
        });
        this.locales.fetch({
          success: function (model) {
            Spinner.hide();
            self._enableBtn();
            self.locales = model.toJSON();
            self._initializeLocalesSelect();
          },
          error: function (model, error) {
            errorHandler({
              error: error,
              retry: !noRetry,
              success: function () {
                if (!noRetry) {
                  self.loadLocaleList(event, true);
                }
              },
              fail: function () { },
            });
          },
        });
      }
    },

    render: function render() {
      Spinner.hide();
      if (!account.isDev()) {
        const appRouter = require('router');
        appRouter.navigate('welcome', { trigger: true });
        return;
      }
      const html = template({});
      this.$el.html(html);
      $('#main').append(this.$el);
      this.bindButtons();
      const jsonContainer = document.getElementById('jsoneditor');
      const options = {
        mode: 'code',
        modes: ['code', 'tree'],
        error: function (err) {
          notificationEvent.notify(err.toString(), 'danger');
        },
      };
      this.jsonEditor = new JSONEditor(jsonContainer, options);
      this._initializeLocalesSelect();
      this.loadLocaleList();
      this.delegateEvents();

      Array.from(jsonContainer.querySelectorAll('input')).forEach((input) => {
        input.readOnly = true;
      });
      return this;
    },
  },
  {
    showMe: function showMe() {
      Spinner.show();
      if (!singleton) {
        singleton = new View();
      }
      singleton.render();
      return singleton;
    },
  },
);

module.exports = View;
