'use strict';

const Importcontent = Backbone.Collection.extend({

  url: function url () {
    if (this.contentId) {
      const queryParams = '?includeDraft=' + this.includeDraft + '&includeArchived=' + this.includeArchived;
      if (this.locales && this.locales.length > 0) {
        if (this.allLocales) {
          // Import all locales
          return '/v1/importContent/' + this.contentId + '/' + this.token + queryParams;
        }
        // Import specified locales
        return '/v1/importContent/' + this.contentId + '/' + this.locales.join('_') + '/' + this.token + queryParams;
      } 
        if (this.allLocales) {
          // Import all locales
          return '/v1/importContent/' + this.contentId + '/' + this.token + queryParams;
        }
        // Get locales list
        return '/v1/importContent/' + this.contentId + '/locales/' + this.token;
      
    }
    // Get contents list
    return '/v1/importContent/' + this.token;
  },

  initialize: function initialize (attributes) {
    this.token = attributes.token;
    this.contents = attributes.contents;
    this.contentId = attributes.contentId;
    this.includeDraft = attributes.includeDraft;
    this.includeArchived = attributes.includeArchived;
    this.allLocales = attributes.allLocales;
    this.locales = attributes.locales;
  },

  token: null,
  contents: null,
  contentId: null,
  includeDraft: false,
  includeArchived: false,
  allLocales: false,
  locales: null,
});

module.exports = Importcontent;
