'use strict';

const User = require('models/user');

const Users = Backbone.Collection.extend({
    url: '/users',

    model: User,
});

module.exports = Users;
