'use strict';

const TreeItemTools = require('./TreeItemTools');
const Utils = require('./utils');

const DataUtils = {};

DataUtils.json2Csv = function json2Csv(json) {
  if (!json || json === {}) {
    return '';
  }
  const csvLines = [];

  if(Array.isArray(json)) {
    json.forEach((item, index) => {
      csvLines.push(...DataUtils.json2Csv({ [index]: item }).split('\n'));
    });
    return csvLines.join('\n');
  }

  if (!_.isObject(json) || _.isArray(json)) {
    throw new Error();
  }
  const flattenValue = TreeItemTools.flatten(json);
  for (const key in flattenValue) {
    const value = (_.isArray(_.get(flattenValue, key))) ? JSON.stringify(_.get(flattenValue, key)) : _.get(flattenValue, key);
    csvLines.push('``' + key + '``,``' + value + '``');
  }
  return csvLines.join('\n');
};

DataUtils.csv2Json = function csv2Json(csv) {
  if (!csv) {
    return {};
  }
  return DataUtils.array2Json(DataUtils.csv2Array(csv));
};

DataUtils.csv2Array = function csv2Array(csv) {
  if (!csv.endsWith('\n')) {
    csv += '\n';
  }

  const regex = /``([\s\S]*?)``,``([\s\S]*?)``\n/g;
  let m,
    rows = [];
  while (m = regex.exec(csv)) {
    rows.push([m[1], m[2]]);
  }
  return rows;
};

DataUtils.array2Json = function array2Json(rows) {
  if (!rows || !rows.length) {
    return {};
  }
  const flattenValue = {};
  rows.map((csvLine) => {
    // Check format
    if (csvLine.length !== 1 && csvLine.length !== 2) {
      throw new Error();
    }
    // Parse key & value
    let [key, value] = csvLine;
    // exceptions
    if (!key.endsWith('.stars') && !key.endsWith('.iconName')) {
      value = DataUtils.parseQuotedValue(csvLine[1]);
    }
    _.set(flattenValue, key, value);
  });
  return TreeItemTools.unflatten(flattenValue);
};

DataUtils.xlsx2Array = function xlsx2Array(workbook) {
  let result = [];
  workbook.SheetNames.forEach((sheetName) => {
    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
    result = roa;
  });
  return result;
};

DataUtils.parseQuotedValue = function parseQuotedValue(value) {
  // Check integer (needs to be first to catch value === 0)
  if (Utils.isNormalInteger(value)) {
    return parseInt(value, 10);
  }
  // Check undefined value or empty string
  if (!value) {
    return (value === false) ? false : '';
  }
  // Check boolean
  if (value === 'true' || value === 'false') {
    return (value === 'true');
  }
  // Check empty Array
  if (value === '[]') {
    return [];
  }

  return value;
};

DataUtils.formatCsvEmptyValues = function formatCsvEmptyValues(csv) {
  return csv
    .split('\n')
    .map((csvLine) => {
      if (csvLine.endsWith('``,')) {
        csvLine = csvLine + '````';
      }
      return csvLine;
    })
    .join('\n');
};

DataUtils.downloadData = function downloadData(data, fileName, fileMimeType) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:' + fileMimeType + ';charset=utf-8,' + encodeURIComponent(data));
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

DataUtils.downloadXlsx = function downloadXlsx(data, fileName, worksheetName) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);

  /* write workbook */
  XLSX.writeFile(workbook, fileName);
};

module.exports = DataUtils;
