let View,
  singleton;
const   account = require('models/account'),
  notificationEvent = require('NotificationEvent'),
  Content = require('models/content'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  config = require('models/config'),
  errorHandler = require('utils/errorHandler'),
  template = require('templates/createContent');

View = Backbone.View.extend({

  events: {
    'blur .cms-editable': 'updateContent',
    'click .js-addNotification': 'addNotificationsUrl',
    'click .js-removeNotification': 'removeNotificationsUrls',
  },

  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  addNotificationsUrl: function addNotificationsUrl () {
    const notifications_urlsDiv = $('#notifications');
    const numberUrls = $('.js-createContent-urls').size();
    $('<div class="js-createContent-urls cms-createContent-urls"><input type="text" class="form-control cms-editable" id="content-notifications_urls_' + numberUrls + '"  /><a id= "content-remove_notifications_urls_' + numberUrls + '" class="cms-createContent-removeUrl glyphicon glyphicon-remove js-removeNotification" href="#"></a></div>').appendTo(notifications_urlsDiv);
    return false;
  },

  removeNotificationsUrls: function removeNotificationsUrls (ev) {
    this.updateContent(ev);
    ev.target.parentElement.remove();
    return false;
  },

  updateContent: function updateContent (event) {
    const $element = $(event.target);
    const id = $element.attr('id');
    if (id == 'content-preview_url') {
      this.model.set('preview_url', $element.val());
    } else if (id == 'content-block-delete') {
      this.model.set('deleteBlocked', $element.prop('checked'));
    } else if (id == 'content-desc') {
      this.model.set('desc', $element.val());
    } else if (id && id.indexOf('content-notifications_urls') !== -1) {
      const position = id.substring(id.lastIndexOf('_') + 1);
      const aNumbers = _.clone(this.model.get('notifications_urls'));
      aNumbers[position] = $element.val();
      this.model.set('notifications_urls', aNumbers);
    } else if (id && id.indexOf('content-remove_notifications_urls') !== -1) {
      const position = id.substring(id.lastIndexOf('_') + 1);
      const aNumbers = _.clone(this.model.get('notifications_urls'));
      aNumbers.splice(position, 1);
      this.model.set('notifications_urls', aNumbers);
    }
  },

  createContent: function createContent (retry) {
    const self = this;
    Spinner.show();
    self.model.save(self.model.toJSON(), {
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        appRouter.navigate('editContent/' + model.get('id'), { trigger: true });
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.createContent(false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  render: function render () {
    const html = template();
    this.$el.html(html);
    $('#main').append(this.$el);

    if ((!config.get('featureFlipping').blockContentAndLocaleDelete || config.get('featureFlipping').blockContentAndLocaleDelete === 'false') && account.isSuperAdmin()) {
      $('#content-block-delete-row').removeClass('hidden');
    }
    this.$el.find('#button-create-content').off().click($.proxy(function () {
      this.model.set('id', this.$el.find('#content-name').val());
      if (this.model.isValid()) {
        const self = this;
        Confirm.showMe('You are about to create this content.', () => {
          self.createContent(true);
        }, () => {
        });
      } else {
        notificationEvent.notify(this.model.validationError, 'danger');
      }
    }, this));

    this.delegateEvents();
    return this;
  },
}, {
  showMe: function showMe () {
    if (!singleton) {
      singleton = new View();
    }
    singleton.model = new Content();
    singleton.render();
    return singleton;
  },
});

module.exports = View;
