'use strict';

const account = require('models/account'),
  config = require('models/config'),
  notificationEvent = require('NotificationEvent'),
  Spinner = require('Spinner');

function disconnectUser () {
  account.destroySession();
  config.redirectTo('/app/welcome');
}

module.exports = function errorHandler (input) {
  Spinner.hide();
  if (!input || !input.error) {
    throw new Error('A front-end error occured but there is no data available');
  }
  const jsonError = (input.error.responseJSON) ? input.error.responseJSON : input.error;
  if (jsonError) {
    const errorMessage = (jsonError.error_description) ? jsonError.error_description : jsonError.message;
    if (errorMessage) {
      if (errorMessage.startsWith('Expired access_token') || errorMessage.startsWith('Invalid access_token')) { // Error during verifyAccessToken - try to renew it and do the previous action again
        if (input.retry && account.isAccessTokenExpired()) {
          account.renewAccessToken(() => {
            // Redo stuff
            input.success();
          }, () => {
            disconnectUser();
          });
        } else {
          disconnectUser();
        }
      } else {
        if (!input.noNotificationEvent) {
          notificationEvent.notify(errorMessage, 'danger');
        }
        input.fail(jsonError);
      }
    } else if (jsonError.statusCode === 401 || jsonError.statusCode === 403) {  // Token error - try to renew it and do the previous action again
      if (input.retry && account.isAccessTokenExpired()) {
        account.renewAccessToken(() => {
          // Redo stuff
          input.success();
        }, () => {
          disconnectUser();
        });
      } else {
        disconnectUser();
      }
    } else {
      input.fail(jsonError);
    }
  } else {
    input.fail();
  }
};
