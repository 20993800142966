'use strict';

const config = require('models/config');

let Account = Backbone.Model.extend({
  url: function url () {
    const currentHost = Backbone.history.location.host;
    const authorizationConfig = config.get('authorization'),
      serverOAuth2Port = authorizationConfig.oauth2Port;
    if (currentHost.indexOf('com.cn') >= 0 && !authorizationConfig.oauth2Hostname.indexOf('com.cn') >= 0) {
      authorizationConfig.oauth2Hostname += '.cn';
    }
    return (serverOAuth2Port == 443 ? 'https://' : 'http://')
      + authorizationConfig.oauth2Hostname
      + (serverOAuth2Port != 443 ? ':' + serverOAuth2Port : '')
      + authorizationConfig.oauth2UserinfoPath;
  },

  initialize: function initialize () {
    if (sessionStorage.user) {
      this.set(JSON.parse(sessionStorage.user));
    }
  },

  defaults: {
    sub: null,
    name: null, // "Antoine Chantalou"
    given_name: null, // "Antoine"
    family_name: null, // "Chantalou"
    email: null, // "antoine.chantalou@clubmed.com"
    email_verified: false,
    locale: null, // "fr-FR"
    joined_at: 0,
    updated_at: 0,
    access_token: null,
    expires_in: null,
    id_token: null,
    refresh_token: null,
    token_type: null,
    profile: null,
    scopes: [],
    locales: [],
  },

  getQueryParams: function getQueryParams (uri) {
    if (!uri) {
      return '';
    }
    const match = uri.match(/\?(.*)$/);
    return match ? match[1] : '';
  },

  signin: function signin (queryParams, callback, errorCallback) {
    this.set('code', this.extractQueryParam(queryParams, 'code'));

    if (this.get('code')) {
      const self = this;
      self.getAccessToken((res) => {
        self.authenticate(res, callback, errorCallback);
      }, (error) => {
        errorCallback(error);
      });
    } else {
      errorCallback();
    }
  },

  authenticate: function authenticate (tokenObject, callback, errorCallback) {
    if (!tokenObject) {
      errorCallback();
    }

    this.set('access_token', _.clone(tokenObject.access_token));
    this.set('expires_in', _.clone(tokenObject.expires_in));
    this.set('id_token', _.clone(tokenObject.id_token));
    this.set('refresh_token', _.clone(tokenObject.refresh_token));
    this.set('token_type', _.clone(tokenObject.token_type));

    if (this.get('access_token') && this.get('expires_in') && this.get('id_token') && this.get('refresh_token') && this.get('token_type')) {
      const self = this;
      self.getProfile((res) => {
        if (!res.profile) {
          return self.fetch({
            success: function (res) {
              self.saveSession();
              errorCallback();
            },
            error: errorCallback(),
          });
        }
        self.set('profile', res.profile);
        return self.validateTokens(callback, errorCallback);
      }, () => {
        errorCallback();
      });
    } else {
      errorCallback();
    }
  },

  extractQueryParam: function extractQueryParam (queryParams, name) {
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regexS = '[\\#&?]?' + name + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(queryParams);
    if (results == null) {
      return '';
    } 
      return results[1];
    
  },

  _getOauth2PublicKeysUrl: function _getOauth2PublicKeysUrl () {
    const { oauth2Port } = config.get('authorization');
    const { oauth2Hostname } = config.get('authorization');
    return (parseInt(oauth2Port) === 443 ? 'https://' : 'http://')
      + oauth2Hostname
      + (parseInt(oauth2Port) === 443 ? '' : ':' + oauth2Port)
      + '/jwks';
  },

  _parsePublicKey: function _parsePublicKey (keysContainer) {
    const encPubKey = keysContainer.keys.filter((publicKey) => {
      return publicKey.use === 'sig';
    }).pop();

    return KEYUTIL.getKey(encPubKey);
  },

  validateTokens: function validateTokens (callback, errorCallback) {
    const self = this;
    return Backbone.$.ajax({
      method: 'GET',
      url: self._getOauth2PublicKeysUrl(),
      crossDomain: true,
      contentType: 'text/plain',
      success: function (res) {
        const publicKey = self._parsePublicKey(res);
        // TODO validate nonce
        const validAccessToken = self.validateAccessToken(publicKey);
        if (!validAccessToken) {
          return errorCallback();
        }
        self.getScopes((appScopes) => {
          self.updateScopesAndLocales(appScopes);
          self.fetch({
            success: callback,
            error: errorCallback,
          });
        }, errorCallback);
      },
      error: errorCallback,
    });
  },

  validateAccessToken: function validateAccessToken (publicKey) {
    const access_token = this.get('access_token');
    const accessTokenArray = access_token.split('.');
    const jwtHeader = JSON.parse(atob(accessTokenArray[0]));
    const pubKey = KEYUTIL.getKey(publicKey);
    const accessTokenIsValid = KJUR.jws.JWS.verify(access_token, pubKey, jwtHeader);
    return accessTokenIsValid;
  },

  isAccessTokenExpired: function isAccessTokenExpired () {
    const currentTime = moment().unix();
    const expirationTime = this.extractAccessTokenExpirationTime();
    if (!expirationTime) {
      return false;
    }
    return (expirationTime - currentTime <= 0);
  },

  parseAccessToken: function parseAccessToken () {
    const access_token = this.get('access_token');
    if (!access_token) {
      return null;
    }
    const accessTokenArray = access_token.split('.');
    const jwtClaims = JSON.parse(atob(accessTokenArray[1]));
    return jwtClaims;
  },

  extractAccessTokenScopes: function extractAccessTokenScopes () {
    const jwtClaims = this.parseAccessToken();
    return (jwtClaims) ? jwtClaims.scope : null;
  },

  extractAccessTokenExpirationTime: function extractAccessTokenExpirationTime () {
    const jwtClaims = this.parseAccessToken();
    return (jwtClaims) ? jwtClaims.exp : null;
  },

  updateScopesAndLocales: function updateScopesAndLocales (scope) {
    const userLocales = [];
    const userScopes = Array.isArray(scope) ? scope : scope.trim().split(' ');

    this.set('scopes', userScopes);
    if (this.hasScopeAdmin(userScopes)) {
      config.get('areas').forEach((area) => {
        area.countries.forEach((country) => {
          country.locales.forEach((locale) => {
            userLocales.push(locale.id);
          });
        });
      });
    } else {
      userScopes.forEach((scope) => {
        const locale = scope.split('_')[2];
        if (userLocales.indexOf(locale) == -1) {
          userLocales.push(locale);
        }
      });
    }

    this.set('locales', userLocales);
  },

  getScopeTree: function getScopeTree (userScopes) {
    const scopeTree = {};
    userScopes.forEach((scope) => {
      const members = scope.split('_');
      if (members.length > 3) {
        const membersLength = members.length;
        const scopeKey = members.slice(1, membersLength - 2).join('_');
        if (!scopeTree[scopeKey]) {
          scopeTree[scopeKey] = {};
        }
        scopeTree[scopeKey][members[membersLength - 2]] = members[membersLength - 1];
      }
    });
    return scopeTree;
  },

  isAllowed: function isAllowed (content, locale, right) {
    const userScopes = this.get('scopes');
    if (this.hasScopeAdmin(userScopes)) {
      return true;
    }

    const scopeTree = this.getScopeTree(userScopes);
    if (scopeTree && scopeTree[content] && scopeTree[content][locale]) {
      return scopeTree[content][locale] === right
        || (right === 'READ' && (scopeTree[content][locale] === 'WRITE' || scopeTree[content][locale] === 'PUBLISH'))
        || (right === 'WRITE' && scopeTree[content][locale] === 'PUBLISH');
    } 
      return false;
    
  },

  isAllowedToReadAllLocales: function isAllowedToReadAllLocales (content) {
    const userScopes = this.get('scopes');
    if (this.hasScopeAdmin(userScopes)) {
      return true;
    }

    const scopeTree = this.getScopeTree(userScopes);
    if (scopeTree && scopeTree[content]) {
      return this.hasWriteOrPublishRight(scopeTree[content]);
    } 
      return false;
    
  },

  hasWriteOrPublishRight: function hasWriteOrPublishRight (scopeTreeContent) {
    for (const key in scopeTreeContent) {
      if (scopeTreeContent[key] === 'WRITE' || scopeTreeContent[key] === 'PUBLISH') {
        return true;
      }
    }
    return false;
  },

  hasScopeAdmin: function hasScopeAdmin (userScopes) {
    return userScopes.indexOf('NONE_NONE') !== -1
      || userScopes.indexOf('CMS_ADMIN') !== -1
      || userScopes.indexOf('CMS_PO') !== -1
      || userScopes.indexOf('CMS_DEV') !== -1
      || userScopes.indexOf('CMS_SUPERADMIN') !== -1;
  },

  isAdmin: function isAdmin () {
    const profile = this.get('profile');
    return ((profile === 'CMS_ADMIN') || (profile === 'CMS_PO') || (profile === 'CMS_DEV') || (profile === 'CMS_SUPERADMIN'));
  },

  isDev: function isDev () {
    const profile = this.get('profile');
    return ((profile === 'CMS_DEV') || (profile === 'CMS_SUPERADMIN'));
  },

  isPo: function isPO () {
    const profile = this.get('profile');
    return ((profile === 'CMS_PO') || (profile === 'CMS_DEV') || (profile === 'CMS_SUPERADMIN'));
  },

  isSuperAdmin: function isSuperAdmin () {
    return (this.get('profile') === 'CMS_SUPERADMIN');
  },

  setName: function setName () {
    if (this) {
      if (this.get('given_name') && this.get('family_name')) {
        this.set('name', this.get('given_name') + ' ' + this.get('family_name'));
      } else if (this.get('given_name')) {
        this.set('name', this.get('given_name'));
      } else if (this.get('family_name')) {
        this.set('name', this.get('family_name'));
      } else {
        this.set('name', 'Unknown user');
      }
    }
  },

  accessDenied: function accessDenied () {
    this.setName();
    this.set('access_denied', true);
    sessionStorage.user = JSON.stringify(this.toJSON());
  },

  saveSession: function saveSession () {
    this.setName();
    sessionStorage.user = JSON.stringify(this.toJSON());
  },

  destroySession: function destroySession () {
    delete sessionStorage.user;
    this.clear();
  },

  getAccessToken: function getAccessToken (next, err) {
    const code = _.clone(this.get('code'));

    return Backbone.$.ajax({
      beforeSend: function (xhr, options) {
        const querySeparator = options.url.indexOf('?') === -1 ? '?' : '&';
        if (options.url && options.url.indexOf('client_id') === -1) {
          options.url = options.url + querySeparator + 'client_id=' + config.get('authorization').client_id;
        }
        options.url = options.url + '&redirect_uri=' + config.getHomeUrl() + '&nonce=' + localStorage['nonce'] + '&code=' + code;
        options.crossDomain = true;
      },
      method: 'GET',
      url: '/token/self',
      crossDomain: true,
      contentType: 'application/json',
      success: function (res) {
        next(res);
      },
      error: function (error) {
        if (error && error.responseJSON) {
          err(error.responseJSON);
        } else {
          err();
        }
      },
    });
  },

  renewAccessToken: function renewAccessToken (next, err) {
    const oldAccessToken = _.clone(this.get('access_token'));
    const refreshToken = _.clone(this.get('refresh_token'));

    const self = this;
    return Backbone.$.ajax({
      beforeSend: function (xhr, options) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + oldAccessToken);
        const querySeparator = options.url.indexOf('?') === -1 ? '?' : '&';
        if (options.url && options.url.indexOf('client_id') === -1) {
          options.url = options.url + querySeparator + 'client_id=' + config.get('authorization').client_id;
        }
        options.url = options.url + '&refresh_token=' + refreshToken;
        options.crossDomain = true;
      },
      method: 'GET',
      url: '/token/renew',
      crossDomain: true,
      contentType: 'application/json',
      success: function (res) {
        self.authenticate(res,
          () => {
            delete sessionStorage.user;
            self.saveSession();
            next();
          },
          () => {
            self.accessDenied();
            err();
          });
      },
      error: function () {
        self.accessDenied();
        err();
      },
    });
  },

  getScopes: function getScopes (next, err) {
    return Backbone.$.ajax({
      method: 'GET',
      url: '/scopes/self',
      crossDomain: true,
      contentType: 'application/json',
      success: next,
      error: err,
    });
  },

  getProfile: function getProfile (next, err) {
    return Backbone.$.ajax({
      method: 'GET',
      url: '/profile/self',
      crossDomain: true,
      contentType: 'application/json',
      success: next,
      error: err,
    });
  },
});

module.exports = new Account();
