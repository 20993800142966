'use strict';

let View,
  singleton;
const  template = require('templates/migrateContent'),
  account = require('models/account'),
  MigrateContent = require('models/migrateContent'),
  notificationEvent = require('NotificationEvent'),
  Confirm = require('views/confirmView'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  Utils = require('utils/utils'),
  Contents = require('models/contents'),
  Locales = require('models/locales'),
  State = require('../State');

View = Backbone.View.extend({

  events: {
    'click #button-back': 'previousPage',
    'click #button-back-to': 'previousView',
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.contents) {
      this.contents.off(null, null, this);
    }
  },

  previousPage: function previousPage() {
    window.history.back();
  },

  previousView: function previousView() {
    State.goToLastView();
  },

  bindButtons: function bindButtons() {
    this.$el.find('#button-check-duplicates-release').off().click($.proxy(function () {
      if (this.model.isValid()) {
        this.checkReleaseDuplicates();
      } else {
        notificationEvent.notify(this.model.validationError, 'danger');
      }
    }, this));

    this.$el.find('#button-apply-migrate-content').off().click($.proxy(function () {
      try {
        if ($('.ace_error').length != 0) {
          notificationEvent.notify('JSON is not valid', 'danger');
        } else {
          this.migrateContent(true);
        }
      } catch (error) {
        notificationEvent.notify(error.message, 'danger');
      }
    }, this));
  },

  migrateContent: function migrateContent(retry) {
    const contentId = this._getContentSelectValue();
    if (!contentId) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> a content must be selected</li>'));
      return;
    }
    const locales = this._getLocalesSelectValue();
    const localeIds = (locales && locales.length && locales.length !== this.locales.length) ? locales : ['all'];

    let migrationObject;
    try {
      migrationObject = this.jsonEditor.get();
    } catch (error) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> migration object cannot be empty</li>'));
      return;
    }
    try {
      this.validateMigrationObject(migrationObject);
    } catch (error) {
      return;
    }
    const self = this;
    const localesStr = (localeIds[0] === 'all') ? 'ALL Locales' : 'Locales [' + locales.join(', ') + ']';
    Confirm.showMe('You are about to apply the migrations on Content [' + contentId + '] for ' + localesStr, $.proxy(() => {
      Spinner.show();
      self._disableBtn();
      notificationEvent.notify('Migration process starting...', 'warning');
      setTimeout(() => {
        const contentMigration = new MigrateContent({
          contentId: contentId,
          localeIds: localeIds,
          migrationObject: migrationObject,
        });
        contentMigration.save(contentMigration.toJSON(), {
          success: function (response) {
            Spinner.hide();
            self._enableBtn();
            if (response) {
              const { result } = response.toJSON();
              self.showResultDiv(result);
            }
            notificationEvent.notify('Migration process finished!', 'success');
          },
          error: function (model, error) {
            errorHandler({
              error: error,
              retry: retry,
              success: function () {
                if (retry) {
                  self.migrateContent(false);
                }
              },
              fail: function () {
                self._enableBtn();
              },
            });
          },
        });
      }, 500);
    }, this), $.proxy(() => {
    }, this), false);
  },

  validateMigrationObject: function validateMigrationObject(migrationObject) {
    if (!Utils.isNotEmptyObject(migrationObject)) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration data, it must be a non-empty object with mandatory "loopOn" and "migrationList" properties OR only the "migrationList" which is a non-empty array of objects with mandatory "op", "path" properties and optional "matchProperty", "matchPropertyValue", "value" properties</li>'));
      throw new Error();
    }

    if (Array.isArray(migrationObject)) {
      this.validateMigrationList(migrationObject);
    } else {
      const migrationList = _.get(migrationObject, 'migrationList');
      this.validateMigrationList(migrationList);
    }
  },

  validateMigrationList: function validateMigrationList(migrationList) {
    if (!Utils.isNotEmptyArray(migrationList)) {
      this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid "migrationList", it must be a non-empty array of objects with mandatory "op", "path" properties and optional "matchProperty", "matchPropertyValue", "value" properties</li>'));
      throw new Error();
    }

    let migrationWithError = false;
    for (let i = 0; i < migrationList.length; i++) {
      const migration = migrationList[i];
      if (!Utils.isNotEmptyObject(migration) || !migration.op || (!migration.path && (!migration.matchProperty && !migration.pos))) {
        this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration at position #' + i + ' in "migrationList", migrations must be objects with mandatory "op" and "path" or "pos" properties and optional "matchProperty", "matchPropertyValue", "value" properties (if op equals "add" or "replace" the "value" property becomes mandatory). Element with error: ' + JSON.stringify(migration) + '</li>'));
        migrationWithError = true;
      }
      if (migration.op === 'add' || migration.op === 'replace') {
        if (!_.has(migration, 'value')) {
          this.showErrorDiv($('<li class="cms-migrateContent-error-item"><b>ERROR:</b> invalid migration at position #' + i + ' in "migrationList", migrations must be objects with mandatory "op", "path" properties and optional "matchProperty", "matchPropertyValue", "value" properties (if op equals "add" or "replace" the "value" property becomes mandatory). Element with error: ' + JSON.stringify(migration) + '</li>'));
          migrationWithError = true;
        }
      }
    }
    if (migrationWithError) {
      throw new Error();
    }
  },

  resetInfoDivs: function resetInfoDivs() {
    const $errorDiv = $('.cms-migrateContent-error-display');
    $errorDiv.addClass('hidden');
    const $errorItems = $('.cms-migrateContent-error-item');
    $errorItems.remove();

    const $resultDiv = $('.cms-migrateContent-result-display');
    $resultDiv.addClass('hidden');
    const $resultContent = $('.cms-migrateContent-result-content');
    $resultContent.empty();
  },

  showErrorDiv: function showErrorDiv($errorElement) {
    this.resetInfoDivs();
    const $errorDiv = $('.cms-migrateContent-error-display');
    $errorDiv.removeClass('hidden');
    const $errorList = $('.cms-migrateContent-error-list');
    $errorElement.appendTo($errorList);
  },

  showResultDiv: function showResultDiv(result) {
    this.resetInfoDivs();
    const $resultDiv = $('.cms-migrateContent-result-display');
    $resultDiv.removeClass('hidden');
    const $resultContent = $('.cms-migrateContent-result-content');
    const sortedKeys = Object.keys(result).sort();
    const failedLocales = [];
    for (let i = 0; i < sortedKeys.length; i++) {
      const key = sortedKeys[i];
      const value = result[key];
      if (value.indexOf('FAIL') >= 0) {
        failedLocales.push(key);
      }
    }
    if (failedLocales.length === 0) {
      $('<h3 class="cms-migrateContent-result-message cms-migrateContent-result-success">All locales successfully migrated</h3>').appendTo($resultContent);
    } else {
      $('<h3 class="cms-migrateContent-result-message cms-migrateContent-result-fail">' + failedLocales.length + ' locales could not be migrated: ' + failedLocales.join(', ') + '</h3>').appendTo($resultContent);
    }
    $('<br>').appendTo($resultContent);
    for (let i = 0; i < sortedKeys.length; i++) {
      const key = sortedKeys[i];
      const value = result[key];
      $('<h4 class="cms-migrateContent-result-locale">Locale ' + key + ':</h4>').appendTo($resultContent);
      $('<p class="cms-migrateContent-result-message">' + value + '</p>').appendTo($resultContent);
      $('<br>').appendTo($resultContent);
    }
  },

  loadLocaleList: function loadLocaleList(event, noRetry) {
    this._resetLocalesSelect();
    const self = this;
    const contentId = this._getContentSelectValue();
    if (contentId) {
      Spinner.show();
      this._disableBtn();
      notificationEvent.notify('Loading content\'s locale list... Please wait.', 'warning');
      this.locales = new Locales({
        contentId: contentId,
      });
      this.locales.fetch({
        success: function (model) {
          Spinner.hide();
          self._enableBtn();
          self.locales = model.toJSON();
          self._initializeLocalesSelect();
        },
        error: function (model, error) {
          errorHandler({
            error: error,
            retry: !noRetry,
            success: function () {
              if (!noRetry) {
                self.loadLocaleList(event, true);
              }
            },
            fail: function () {
            },
          });
        },
      });
    }
  },

  _resetLocalesSelect: function _resetLocalesSelect() {
    $('#locales').empty();
    $('#locales').attr('disabled', true);
    $('.selectpicker').selectpicker('refresh');
  },

  _initializeLocalesSelect: function _initializeLocalesSelect() {
    if (this.locales && this.locales.length) {
      $('#locales').attr('disabled', false);
      for (let i = 0; i < this.locales.length; i++) {
        const locale = this.locales[i];
        const localeId = locale.id;
        $('#locales').append('<option value="' + localeId + '">' + localeId + '</option>');
      }
    } else {
      $('#locales').attr('disabled', true);
      $('.selectpicker').selectpicker();
    }
    $('.selectpicker').selectpicker('refresh');
  },

  _getContentSelectValue: function _getContentSelectValue() {
    const selectContent = $('select[name=selectContent]');
    return (selectContent) ? selectContent.val() : '';
  },

  _getLocalesSelectValue: function _getLocalesSelectValue() {
    return $('.selectpicker').selectpicker('val');
  },

  _disableBtn: function _disableBtn() {
    $('select[name=selectContent]').prop('disabled', true);
    $('#button-apply-migrate-content').prop('disabled', true);
  },

  _enableBtn: function _enableBtn() {
    $('select[name=selectContent]').prop('disabled', false);
    $('#button-apply-migrate-content').prop('disabled', false);
  },

  render: function render() {
    Spinner.hide();
    if (!account.isDev()) {
      const appRouter = require('router');
      appRouter.navigate('welcome', { trigger: true });
      return;
    }
    const html = template({ contents: this.contents.toJSON() });
    this.$el.html(html);
    $('#main').append(this.$el);
    this.bindButtons();
    const jsonContainer = document.getElementById('jsoneditor');
    const options = {
      mode: 'code',
      modes: ['code', 'tree'],
      error: function (err) {
        notificationEvent.notify(err.toString(), 'danger');
      },
    };
    this.jsonEditor = new JSONEditor(jsonContainer, options);
    this.jsonEditor.set({
      loopOn: 'all',
      migrationList: [],
    });
    $('select[name=selectContent]').on('change', $.proxy(this.loadLocaleList, this));
    this._initializeLocalesSelect();
    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData(contents, retry) {
    const self = this;
    contents.fetch({
      success: function (model) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.contents = model;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(contents, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe() {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }

    const contents = new Contents();
    this.retrieveData(contents, true);
    return singleton;
  },
});

module.exports = View;
