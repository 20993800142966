'use strict';

const Utils = require('./utils');
const _JSON_POINTER_PATH_REGEX = /\/([\w-._]+\/?)*/;

const JsonPatchUtils = {

  buildOperation: function buildOperation (isMoveOp, op, path, value) {
    if (op && path) {
      const operation = {
        op: op,
        path: Utils.formatToJsonPointer(path),
        value: value,
      };
      if (op === 'remove') {
        delete operation.value;
      }
      if (op === 'move') {
        delete operation.value;
        operation.from = Utils.formatToJsonPointer(value);
      }
      if (isMoveOp) {
        operation.isMoveOp = true;
      }

      return operation;
    }
    return null;
  },

  validateJsonpatch: function validateJsonpatch (jsonpatch) {
    return jsonpatch.map((operation, index) => {
      if (!operation) {
        return 'Error on operation ' + index + ': operation cannot be empty.';
      }

      const { op } = operation;
      if (!op) {
        return 'Error on operation ' + index + ': operation must have \'op\' property / operation: ' + JSON.stringify(operation);
      } else if (op !== 'add' && op !== 'replace' && op !== 'remove' && op !== 'move') {
        return 'Error on operation ' + index + ': \'op\' property accepted values are \'add\', \'replace\', \'remove\' or \'move\' / operation: ' + JSON.stringify(operation);
      }

      const { isMoveOp, from , path } = operation;
      if(isMoveOp) {
        if (op !== 'move') {
          return `Error on operation ${index}: 'isMoveOp' property can only be true for 'move' operations / operation: ${JSON.stringify(operation)}`;
        }
        if(!from || !path) {
          return `Error on operation ${index}: 'move' operation must have 'from' and 'path' properties / operation: ${JSON.stringify(operation)}`;
        }
      }

      if (!path) {
        return 'Error on operation ' + index + ': operation must have \'path\' property / operation: ' + JSON.stringify(operation);
      } else if (!_JSON_POINTER_PATH_REGEX.test(path)) {
        return 'Error on operation ' + index + ': \'path\' property accepted values are JSON Pointer paths (i.e. /path/example) / operation: ' + JSON.stringify(operation);
      }

      const operationHasValue = _.has(operation, 'value');
      if ((op === 'add' || op === 'replace') && !operationHasValue) {
        return 'Error on operation ' + index + ': \'add\' & \'replace\' operations should have \'value\' property / operation: ' + JSON.stringify(operation);
      } else if (op === 'remove' && operationHasValue) {
        return 'Error on operation ' + index + ': \'remove\' operations should not have \'value\' property / operation: ' + JSON.stringify(operation);
      }

      const { value } = operation;
      if ((op === 'add' || op === 'replace') && value == null) {
        return 'Error on operation ' + index + ': \'add\' & \'replace\' operations should have \'value\' property / operation: ' + JSON.stringify(operation);
      }

      return null;
    }).filter((v) => {
      return v;
    });
  },
};
module.exports = JsonPatchUtils;
