'use strict';

let View,
  singleton;

const  template = require('templates/createUser'),
  account = require('models/account'),
  User = require('models/user'),
  Scopes = require('models/scopes'),
  Spinner = require('Spinner'),
  errorHandler = require('utils/errorHandler'),
  notificationEvent = require('NotificationEvent');

function makeScopesGrid (scopes) {
  return new Backgrid.Grid({
    columns: [{
      name: 'content',
      label: 'Content',
      id: 'Content',
      cell: 'string',
      editable: false,
    }, {
      name: 'locale',
      label: 'Locale',
      id: 'Locale',
      cell: 'string',
      editable: false,
    }, {
      name: 'perm',
      label: 'Right',
      id: 'Permission',
      cell: Backgrid.SelectCell.extend({
        optionValues: [
          ['NONE', 0],
          ['READ', 1],
          ['WRITE', 2],
          ['PUBLISH', 3],
        ],
      }),
      editable: true,
    }],

    collection: scopes,
  });
}

function makeScopesFilter (scopes) {
  return new Backgrid.Extension.ClientSideFilter({
    collection: scopes,
    placeholder: 'Search scopes',
    fields: ['content', 'locale'],
    wait: 150,
  });
}

_.extend(Backform, {
  controlLabelClassName: 'control-label col-sm-2',
});

function makeUserForm (user) {
  return new Backform.Form({
    fields: [{
      name: 'hepta',
      label: 'Heptagram *',
      control: 'input',
    }, {
      name: 'lastname',
      label: 'Last Name *',
      control: 'input',
    }, {
      name: 'firstname',
      label: 'First Name *',
      control: 'input',
    }, {
      name: 'email',
      label: 'Email *',
      control: 'input',
    }, {
      name: 'profile',
      label: 'Profile *',
      control: 'select',
      options: [
        { label: 'CMS_USER', value: 'CMS_USER' },
        { label: 'CMS_ADMIN', value: 'CMS_ADMIN' },
        { label: 'CMS_DEV', value: 'CMS_DEV' },
        { label: 'CMS_PO', value: 'CMS_PO' },
      ],
    }],

    model: user,
  });
}

View = Backbone.View.extend({

  events: {
    'click .cancel': 'cancel',
  },

  close: function close () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  formValidation: function formValidation () {
    const COMMAND = 'create';
    const userErrors = this.model.validation();
    const profile = this.model.get('profile');
    const validScopes = this.scopes.validation(profile);
    if (!validScopes) {
      userErrors.push('A user with the "CMS_USER" profile must have at least one READ or WRITE or PUBLISH right');
    }
    const errorMsg = this.model.extractErrorMsg(userErrors, COMMAND);
    if (errorMsg) {
      notificationEvent.notify(errorMsg, 'danger');
      return false;
    } 
      return true;
    
  },

  changeProfile: function changeProfile (model) {
    const profile = model.get('profile');
    if (profile !== 'CMS_USER') {
      this.$('.scopes-grid').hide();
    } else {
      this.$('.scopes-grid').show();
    }
  },

  cancel: function cancel () {
    window.history.back();
  },

  createUser: function createUser (user, retry) {
    const self = this;
    Spinner.show();
    user.sync('create', user, {
      success: function () {
        notificationEvent.notify('User was created.', 'success');
        self.scopes.allScopes = false;
        self.scopes.hepta = self.model.get('hepta');
        const profile = self.model.get('profile');
        if (profile !== 'CMS_USER') {
          self.scopes.resetPermissions();
        }
        self.createScopes(self.scopes, true);
      },
      error: function (error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.createUser(user, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  createScopes: function createScopes (scopes, retry) {
    const self = this;
    scopes.sync('update', scopes, {
      success: function () {
        Spinner.hide();
        const appRouter = require('router');
        appRouter.navigate('users', { trigger: true });
        Backbone.history.loadUrl();
      },
      error: function (error) {
        errorHandler({
          error: error,
          retry: retry,
          noNotificationEvent: true,
          success: function () {
            if (retry) {
              self.createScopes(scopes, false);
            }
          },
          fail: function () {
            let errorMsg = 'Failed to add scopes, please check them in the edit form.';
            if (error) {
              errorMsg += '</br></br>' + error.responseJSON.message;
            }
            notificationEvent.notify(errorMsg, 'danger');
            const appRouter = require('router');
            appRouter.navigate('users', { trigger: true });
            Backbone.history.loadUrl();
          },
        });
      },
    });
  },

  render: function render () {
    Spinner.hide();
    if (!account.isSuperAdmin()) {
      const appRouter = require('router');
      appRouter.navigate('welcome', { trigger: true });
      return;
    }

    const html = template();
    this.grid = makeScopesGrid(this.scopes);
    this.filter = makeScopesFilter(this.scopes);
    const paginator = new Backgrid.Extension.Paginator({
      collection: this.scopes,
    });
    this.$el.html(html);
    $('#main').append(this.$el);
    $('.user-form').append(this.form.render().el);
    $('.scopes-grid').append(this.grid.render().el);
    $('.scopes-grid').prepend(this.filter.render().el);
    $('.scopes-grid').append(paginator.render().el);

    this.$el.find('.createUser').off().click($.proxy(function () {
      // Reset search to get full scopes collection before validation
      this.filter.clear();

      // Wait for search clear to be done
      setTimeout((self) => {
        if (self.formValidation()) {
          self.createUser(self.model, true);
        }
      }, 1000, this);
    }, this));

    this.delegateEvents();
    return this;
  },
}, {
  retrieveData: function retrieveData (scopes, retry) {
    const self = this;
    scopes.fetch({
      success: function (scopes) {
        Spinner.hide();
        const appRouter = require('router');
        if (!appRouter.activeView || appRouter.activeView == singleton) {
          singleton.scopes = scopes;
          singleton.render();
        }
      },
      error: function (model, error) {
        errorHandler({
          error: error,
          retry: retry,
          success: function () {
            if (retry) {
              self.retrieveData(scopes, false);
            }
          },
          fail: function () {
          },
        });
      },
    });
  },

  showMe: function showMe () {
    Spinner.show();
    if (!singleton) {
      singleton = new View();
    }

    singleton.model = new User();
    singleton.model.set('profile', 'CMS_USER');
    singleton.scopes = new Scopes(null, {
      allScopes: true,
      mode: 'client',
    });
    singleton.form = makeUserForm(singleton.model);
    this.retrieveData(singleton.scopes, true);
    singleton.listenTo(singleton.form.model, 'change:profile', singleton.changeProfile);

    return singleton;
  },
});

module.exports = View;
