'use strict';

const $spinner=$('#spinner');
const $js_spinner=$('.js-spinner');
const Spinner = {
    show: function show() {
        if ($spinner.length) {
            $spinner.addClass('spinner-container');
            $js_spinner.addClass('spinner');
        }
    },

    hide: function hide() {
        if ($spinner.length) {
            $spinner.removeClass('spinner-container');
            $js_spinner.removeClass('spinner');
        }
    },
};

module.exports = Spinner;
