'use strict';

let View,
  singleton;
const  template = require('templates/confirm');

View = Backbone.View.extend({

  close: function () {
    this.$el.remove();
    this.off();
    if (this.model) {
      this.model.off(null, null, this);
    }
  },

  render: function (msg, callback, cancelCallback, danger, dangerMsg) {
    const html = template();
    const state = danger ? 'danger' : 'warning';
    this.$el.html(html);
    $('#modal-confirm').append(this.$el).removeClass('cms-modal-confirm--warning cms-modal-confirm--danger');

    $('#modal-confirm').addClass('cms-modal-confirm--' + state);
    $('#button-OK-modal-confirm').addClass('button--' + state);

    this.$el.find('#modal-confirm-msg').text(msg);
    this.$el.find('#modal-confirm-msg-danger').text(dangerMsg);
    $('#modal-confirm').modal('toggle');
    this.$el.find('#button-OK-modal-confirm').off().click(() => {
      callback();
    });
    this.$el.find('#button-cancel-modal-confirm').off().click(() => {
      cancelCallback();
    });
    this.$el.find('#button-close-modal-confirm').off().click(() => {
      cancelCallback();
    });
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function (msg, callback, cancelCallback, danger, dangerMsg) {
    if (!singleton) {
      singleton = new View();
    }
    return singleton.render(msg, callback, cancelCallback, danger, dangerMsg);
  },
});

module.exports = View;
