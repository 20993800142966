'use strict';

let View,
  singleton;
const  template = require('templates/editTagModal'),
  TagsUtils = require('utils/TagsUtils');

View = Backbone.View.extend({

  events: {
    'click .js-btn-edit-tag': 'saveTags',
    'click .js-btn-cancel': 'cancel',
  },

  close: function close() {
    this.$el.remove();
    this.off();
    if (this.release) {
      this.release.off(null, null, this);
    }
  },

  initModel: function initModel() {
    let release = this.release.toJSON();
    const releaseValue = _.get(release, 'value', {});

    const tagsJsonPath = TagsUtils.getTagsJsonPath(this.defaultTag);
    let tags = _.get(releaseValue, tagsJsonPath, '');
    const allTags = TagsUtils.getTagsInfo(this.release, true, this.selectedCategory);
    this.currentTags = _.clone(tags);
    this.newTags = _.clone(tags);
    let splittedTags = tags.split(',');

    let uniqueArray = function(arrArg) {
      return arrArg.filter((elem, pos, arr) => {
        return arr.indexOf(elem) == pos;
      });
    };

    let mainTagStr = (splittedTags.length >= 1) ? decodeURIComponent(splittedTags[0]) : '';
    let subTagStr = (splittedTags.length >= 2) ? decodeURIComponent(splittedTags[1]) : '';
    let mainTagOptions = ['<option value="">-- No main Tag --</option>'];

    mainTagOptions.push( uniqueArray(
      allTags.map((o) => {
        return decodeURIComponent(o.mainTag);
      }))
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .filter((s) => {
        return s !== 'ALL';
      })
      .map((s) => { return mainTagStr === s ? '<option value="' + s + '" selected="selected">' + s + '</option>' : '<option value="' + s + '">' + s + '</option>';}));

    const subTagOptions = ['<option value="">-- No sub Tag --</option>'];

    subTagOptions.push( uniqueArray(
      allTags.map((o) => {
        return o.subTags;
      })
        .flat()
        .map((o) => { return decodeURIComponent(o.subTagStr);}))
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .filter((s) => { return s !== 'ALL';})
      .map((s) => {
        return subTagStr === s ? '<option value="' + s + '" selected="selected">' + s + '</option>' : '<option value="' + s + '">' + s + '</option>';
      } ));


    return {
      mainTagOptions: mainTagOptions.join(''),
      subTagOptions: subTagOptions.join(''),
      release: release,
      title: this.title,
      defaultTag: this.defaultTag,
      tags: tags,
      mainTag: (splittedTags.length >= 1) ? splittedTags[0] : '',
      mainTagStr: mainTagStr,
      subTag: (splittedTags.length >= 2) ? splittedTags[1] : '',
      subTagStr: subTagStr,
    };
  },

  saveTags: function saveTags() {
    if (this.validateTags()) {
      this.$el.parent().modal('toggle');
      this.callBackEdit(decodeURIComponent(this.newTags));
      return true;
    }
    return false;
  },

  validateTags: function validateTags() {
    $('.cms-label--error').remove();
    var $mainTagInput = $('#release-main-tag');
    var mainTagStr = $mainTagInput.val().trim();
    var $subTagInput = $('#release-sub-tag');
    var subTagStr = $subTagInput.val().trim();
    if (subTagStr) {
      if (!mainTagStr) {
        $mainTagInput.addClass('cms-field--error');
        $mainTagInput.after('<div class="cms-label--error">Main tag cannot be empty if there is a sub tag</div>');
        return false;
      } else if (subTagStr.indexOf(',') >= 0) {
        $subTagInput.addClass('cms-field--error');
        $subTagInput.after('<div class="cms-label--error">Sub tag cannot contain commas</div>');
        return false;
      }
    }
    if (mainTagStr) {
      if (mainTagStr.indexOf(',') >= 0) {
        $mainTagInput.addClass('cms-field--error');
        $mainTagInput.after('<div class="cms-label--error">Main tag cannot contain commas</div>');
        return false;
      }
    }
    let mainTag = encodeURIComponent(mainTagStr.toUpperCase());
    let subTag = encodeURIComponent(subTagStr);
    this.newTags = (mainTag && subTag) ? [mainTag, subTag].join(',') : mainTag;
    if (this.newTags === this.currentTags) {
      $subTagInput.addClass('cms-field--error');
      $subTagInput.after('<div class="cms-label--error">New tags must be different than current tags</div>');
      return false;
    }
    return true;
  },

  cancel: function cancel() {
    this.$el.parent().modal('toggle');
  },

  render: function render() {
    var templateModel = this.initModel();
    var html = template(templateModel);
    this.$el.html(html);
    $('#modal-editTag').append(this.$el);
    $('.selectpicker').selectpicker({
      noneResultsText: '<button type="button" class="btn btn-outline-secondary" onclick=(add_opt(this))> Add new tag ... </button>',
    });
    this.$el.parent().modal('toggle');
    this.delegateEvents();
    return this;
  },
}, {
  showMe: function(release, defaultTag, title, callBack, selectedCategory) {
    if (!singleton) {
      singleton = new View();
    } else {
      singleton.close();
    }
    singleton.release = release;
    singleton.defaultTag = defaultTag;
    singleton.title = title;
    singleton.currentTags = '';
    singleton.newTags = '';
    singleton.selectedCategory = selectedCategory;
    singleton.callBackEdit = callBack;
    singleton.render();
    return singleton;
  },
});

module.exports = View;
